import SearchBar from '@components/lib/searchbar/searchbar';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FollowUpKeys,
  useFollowUpSelectTemplateMutation,
  useGetFollowUpQuery,
} from '@gen2/api/follow-up/hooks';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import {
  InviteRequestsKeys,
  useInviteRequestsQuery,
} from '@gen2/api/invite-requests/hooks';
import { InvitesKeys } from '@gen2/api/invites/hooks';
import { getTemplateRequests, TTemplate } from '@gen2/api/templates/api';
import {
  useSelectTemplateMutation,
  useTemplatesQuery,
} from '@gen2/api/templates/hooks';
import { ActionModal } from '@gen2/app/components/action-modal/action-modal';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { FeatureFlags } from '@gen2/app/components/feature-flags/feature-flags';
import { Loading } from '@gen2/app/invite-listing/invite-listing-item/loading';
import {
  EmptyTemplates,
  TemplateCard,
  TemplateCardBadge,
  TemplateCardContent,
  TemplateCardDate,
  TemplateCardHeader,
  TemplateCardName,
} from '@gen2/app/templates/templates.styled';
import { queryClient } from '@gen2/config';
import { useAuth, useRouter, useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { MAX_INVITE_REQUESTS } from '../requests/schema';
import { useSendInviteStore } from '../store';
import { MergeTemplatesModal } from './merge-templates-modal/merge-templates-modal';
import { useMergeTemplateModalStore } from './merge-templates-modal/store';
import { TemplateIcon } from './template-icon';
import {
  HeaderTitle,
  SearchAndActionContainer,
  SearchContainer,
  TemplateCards,
  TemplateContent,
  TemplatePaginationArrows,
  TemplatesHeader,
  TemplatesHeaderActions,
  TemplatesHeaderTop,
  TemplatesMobileClose,
  TemplatesModal,
} from './templates.styled';

export const Templates = () => {
  const theme = useTheme();
  const mediaMatch = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletMatch = useMediaQuery(theme.breakpoints.down('md'));
  const { featureFlags } = useAuth();
  const [selectedTemplates, setSelectedTemplates] = useState<TTemplate[]>([]);
  const { inviteId, followUpId } = useParams<{
    inviteId: string;
    followUpId: string;
  }>();

  const router = useRouter();
  const isFollowUp = router.pathname.includes('follow-up');

  const {
    invite,
    isTemplatesOpen,
    setIsTemplatesOpen,
    reset,
    setContextInviteIdForNewInvite,
  } = useSendInviteStore();

  const { data: followUpRequests } = useGetFollowUpQuery({
    inviteId: invite?.id ? invite.id : inviteId ?? '',
    followUpId: followUpId ?? '',
    options: {
      enabled: isFollowUp && !!inviteId && !!followUpId,
    },
  });

  const { data: inviteRequests } = useInviteRequestsQuery(
    {
      inviteId: invite?.id ? invite.id : inviteId ?? '',
      'sort[order]': 'asc',
      'sort[column]': 'order',
      per_page: 100,
    },
    {
      enabled: !!invite.id && featureFlags['load_template_improvement'],
    },
  );

  const { showModal } = useMergeTemplateModalStore();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useTemplatesQuery(
    {
      page,
      per_page: 25,
      'filter[name]': debouncedQuery,
    },
    {
      enabled: isTemplatesOpen,
    },
  );
  const toast = useToast();

  const { mutateAsync: selectTemplateMutation } = useSelectTemplateMutation();

  const [isInviteRequestsUpdating, setIsInviteRequestsUpdating] =
    useState(false);

  const { mutateAsync: followUpSelectTemplateMutation } =
    useFollowUpSelectTemplateMutation();

  const { t } = useTranslation('templates');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { showModal: showActionModal } = useActionModalStore();

  const handleClose = useCallback(async () => {
    setIsTemplatesOpen(false);
    setSelectedTemplates([]);
  }, [setIsTemplatesOpen]);

  const nextPage = () => {
    if (!data?.templates?.length) return;

    setPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (page <= 1) return;

    setPage((prev) => prev - 1);
  };

  const onSelect = (template: TTemplate) => {
    setSelectedTemplates((prev) => {
      if (!featureFlags['merge_templates']) {
        return [template];
      }

      if (prev.find((t) => t.id === template.id)) {
        return prev.filter((t) => t.id !== template.id);
      }

      if (prev.length >= 3) {
        return prev;
      }

      return [...prev, template];
    });
  };

  // it's only new if it was created today
  const isStillNew = (createdAt: Date | string) => {
    return dayjs(createdAt).isSame(dayjs(), 'day');
  };

  const handleTemplateSelection = useCallback(async () => {
    const templateId = selectedTemplates[0].id;
    const payloadInviteId = invite?.id ? invite.id : inviteId ?? '';

    if (!isFollowUp) {
      await selectTemplateMutation({ templateId, inviteId: payloadInviteId });
    } else {
      await followUpSelectTemplateMutation({
        templateId,
        inviteId: payloadInviteId,
        followUpId: followUpId ?? '',
      });
    }
  }, [
    followUpId,
    followUpSelectTemplateMutation,
    invite.id,
    inviteId,
    isFollowUp,
    selectTemplateMutation,
    selectedTemplates,
  ]);

  const finalizeConfirmation = useCallback(async () => {
    const resultInviteId = invite?.id ? invite.id : inviteId ?? '';

    if (resultInviteId) {
      setContextInviteIdForNewInvite(resultInviteId);
    }

    if (!isFollowUp) {
      await queryClient.invalidateQueries([
        InviteRequestsKeys.getInviteRequests,
      ]);
      await queryClient.invalidateQueries([InvitesKeys.getInvite]);
    } else {
      await queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);
    }
  }, [invite.id, inviteId, isFollowUp, setContextInviteIdForNewInvite]);

  const onConfirm = useCallback(async () => {
    if (!selectedTemplates) return;

    handleCloseConfirmation();
    reset();

    try {
      await handleTemplateSelection();

      toast.show({
        text: t('afterChange'),
        variant: 'success',
      });
    } catch (err) {
      toast.show({
        text: t('max_requests_reached'),
        variant: 'error',
      });
    } finally {
      await finalizeConfirmation();

      await handleClose();

      setIsInviteRequestsUpdating(false);
    }
  }, [
    finalizeConfirmation,
    handleClose,
    handleTemplateSelection,
    reset,
    selectedTemplates,
    t,
    toast,
  ]);

  const handleOpenConfirmation = useCallback(async () => {
    if (!featureFlags['load_template_improvement']) {
      setIsConfirmationOpen(true);

      return;
    }

    setIsInviteRequestsUpdating(true);

    const response = await getTemplateRequests({
      templateId: selectedTemplates[0].id,
    });

    const inviteReqs = !isFollowUp
      ? inviteRequests?.length ?? 0
      : followUpRequests?.requests.length ?? 0;

    const templateRequests: TInviteRequest[] = response.data.data.requests;

    const isOverwritable = inviteReqs <= 1;
    const needsConfirmation =
      inviteReqs + templateRequests.length > MAX_INVITE_REQUESTS &&
      !isOverwritable;

    if (!needsConfirmation) {
      await onConfirm();
      return;
    }

    const { isConfirmed } = await showActionModal({
      header: t('confirm.title'),
      message: 'confirm.desc',
      translationNamespace: 'templates',
      closeButtonLabel: t('cancel', { ns: 'common' }) ?? '',
      submitButtonLabel: t('confirm.buttons.submit') ?? '',
      submitButtonColor: 'primary',
    });

    if (isConfirmed) {
      await onConfirm();
    }

    setIsInviteRequestsUpdating(false);
  }, [
    featureFlags,
    inviteRequests?.length,
    onConfirm,
    selectedTemplates,
    showActionModal,
    t,
  ]);

  const handleMergeTemplates = async () => {
    const { readyToSend } = await showModal({
      templates: selectedTemplates,
    });

    if (readyToSend) {
      handleClose();
    }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (page !== 1) {
      setPage(1);
    }

    setQuery(e.target.value);
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <>
      <TemplatesModal
        open={isTemplatesOpen}
        onClose={handleClose}
        aria-labelledby="templates"
        aria-describedby="templates-title"
        fullScreen={mediaMatch}
        data-cy="templates-modal"
      >
        {!mediaMatch && (
          <ModalCloseButton
            data-cy="templates-modal-close"
            aria-label="close"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={regular('close')} />
          </ModalCloseButton>
        )}

        <TemplatesHeader>
          <TemplatesHeaderTop>
            <HeaderTitle id="templates-title">
              {t('loadTemplate.title')}
            </HeaderTitle>
            {mediaMatch && (
              <TemplatesMobileClose
                data-cy="templates-modal-mobile-close"
                aria-label="close"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={regular('x')} />
              </TemplatesMobileClose>
            )}
          </TemplatesHeaderTop>

          {!tabletMatch && (
            <SearchContainer>
              <SearchBar
                id="search-templates"
                placeholder="Search templates"
                onChange={handleSearch}
                onReset={() => setQuery('')}
                value={query}
                data-cy="search-templates"
              />
            </SearchContainer>
          )}

          <SearchAndActionContainer>
            {tabletMatch && (
              <SearchContainer>
                <SearchBar
                  id="search-templates"
                  placeholder="Search templates"
                  onChange={handleSearch}
                  onReset={() => setQuery('')}
                  value={query}
                  data-cy="search-templates"
                />
              </SearchContainer>
            )}

            <TemplatesHeaderActions>
              <LoadingButton
                onClick={handleOpenConfirmation}
                color="primary"
                variant="contained"
                disabled={
                  selectedTemplates.length !== 1 || isInviteRequestsUpdating
                }
                data-cy="load-selected-template-btn"
                loading={isInviteRequestsUpdating}
              >
                {t('loadTemplate.buttons.load')}
              </LoadingButton>
              <FeatureFlags restriction="merge_templates">
                <LoadingButton
                  onClick={handleMergeTemplates}
                  color="secondary"
                  variant="contained"
                  disabled={
                    selectedTemplates.length < 2 || isInviteRequestsUpdating
                  }
                  data-cy="load-selected-template-btn"
                  loading={isInviteRequestsUpdating}
                >
                  {t('loadTemplate.buttons.merge')}
                </LoadingButton>
              </FeatureFlags>
            </TemplatesHeaderActions>
          </SearchAndActionContainer>
        </TemplatesHeader>
        <TemplateContent dividers $loading={isLoading}>
          <TemplatePaginationArrows onClick={prevPage} disabled={isLoading}>
            <FontAwesomeIcon icon={regular('chevron-left')} />
          </TemplatePaginationArrows>
          <TemplatePaginationArrows
            onClick={nextPage}
            $right
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={regular('chevron-right')} />
          </TemplatePaginationArrows>
          {isLoading ? (
            <Loading />
          ) : (
            <TemplateCards>
              {data?.templates?.length ? (
                data.templates.map((template) => (
                  <TemplateCard
                    $active={
                      selectedTemplates.filter((t) => t.id === template.id)
                        .length > 0
                    }
                    onClick={() => onSelect(template)}
                    key={template.id}
                    data-cy={`template-card-${template.id}`}
                  >
                    <TemplateCardHeader>
                      <TemplateCardDate>
                        {dayjs(template.created_at).format('MMM D, YYYY')}
                      </TemplateCardDate>
                    </TemplateCardHeader>
                    <TemplateCardContent>
                      <TemplateIcon />
                      <TemplateCardName>{template.name}</TemplateCardName>
                    </TemplateCardContent>
                    {isStillNew(template.created_at) && (
                      <TemplateCardBadge>
                        <span>{t('loadTemplate.new')}</span>
                      </TemplateCardBadge>
                    )}
                  </TemplateCard>
                ))
              ) : (
                <EmptyTemplates>{t('notFound')}</EmptyTemplates>
              )}
            </TemplateCards>
          )}
        </TemplateContent>
        {mediaMatch && (
          <DialogActions>
            <Button
              onClick={handleOpenConfirmation}
              color="primary"
              variant="contained"
              disabled={selectedTemplates.length === 0}
              data-cy="load-selected-template-btn"
            >
              {t('loadTemplate.buttons.loadSelected')}
            </Button>
          </DialogActions>
        )}

        {/* confirmation */}
        <Dialog data-cy="confirmation-modal" open={isConfirmationOpen}>
          <ModalCloseButton
            aria-label="close"
            onClick={handleCloseConfirmation}
          >
            <FontAwesomeIcon icon={regular('close')} />
          </ModalCloseButton>
          <DialogTitle id="confirmation-dialog-title">
            {t('confirm.title')}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="confirmation-dialog-description">
              {t('confirm.desc')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseConfirmation}
              color="tertiary"
              variant="outlined"
              data-cy="cancel-change-template-btn"
            >
              {t('loadTemplate.buttons.cancel')}
            </Button>
            <Button
              data-cy="confirm-change-template-btn"
              onClick={onConfirm}
              color="primary"
              variant="contained"
            >
              {t('loadTemplate.buttons.loadConfirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </TemplatesModal>

      <FeatureFlags restriction="merge_templates">
        <MergeTemplatesModal />
      </FeatureFlags>

      <ActionModal />
    </>
  );
};
