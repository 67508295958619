import { config } from '@gen2/config';
import { TPagination } from '@gen2/types/pagination';
import { TUser } from '@gen2/types/user';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';
import { TInviteIndicator } from '../invites/api';

export type TWorkspace = {
  id: string;
  name: string;
  creator: TUser;
};

export type TGetWorkspacesParams = {
  'filter[search]'?: string;
  'filter[is_default]'?: 1 | 0;
} & TPagination;

export type TGetWorkspacesResponse = {
  data: {
    views: TWorkspace[];
  };
  links: {
    first: string;
    last: string;
    next: string;
    prev: string;
  };
};

export type TWorkspaceFilters = {
  poc?: Array<{ id: string; name: string }>;
  pocQuery?: string;
  contacts?: Array<{ id: string; name: string }>;
  contactsQuery?: string;
  assignees?: Array<{ id: string; name: string }>;
  assigneesQuery?: string;
  teams?: Array<{ id: string; name: string }>;
  teamsQuery?: string;
  indicators?: TInviteIndicator[];
  sortColumn?: 'created_at' | 'progression_percentage';
  sortOrder?: 'asc' | 'desc';
  subjectQuery?: string;
};

export const getWorkspaces = (
  params?: TGetWorkspacesParams,
): AxiosPromise<TGetWorkspacesResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/views`,
    params,
    method: 'get',
  });
};

export type TGetWorkspaceParams = {
  id: string;
};

export type TGetWorkspaceResponse = {
  data: {
    view: {
      id: string;
      name: string;
      is_default: boolean;
      created_at: string;
      filters: TWorkspaceFilters;
      creator: TUser;
    };
  };
  message: string;
};

export const getWorkspace = ({
  id,
}: TGetWorkspaceParams): AxiosPromise<TGetWorkspaceResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/views/${id}`,
    method: 'get',
  });
};

export type TSaveWorkspaceRequest = {
  name: string;
  is_default?: boolean;
  filters: TWorkspaceFilters;
};

export const saveWorkspace = (
  payload: TSaveWorkspaceRequest,
): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/views`,
    data: payload,
    method: 'post',
  });
};

export const deleteWorkspace = (id: string): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/views/${id}`,
    method: 'delete',
  });
};

export type TUpdateWorkspaceRequest = {
  id: string;
  name?: string;
  is_default?: boolean;
};

export const updateWorkspace = ({
  id,
  ...payload
}: TUpdateWorkspaceRequest): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/views/${id}`,
    data: payload,
    method: 'put',
  });
};

export const defaultWorkspace = ({
  id,
}: TUpdateWorkspaceRequest): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/views/default`,
    data: { id },
    method: 'post',
  });
};
