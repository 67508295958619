import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContactsQuery } from '@gen2/api/invite-contacts/hooks';
import { TInviteIndicator } from '@gen2/api/invites/api';
import { useTeamMemberListQuery } from '@gen2/api/team-member/hooks';
import { useTeamListQuery } from '@gen2/api/team/hooks';
import {
  Actions,
  Container,
  Content,
  StyledPopover,
  Tab,
  Title,
} from '@gen2/app/components/base-popup-with-tabs/base-popup-with-tabs.styled';

import { useAuth } from '@gen2/hooks';
import { SortColumn, SortOrder } from '@gen2/types/search-condition';
import { TUserStatus } from '@gen2/types/user';
import { Button } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useInviteListingStore } from '../store';
import { StyledChip, StyledTabs } from './filter.styled';
import IndicatorTabPanel from './indicator-tab-panel';
import TabPanel from './tab-panel';

export type FilterSearchCondition = {
  'sort[column]': SortColumn;
  'sort[order]': SortOrder;
  'filter[user]'?: string;
  'filter[full_name]'?: string;
  per_page: number;
  page: number;
};

export type TFilterInviteIndicator = Exclude<
  TInviteIndicator,
  'draft' | 'accepted' | 'archived'
>;

const inviteIndicators: TFilterInviteIndicator[] = [
  'sent',
  'no_progress',
  'progressing',
  'awaiting_review',
  'in_review',
  'complete',
  'due_today',
  'overdue',
];

export const InviteListingFilter = () => {
  const { featureFlags } = useAuth();
  const [tabValue, setTabValue] = useState(1);
  const {
    filter,
    selectedFilters,
    setSelectedPoc,
    setSelectedPocQuery,
    setSelectedContacts,
    setSelectedContactsQuery,
    setSelectedTeams,
    setSelectedTeamsQuery,
    setSelectedAssignees,
    setSelectedAssigneesQuery,
    setSelectedIndicators,
  } = useInviteListingStore();

  // check box for user selection or removal
  const { applyFilter, resetClearFlag } = filter;

  useEffect(() => {
    if (filter) {
      if (filter.clearFlag) {
        clearAll();
        resetClearFlag();
      }

      if (filter.pocQuery) {
        setUserQuery(filter.pocQuery);
      }

      if (filter.contactsQuery) {
        setContactQuery(filter.contactsQuery);
      }

      if (filter.assigneesQuery) {
        setAssigneeQuery(filter.assigneesQuery);
      }

      if (filter.teamsQuery) {
        setAssignedTeamQuery(filter.teamsQuery);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // sender users search
  const [userQuery, setUserQuery] = useState<string>(filter.pocQuery ?? '');
  const [debouncedUserQuery] = useDebounceValue(userQuery, 500);

  // contact search
  const [contactQuery, setContactQuery] = useState<string>(filter.contactsQuery ?? '');
  const [debouncedContactQuery] = useDebounceValue(contactQuery, 500);

  // Assignee users search
  const [assigneeQuery, setAssigneeQuery] = useState<string>(filter.assigneesQuery ?? '');
  const [debouncedAssigneeQuery] = useDebounceValue(assigneeQuery, 500);

  // Assigned Teams search
  const [assignedTeamQuery, setAssignedTeamQuery] = useState<string>(filter.teamsQuery ?? '');
  const [debouncedAssignedTeamQuery] = useDebounceValue(assignedTeamQuery, 500);

  const baseFilterSearchCondition = useMemo(() => {
    return {
      'sort[column]': 'created_at' as SortColumn,
      'sort[order]': 'desc' as SortOrder,
      per_page: 25,
      page: 1,
    };
  }, []);

  const baseUserFilterSearchCondition = useMemo(() => {
    return {
      ...baseFilterSearchCondition,
      'filter[status]': ['active'] as TUserStatus[],
    };
  }, [baseFilterSearchCondition]);

  const { data: users, isLoading: isUserLoading } = useTeamMemberListQuery({
    ...baseUserFilterSearchCondition,
    'filter[user]': debouncedUserQuery,
  });
  const { data: contacts, isLoading: isContactLoading } = useContactsQuery({
    ...baseFilterSearchCondition,
    'filter[search]': debouncedContactQuery,
  });
  const { data: assignees, isLoading: isAssigneeLoading } =
    useTeamMemberListQuery({
      ...baseUserFilterSearchCondition,
      'filter[user]': debouncedAssigneeQuery,
    });
  const { data: teams, isLoading: isAssignedTeamLoading } = useTeamListQuery({
    ...baseFilterSearchCondition,
    'filter[team]': debouncedAssignedTeamQuery,
  });

  const handleApply = () => {
    applyFilter({
      poc: selectedFilters.poc,
      pocQuery: userQuery,
      contacts: selectedFilters.contacts,
      contactsQuery: contactQuery,
      assignees: selectedFilters.assignees,
      assigneesQuery: assigneeQuery,
      teams: selectedFilters.teams,
      teamsQuery: assignedTeamQuery,
      indicators: selectedFilters.indicators,
      sortColumn: selectedFilters.sortColumn,
      sortOrder: selectedFilters.sortOrder,
      subjectQuery: selectedFilters.subjectQuery,
    });
    filter.closeFilter();
    setTabValue(1);
  };

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
  };

  const handleUserReset = () => {
    setUserQuery('');
  };

  const handleUserSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setUserQuery(event.target.value);
  };

  const handleContactReset = () => {
    setContactQuery('');
  };

  const handleContactSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setContactQuery(event.target.value);
  };

  const handleAssigneeReset = () => {
    setAssigneeQuery('');
  };

  const handleAssigneeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAssigneeQuery(event.target.value);
  };

  const handleAssignedTeamReset = () => {
    setAssignedTeamQuery('');
  };

  const handleAssignedTeamSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAssignedTeamQuery(event.target.value);
  };

  const clearAll = () => {
    setSelectedPoc([]);
    setSelectedPocQuery('');
    setUserQuery('');
    setSelectedContacts([]);
    setSelectedContactsQuery('');
    setContactQuery('');
    setSelectedAssignees([]);
    setSelectedAssigneesQuery('');
    setAssigneeQuery('');
    setSelectedTeams([]);
    setSelectedTeamsQuery('');
    setAssignedTeamQuery('');
    setSelectedIndicators([]);
    filter.clearFilter();
    filter.closeFilter();
    setTabValue(1);
  };

  useEffect(() => {
    return () => {
      setTabValue(1);
    };
  }, []);

  return (
    <div>
      <StyledPopover
        data-cy="filter-popover"
        id={filter.anchorEl ? 'simple-popover' : undefined}
        open={Boolean(filter.anchorEl)}
        anchorEl={filter.anchorEl}
        onClose={filter.closeFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ModalCloseButton aria-label="close" onClick={filter.closeFilter}>
          <FontAwesomeIcon icon={regular('close')} />
        </ModalCloseButton>
        <Container>
          <Content>
            <StyledTabs>
              <Title>Filter By</Title>
              <Tab active={tabValue === 1} onClick={() => handleTabChange(1)}>
                <span> Point of Contact</span>
                {selectedFilters.poc.length > 0 && (
                  <StyledChip
                    color="navy"
                    icon={
                      <FontAwesomeIcon
                        icon={solid('times-circle')}
                        transform="up-1 right-2"
                      />
                    }
                    label={`${selectedFilters.poc.length}`}
                    onClick={() => setSelectedPoc([])}
                  />
                )}
              </Tab>
              <Tab active={tabValue === 2} onClick={() => handleTabChange(2)}>
                <span> Contact</span>
                {selectedFilters.contacts.length > 0 && (
                  <StyledChip
                    color="navy"
                    icon={
                      <FontAwesomeIcon
                        icon={solid('times-circle')}
                        transform="up-1 right-2"
                      />
                    }
                    label={`${selectedFilters.contacts.length}`}
                    onClick={() => setSelectedContacts([])}
                  />
                )}
              </Tab>
              {featureFlags.manage_invites_part_2 && (
                <>
                  <Tab
                    active={tabValue === 3}
                    onClick={() => handleTabChange(3)}
                  >
                    <span>Assignee</span>
                    {selectedFilters.assignees.length > 0 && (
                      <StyledChip
                        color="navy"
                        icon={
                          <FontAwesomeIcon
                            icon={solid('times-circle')}
                            transform="up-1 right-2"
                          />
                        }
                        label={`${selectedFilters.assignees.length}`}
                        onClick={() => setSelectedAssignees([])}
                      />
                    )}
                  </Tab>
                  <Tab
                    active={tabValue === 4}
                    onClick={() => handleTabChange(4)}
                  >
                    <span>Assigned Team</span>
                    {selectedFilters.teams.length > 0 && (
                      <StyledChip
                        color="navy"
                        icon={
                          <FontAwesomeIcon
                            icon={solid('times-circle')}
                            transform="up-1 right-2"
                          />
                        }
                        label={`${selectedFilters.teams.length}`}
                        onClick={() => setSelectedTeams([])}
                      />
                    )}
                  </Tab>
                  <Tab
                    active={tabValue === 5}
                    onClick={() => handleTabChange(5)}
                  >
                    <span>Invite Status</span>
                    {selectedFilters.indicators.length > 0 && (
                      <StyledChip
                        color="navy"
                        icon={
                          <FontAwesomeIcon
                            icon={solid('times-circle')}
                            transform="up-1 right-2"
                          />
                        }
                        label={`${selectedFilters.indicators.length}`}
                        onClick={() => setSelectedIndicators([])}
                      />
                    )}
                  </Tab>
                </>
              )}
            </StyledTabs>
            {tabValue === 1 && (
              <TabPanel
                data={users?.data}
                isLoading={isUserLoading}
                selectedItems={selectedFilters.poc}
                setSelectedItems={setSelectedPoc}
                handleSearch={handleUserSearch}
                searchQuery={userQuery}
                handleReset={handleUserReset}
                placeholder="Search member by name or email"
                emptyMessage="No results for a Sender with this name or email."
                title="Point of Contact"
              />
            )}
            {tabValue === 2 && (
              <TabPanel
                data={contacts?.data?.contacts}
                isLoading={isContactLoading}
                selectedItems={selectedFilters.contacts}
                setSelectedItems={setSelectedContacts}
                handleSearch={handleContactSearch}
                searchQuery={contactQuery}
                handleReset={handleContactReset}
                placeholder="Search contact by name or email"
                emptyMessage="No results for a Contact with this name or email."
                title="Contact"
              />
            )}
            {tabValue === 3 && (
              <TabPanel
                data={assignees?.data}
                isLoading={isAssigneeLoading}
                selectedItems={selectedFilters.assignees}
                setSelectedItems={setSelectedAssignees}
                handleSearch={handleAssigneeSearch}
                searchQuery={assigneeQuery}
                handleReset={handleAssigneeReset}
                placeholder="Search member by name or email"
                emptyMessage="No results for an Assignee with this name or email."
                title="Assignee"
              />
            )}
            {tabValue === 4 && (
              <TabPanel
                data={teams?.data}
                isLoading={isAssignedTeamLoading}
                selectedItems={selectedFilters.teams}
                setSelectedItems={setSelectedTeams}
                handleSearch={handleAssignedTeamSearch}
                searchQuery={assignedTeamQuery}
                handleReset={handleAssignedTeamReset}
                placeholder="Search member by name"
                emptyMessage="No results for an Assigned Team with this name."
                title="Assigned Team"
                subTitle="Search by name"
                withAvatar={false}
              />
            )}
            {tabValue === 5 && (
              <IndicatorTabPanel
                data={inviteIndicators}
                selectedItems={
                  selectedFilters.indicators as TFilterInviteIndicator[]
                }
                setSelectedItems={setSelectedIndicators}
                title="Invite status"
                subTitle="View invites which have a certain status"
              />
            )}
          </Content>
          <Actions>
            <Button
              variant="contained"
              color="secondary"
              data-cy="clear-all"
              disabled={
                selectedFilters.poc.length <= 0 &&
                selectedFilters.contacts.length <= 0 &&
                selectedFilters.assignees.length <= 0 &&
                selectedFilters.teams.length <= 0 &&
                selectedFilters.indicators.length <= 0
              }
              onClick={clearAll}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-cy="apply"
              disabled={
                selectedFilters.poc.length <= 0 &&
                selectedFilters.contacts.length <= 0 &&
                selectedFilters.assignees.length <= 0 &&
                selectedFilters.teams.length <= 0 &&
                selectedFilters.indicators.length <= 0
              }
              onClick={handleApply}
            >
              Apply
            </Button>
          </Actions>
        </Container>
      </StyledPopover>
    </div>
  );
};
