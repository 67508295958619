import { useDeleteFilesMutation } from '@gen2/api/files/hooks';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import {
  InviteRequestsKeys,
  useCreateInviteRequestMutation,
  useDeleteInviteRequestMutation,
  useInviteRequestsQuery,
} from '@gen2/api/invite-requests/hooks';
import { ConfirmationModal } from '@gen2/app/components/confirmation-modal/confirmation-modal';
import { useConfirmationModalStore } from '@gen2/app/components/confirmation-modal/hooks/confirmation-modal-store';
import { CreateRequest } from '@gen2/app/components/invite-request/create-request/create-request';
import { useFileStore } from '@gen2/app/components/invite-request/hooks/file-store';
import { InviteRequest } from '@gen2/app/components/invite-request/invite-request';
import { useFollowUps } from '@gen2/app/invite-listing/invite-requests-details/hooks';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUnmount } from 'usehooks-ts';
import { REQUEST_TITLE } from '../hooks';
import { useSendInviteStore } from '../store';
import {
  StyledContainer,
  StyledCreateContainer,
  StyledRequests,
  StyledRequestsHead,
  StyledTitle,
} from './requests.styled';
import { inviteRequestsSchema, MAX_INVITE_REQUESTS } from './schema';
import { useRequestsStore } from './store';

export type TInviteRequestsForm = {
  requests: Array<TInviteRequest>;
};

export const defaultRequests: TInviteRequestsForm = {
  requests: [
    {
      id: '0',
      title: 'New Request',
      type: 'file-upload',
      description: '',
      descriptionText: '',
      meta: '',
      status: 'draft',
      order: 0,
      files: [],
      pdf_redactions: [],
      created_at: '',
      indicator: 'draft',
    },
  ],
};

export type TInviteRequestsListProps = {
  data: Array<TInviteRequest>;
  title?: string;
  hasDelete?: boolean;
};

export const InviteRequestsList = React.memo(
  ({ data, title, hasDelete }: TInviteRequestsListProps) => {
    const {
      mutate: deleteInviteRequestMutation,
      isLoading: isRequestDeleting,
    } = useDeleteInviteRequestMutation();
    const { toDeleteRequest, setToDeleteRequest, setActiveRequest } =
      useRequestsStore();
    const { t } = useTranslation('sendInvite');
    const toast = useToast();
    const { file: selectedFile, setFile: setSelectedFile } = useFileStore();
    const { setIsConfirmationModalOpen, setConfirmationModalProps } =
      useConfirmationModalStore();
    const { mutate: deleteFilesMutation, isLoading: isFileDeleting } =
      useDeleteFilesMutation();
    const { fields: requests } = useFieldArray({
      name: 'requests',
    });

    const siStore = useSendInviteStore();
    const isInviteReady = !!siStore.invite.id;

    const { reset } = useFormContext<TInviteRequestsForm>();

    useEffect(() => {
      if (!data || !data.length) return;

      // convert data to form values
      reset({
        requests: data,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siStore.invite.id, data, reset]);

    useUnmount(() => {
      // Cleanup logic here
      setActiveRequest(defaultRequests.requests[0]);
      reset(defaultRequests);
    });

    const requestTitle = useMemo(() => {
      if (requests.length > 0) {
        return toDeleteRequest?.title ?? '';
      }

      return '';
    }, [toDeleteRequest, requests]);

    const onDelete = useCallback(() => {
      if (toDeleteRequest) {
        deleteInviteRequestMutation(
          {
            inviteId: siStore.invite.id,
            requestIds: [toDeleteRequest.id],
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                InviteRequestsKeys.getInviteRequests,
              ]);

              await queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);

              toast.show({
                text: t('request.delete.success'),
                variant: 'success',
              });

              return null;
            },
            onError: () => {
              toast.show({
                text: t('request.delete.error'),
                variant: 'error',
              });
            },
            onSettled: () => {
              setToDeleteRequest(undefined);
              setIsConfirmationModalOpen(false);
            },
          },
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      deleteInviteRequestMutation,
      toDeleteRequest,
      setIsConfirmationModalOpen,
      toast,
    ]);

    useEffect(() => {
      if (!requestTitle) return;

      setConfirmationModalProps({
        onClose: () => setIsConfirmationModalOpen(false),
        onSubmit: onDelete,
        isLoading: isRequestDeleting,
        title: t('request.delete.title'),
        submitLabel: t('request.delete.buttonLabel'),
        submitColor: 'danger',
        message: siStore.isEditSendInvite
          ? t('editSendInvite.request.delete.message')
          : requestTitle
            ? t('request.delete.message', {
              requestName: requestTitle,
            })
            : t('request.delete.message_no_subject'),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isRequestDeleting,
      onDelete,
      requestTitle,
      setConfirmationModalProps,
      setIsConfirmationModalOpen,
    ]);

    const handleFileDelete = useCallback(() => {
      if (selectedFile) {
        deleteFilesMutation(
          {
            type: 'request',
            typeId: selectedFile.request.id,
            files: [{ id: selectedFile.id }],
          },
          {
            onSuccess: () => {
              siStore.setBannerMessage({
                severity: 'success',
                message: t('request.fileUpload.message.deleted') ?? '',
              });

              queryClient.invalidateQueries([
                InviteRequestsKeys.getInviteRequests,
              ]);

              queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);

              toast.show({
                text: t('file.delete.success'),
                variant: 'success',
              });
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (err: any) => {
              siStore.setBannerMessage({
                severity: 'error',
                message: err.data.message,
              });

              toast.show({
                text: t('file.delete.error'),
                variant: 'error',
              });
            },
            onSettled: () => {
              setSelectedFile(undefined);
              setIsConfirmationModalOpen(false);
            },
          },
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile, siStore, toast]);

    useEffect(() => {
      if (!selectedFile) return;

      const isDeleteFileFromFormType =
        selectedFile.request.type === 'live-form';

      setConfirmationModalProps({
        onClose: () => setIsConfirmationModalOpen(false),
        onSubmit: handleFileDelete,
        isLoading: isFileDeleting,
        title: isDeleteFileFromFormType
          ? t('editSendInvite.file.delete.title')
          : t('file.delete.title'),
        submitLabel: t('file.delete.buttonLabel'),
        submitColor: 'danger',
        message:
          isDeleteFileFromFormType && siStore.isEditSendInvite
            ? t('editSendInvite.file.delete.message')
            : t('file.delete.message', {
              fileName: selectedFile.original_name,
            }),
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFileDeleting, selectedFile]);

    if (!requests.length) return null;

    // NOTE:
    // react doesn't have support for the inert attribute
    // so we have to use a ref to set the attribute
    return (
      <StyledContainer
        $opaque={!isInviteReady}
        ref={(node) =>
          node &&
          (!isInviteReady
            ? node.setAttribute('inert', '')
            : node.removeAttribute('inert'))
        }
      >
        <StyledRequests>
          <StyledRequestsHead>
            <StyledTitle>{title}</StyledTitle>
          </StyledRequestsHead>
          <div>
            {requests.map((request, index) => (
              <InviteRequest
                key={request.id}
                id={index}
                hasDelete={hasDelete}
                isDeleteDisabled={isRequestDeleting}
              />
            ))}
          </div>
        </StyledRequests>
      </StyledContainer>
    );
  },
);

export type TFollowUpRequestsListProps = {
  title: string;
  requests: Array<TInviteRequest>;
};

export const FollowUpRequestsList = React.memo(
  (props: TFollowUpRequestsListProps) => {
    const methods = useForm<TInviteRequestsForm>({
      defaultValues: defaultRequests,
      resolver: yupResolver(inviteRequestsSchema),
    });

    return (
      <FormProvider {...methods}>
        <InviteRequestsList
          hasDelete
          title={props.title}
          data={props.requests}
        />
      </FormProvider>
    );
  },
);

export const FollowUpRequests = React.memo(() => {
  const siStore = useSendInviteStore();
  const { inviteId } = useParams<{ inviteId: string; }>();
  const followUps = useFollowUps((siStore.invite.id || inviteId) ?? '');

  if (!followUps.sent.length) {
    return null;
  }

  return (
    <>
      {followUps.sent.map((followUp) => (
        <FollowUpRequestsList
          key={followUp.id}
          title={followUp.name}
          requests={followUp.requests}
        />
      ))}
    </>
  );
});

export const InviteRequests = React.memo(() => {
  const methods = useForm<TInviteRequestsForm>({
    defaultValues: defaultRequests,
    resolver: yupResolver(inviteRequestsSchema),
  });
  const { t } = useTranslation('sendInvite');
  const siStore = useSendInviteStore();
  const { mutate: createInviteRequestMutation, isLoading: isCreating } =
    useCreateInviteRequestMutation();
  const { data } = useInviteRequestsQuery(
    {
      inviteId: siStore.invite.id,
      'sort[order]': 'asc',
      'sort[column]': 'order',
      per_page: 100,
    },
    {
      enabled: !!siStore.invite.id,
    },
  );

  const followUps = useFollowUps(siStore.invite.id);
  const onCreate = () => {
    if ((data?.length || 0) >= MAX_INVITE_REQUESTS) {
      siStore.setUpgradeModal({
        isOpen: true,
        title: t('request.upgrade.title') ?? '',
        description: t('request.upgrade.description') ?? '',
      });

      return;
    }

    createInviteRequestMutation(
      {
        inviteId: siStore.invite.id,
        title: REQUEST_TITLE,
        type: 'file-upload',
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            InviteRequestsKeys.getInviteRequests,
          ]);

          return null;
        },
      },
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <InviteRequestsList
          title={followUps.sent.length ? 'Initial Requests' : ''}
          data={data || []}
          hasDelete={(data?.length || 0) > 1}
        />
      </FormProvider>
      {!siStore.isEditSendInvite && (
        <StyledCreateContainer>
          <CreateRequest onClick={onCreate} disabled={isCreating} />
        </StyledCreateContainer>
      )}
    </>
  );
});

export const SendInviteRequests = React.memo(() => {
  return (
    <>
      <FollowUpRequests />
      <InviteRequests />
      <ConfirmationModal />
    </>
  );
});
