import { config } from '@gen2/config';
import { FilterStatus } from '@gen2/types/search-condition';
import { TUserStatus } from '@gen2/types/user';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';

export interface InvitesListPayload {
  'filter[status]'?: FilterStatus[] | TUserStatus[];
  'filter[users]'?: string;
  'filter[from_users]'?: string;
  'filter[contacts]'?: string;
  'filter[assignees]'?: string;
  'filter[teams]'?: string;
  'filter[subject]'?: string;
  'filter[indicator]'?: string;
  'sort[column]'?: string;
  'sort[order]'?: string;
  per_page: number;
  page: number;
}

export const getInvitesList = (
  search: InvitesListPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites`,
    params: {
      ...search,
      'filter[status]': (search['filter[status]'] ?? []).join(','),
    },
    method: 'get',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvitesItem = (inviteId: string): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}`,
    method: 'get',
  });

export const getInvitesRequests = (
  inviteId: string,
  excludeFollowUp?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests?sort[order]=asc&per_page=100&filter[exclude_follow_up_requests]=${excludeFollowUp}`,
    method: 'get',
  });

export const getDownloadFiles = (
  inviteId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/files/zip`,
    method: 'get',
  });

export const archiveInvite = (
  inviteId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/archive`,
    method: 'post',
  });

export interface ResendPayload {
  message: string;
  contact_ids: string[];
}

export const resendInvite = (
  inviteId: string,
  payload: ResendPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/resend`,
    method: 'post',
    data: payload,
  });

export interface GenerateHistoryReportPayload {
  start_at: string;
  end_at: string;
}

export const generateHistoryReportInvite = (
  inviteId: string,
  payload: GenerateHistoryReportPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/generate-history-report`,
    method: 'post',
    data: payload,
  });

export interface InviteHistoryReportDownloadPayload {
  signature: string;
  inviteId: string;
  expires?: number;
  fileId: string;
}

export const getDownloadInviteHistoryReport = ({
  signature,
  inviteId,
  expires,
  fileId,
}: InviteHistoryReportDownloadPayload) => {
  let url = `${config.apiVersion.v1}/invites/${inviteId}/history-reports/${fileId}?signature=${signature}`;

  if (expires) {
    url += `&expires=${expires}`;
  }

  return axios.request({
    url,
    method: 'get',
  });
};

export type TInviteHistoryReportDownloadResponse = {
  fileUrl: string;
}

export const duplicateInvite = (
  inviteId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/duplicate`,
    method: 'post',
  });
