import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetFollowUpQuery,
  useSendFollowUpMutation,
  useUpdateFollowUpMutation,
} from '@gen2/api/follow-up/hooks';
import { useCreateInviteRequestMutation } from '@gen2/api/invite-requests/hooks';
import { useInviteQuery } from '@gen2/api/invites/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios/index';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionModal } from '../components/action-modal/action-modal';
import { ConfirmationModal } from '../components/confirmation-modal/confirmation-modal';
import { CreateRequest } from '../components/invite-request/create-request/create-request';
import UpgradeModal from '../components/modals/upgrade-modal';
import { REQUEST_TITLE } from '../invites/send-invites/hooks';
import { useRequestsStore } from '../invites/send-invites/requests/store';
import { useSendInviteStore } from '../invites/send-invites/store';
import { FollowUpRequests } from './follow-up-requests';
import {
  BackButton,
  Container,
  Content,
  ContentLayout,
  FollowUpTitle,
  FormGroup,
  Header,
  NameField,
  SendFollowUpButton,
  Title,
} from './follow-up.styled';
import { schema } from './schema';
import { useFollowUpStore } from './store';

export type TFollowUpForm = {
  name: string;
};

const FollowUp = () => {
  const store = useSendInviteStore();
  const { showModal } = useActionModalStore();
  const { t } = useTranslation('followUp');
  const { inviteId, followUpId } = useParams<{
    inviteId: string;
    followUpId: string;
  }>();

  const {
    reset,
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<TFollowUpForm>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });
  const { data, refetch } = useGetFollowUpQuery({
    inviteId: inviteId || '',
    followUpId: followUpId || '',
  });

  const currentInviteId = useMemo(() => {
    return inviteId || store.contextInviteIdForNewInvite || '';
  }, [inviteId, store.contextInviteIdForNewInvite]);

  const { data: invite } = useInviteQuery(currentInviteId, {
    enabled: Boolean(currentInviteId),
  });

  useEffect(() => {
    if (!invite) return;

    store.setInvite(invite);
    store.setIsEditSendInvite(invite.status !== 'draft');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite]);

  const { setActiveRequest } = useRequestsStore();
  const { mutateAsync: createInviteRequestMutation, isLoading: isCreating } =
    useCreateInviteRequestMutation();
  const { mutate: updateFollowUp, isLoading: isUpdatingFollowUp } =
    useUpdateFollowUpMutation();
  const { mutate: sendFollowUpMutation, isLoading: isSending } =
    useSendFollowUpMutation();
  const { isOpen, title, description, setUpgradeModal } = useFollowUpStore();

  const navigate = useNavigate();
  const wName = watch('name');
  const toast = useToast();

  useEffect(() => {
    if (data) {
      reset({
        name: data.name || '',
      });
    }
  }, [data, reset]);

  const onCreate = () => {
    createInviteRequestMutation(
      {
        inviteId: inviteId || '',
        title: REQUEST_TITLE,
        type: 'file-upload',
        follow_up_id: followUpId,
      },
      {
        onSuccess: async () => {
          // after successful creation, refetch the data
          // and set the last request as active
          const res = await refetch();
          const newRequests = res.data?.requests ?? [];
          const lastRequest = newRequests[newRequests.length - 1];

          if (!lastRequest) return null;

          setActiveRequest(lastRequest);

          return null;
        },
        onError: (error) => {
          const axiosError = error as AxiosResponse;
          toast.show({
            text: `${axiosError.data.message}`,
            variant: 'error',
          });
        },
      },
    );
  };

  const onUpdate = () => {
    updateFollowUp(
      {
        inviteId: inviteId || '',
        followUpId: followUpId || '',
        name: wName,
      },
      {
        onSuccess: async () => {
          clearErrors('name');
        },
        onError: (error: any) => {
          setError('name', {
            type: 'server',
            message: error?.data?.errors.name[0],
          });
        },
      },
    );
  };

  const onSend = () => {
    sendFollowUpMutation(
      {
        inviteId: inviteId || '',
        followUpId: followUpId || '',
      },
      {
        onSuccess: () => {
          navigate(`/follow-up/${inviteId}/success`);
        },
        onError: (error: any) => {
          if (error?.data?.errors && !error?.data?.errors.contacts_in_request) {
            setError('name', {
              type: 'server',
              message: error?.data?.errors.name[0],
            });
          }

          if (error?.data?.errors.contacts_in_request) {
            showModal({
              header: t('editSendInvite.requestContact.notAssigned.title', {
                ns: 'sendInvite',
              }),
              translationNamespace: 'sendInvite',
              rawMessage: error.data.message,
              closeButtonLabel:
                t('editSendInvite.requestContact.notAssigned.close', {
                  ns: 'sendInvite',
                }) ?? '',
            });
          } else {
            toast.show({
              text: error?.data?.message,
              variant: 'error',
            });
          }
        },
      },
    );
  };

  return (
    <>
      <Container>
        <ContentLayout>
          <BackButton
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={regular('arrow-left')} />
            {t('back_btn')}
          </BackButton>
          <Content>
            <Header>
              <div>
                <FollowUpTitle>{t('title')}</FollowUpTitle>
                <p>
                  <Trans
                    i18nKey="description"
                    components={{
                      strong: <strong />,
                    }}
                  >
                    {t('description')}
                  </Trans>
                </p>
              </div>
              <SendFollowUpButton
                variant="contained"
                color="primary"
                disabled={isUpdatingFollowUp || isSending}
                onClick={onSend}
              >
                {t('send_btn')}
              </SendFollowUpButton>
            </Header>
            <FormGroup>
              <Title>{t('name.title')}</Title>
              <p>{t('name.description')}</p>
              <NameField
                {...register('name')}
                value={wName}
                onBlur={onUpdate}
                label={t('name.label')}
                error={Boolean(errors.name?.message)}
                helperText={t(errors.name?.message ?? '')}
                fullWidth
                required
              />
            </FormGroup>
            <FollowUpRequests requests={data?.requests ?? []} />
            <CreateRequest onClick={onCreate} disabled={isCreating} />
          </Content>
        </ContentLayout>
      </Container>
      <ConfirmationModal />
      <ActionModal />
      <UpgradeModal
        open={isOpen}
        onClose={() => setUpgradeModal({ isOpen: false })}
        title={title}
      >
        {description}
      </UpgradeModal>
    </>
  );
};

export default FollowUp;
