import { useDuplicateInviteMutation } from '@gen2/api/invite-listing/hooks';
import { useRouter, useToast } from '@gen2/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useDuplicateInvite = () => {
  const { t } = useTranslation('inviteListing');
  const toast = useToast();
  const router = useRouter();

  const { mutate: duplicateInvite, isLoading } = useDuplicateInviteMutation();

  const handleDuplicate = useCallback(
    (inviteId: string) => {
      duplicateInvite(inviteId, {
        onSuccess: (res) => {
          const { data } = res.data;
          const newInviteId = data.invite.id;
          toast.show({
            text: t('duplicate.success'),
            variant: 'success',
          });
          router.navigate(`/send-invites/${newInviteId}`);
        },
        onError: () => {
          toast.show({
            text: t('duplicate.error'),
            variant: 'error',
          });
        },
      });
    },
    [duplicateInvite, router, t, toast],
  );

  return {
    handleDuplicate,
    isLoading,
  };
};
