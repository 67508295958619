import Logout from '@gen2/app/components/Logout';
import ProtectedRoute from '@gen2/app/components/ProtectedRoute';
import { SocialLoginCallback } from '@gen2/app/components/social-login/social-login-callback';
import AuthorizationRequired from '@gen2/app/error/AuthorizationRequired';
import NotFound from '@gen2/app/error/NotFound';
import FollowUp from '@gen2/app/follow-up/follow-up';
import { FollowUpSuccess } from '@gen2/app/follow-up/follow-up-success';
import ForgotPasswordConfirmation from '@gen2/app/forgot-password-confirmation/forgot-password-confirmation';
import ForgotPassword from '@gen2/app/forgot-password/forgot-password';
import StorageIntegrationCallback from '@gen2/app/integrations/callbacks/int-callback';
import Integrations from '@gen2/app/integrations/integrations';
import { CloudStorageWrapper } from '@gen2/app/integrations/storage/storage';
import { IntegrationProvider } from '@gen2/app/integrations/useIntegrationContext';
import DraftListingLayout from '@gen2/app/invite-listing/layout/draft-listing-layout';
import SendInvites from '@gen2/app/invites/send-invites/send-invites';
import { SendInviteSuccess } from '@gen2/app/invites/send-invites/success/success';
import Login from '@gen2/app/login/Login';
import OauthCallbackHandler from '@gen2/app/login/OauthCallbackHandler';
import SSOCallbackHandler from '@gen2/app/login/SSOCallbackHandler';
import ResetPassword from '@gen2/app/reset-password/reset-password';
import SignupInvite from '@gen2/app/signup-invite/set-password';
import Signup from '@gen2/app/signup/signup';
import { TemplateForm } from '@gen2/app/templates/template-form/template-form';
import UserSettings from '@gen2/app/user-settings/user-settings';
import Workspaces from '@gen2/app/workspaces/workspaces';
import { PermissionsKeys as pk } from '@gen2/types/permissions';
import { AuthorizedRoute } from '@gen2/utils/permissions/permissions';
import { Unauthorized } from '@gen2/utils/unauthorized/unauthorized';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { InviteListingWrapper } from '../app/invite-listing/layout/invite-listing-main';
import { Layout } from '../app/layout/Layout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

const Dashboard = Loadable(lazy(() => import('../app/dashboard/Dashboard')));
const InviteListingLayout = Loadable(
  lazy(() => import('../app/invite-listing/layout/invite-listing-layout')),
);
const InviteListingMain = Loadable(
  lazy(() => import('../app/invite-listing/layout/invite-listing-main')),
);
const InviteListingDetails = Loadable(
  lazy(() => import('../app/invite-listing/layout/invite-listing-details')),
);
const Branding = Loadable(lazy(() => import('../app/branding/branding')));
const FileOption = Loadable(
  lazy(() => import('../app/file-options/file-options')),
);
const Templates = Loadable(lazy(() => import('../app/templates/templates')));

const CollationHistory = Loadable(
  lazy(() => import('../app/invites/collation-history/collation-history')),
);

const ArchivedLayout = Loadable(
  lazy(() => import('../app/invite-listing/layout/archived-listing-layout')),
);

const SwitchOrganisations = Loadable(
  lazy(() => import('../app/switch-organisations/switch-organisations')),
);

const Account = Loadable(lazy(() => import('../app/account/account')));

const TeamMemberNew = Loadable(
  lazy(() => import('../app/team-member/layout/team-member-create')),
);

const TeamMemberIndex = Loadable(
  lazy(() => import('../app/team-member/layout/team-member-listing')),
);

const TeamMemberView = Loadable(
  lazy(() => import('../app/team-member/layout/team-member-view')),
);

const TeamIndex = Loadable(
  lazy(() => import('../app/team/layout/team-listing')),
);

const TeamForm = Loadable(lazy(() => import('../app/team/form/team-form')));

const TeamView = Loadable(
  lazy(() => import('../app/team/view-team/view-team')),
);

const ContactsLayout = Loadable(
  lazy(() => import('../app/contacts/layout/contacts-layout')),
);

const ContactForm = Loadable(
  lazy(() => import('../app/contacts/contact-form/contact-form')),
);

const ContactView = Loadable(
  lazy(() => import('../app/contacts/view-contact/view-contact')),
);

const ContactGroups = Loadable(
  lazy(() => import('../app/contact-group/layout/contact-group-listing')),
);

const ContactGroupForm = Loadable(
  lazy(() => import('../app/contact-group/form/contact-group-form')),
);

const ContactGroupView = Loadable(
  lazy(
    () => import('../app/contact-group/view-contact-group/view-contact-group'),
  ),
);

const TemplateView = Loadable(
  lazy(() => import('../app/template-view/template-view')),
);

const InviteHistoryReport = Loadable(
  lazy(() => import('@gen2/app/reports/invite-history-report-download')),
);

const TeamMemberActivityLogDownload = Loadable(
  lazy(() => import('../app/reports/team-member-activity-download')),
);

const Register = Loadable(Signup);
const SendInvite = Loadable(SendInvites);
const Signin = Loadable(Login);
const ForgotPass = Loadable(ForgotPassword);
const ResetPass = Loadable(ResetPassword);
const SetPassword = Loadable(SignupInvite);
const ProfileSettings = Loadable(UserSettings);
const SocialLoginGenericCallback = Loadable(SocialLoginCallback);
const StorageGoogleCallback = Loadable(StorageIntegrationCallback);

const routes: RouteObject[] = [
  // The gen2 pages
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: '/send-invites', // for create invite
        element: (
          <AuthorizedRoute allow={pk.CREATE_INVITE}>
            <SendInvite />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/send-invites/:id', // for update invite
        element: (
          <AuthorizedRoute allow={pk.UPDATE_INVITE}>
            <SendInvite />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/success-sent/:id', // for create invite success page
        element: <SendInviteSuccess />,
      },
      {
        path: 'templates',
        element: (
          <AuthorizedRoute allow={pk.LIST_TEMPLATE}>
            <Templates />
          </AuthorizedRoute>
        ),
      },
      {
        path: 'templates/view/:templateId',
        element: (
          <AuthorizedRoute allow={pk.SHOW_TEMPLATE}>
            <TemplateView />
          </AuthorizedRoute>
        ),
      },
      {
        path: 'templates/:templateId',
        element: (
          <AuthorizedRoute allow={pk.UPDATE_TEMPLATE}>
            <TemplateForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: 'integrations',
        element: (
          <AuthorizedRoute allow={pk.CREATE_INVITE}>
            <IntegrationProvider>
              <Outlet />
            </IntegrationProvider>
          </AuthorizedRoute>
        ),
        children: [
          {
            index: true,
            element: <Integrations />,
          },
          {
            path: ':vendor',
            element: <CloudStorageWrapper />,
          },
        ],
      },
      {
        path: 'invite-listing',
        element: <InviteListingLayout />,
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_INVITE}>
                <InviteListingWrapper />
              </AuthorizedRoute>
            ),
          },
          {
            path: ':id',
            element: (
              <AuthorizedRoute allow={pk.SHOW_INVITE}>
                <InviteListingDetails />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: 'draft-listing',
        element: <DraftListingLayout />,
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_INVITE}>
                <InviteListingMain />
              </AuthorizedRoute>
            ),
          },
          {
            path: ':id',
            element: (
              <AuthorizedRoute allow={pk.SHOW_INVITE}>
                <InviteListingDetails />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: 'archived-listing',
        element: <ArchivedLayout />,
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_INVITE}>
                <InviteListingMain />
              </AuthorizedRoute>
            ),
          },
          {
            path: ':id',
            element: (
              <AuthorizedRoute allow={pk.SHOW_INVITE}>
                <InviteListingDetails />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: '/user-settings',
        element: <ProfileSettings />,
      },
      {
        path: '/team-members',
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_USER}>
                <TeamMemberIndex />
              </AuthorizedRoute>
            ),
          },
          {
            path: ':id',
            element: (
              <AuthorizedRoute allow={pk.SHOW_USER}>
                <TeamMemberView />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: '/team-members/member', // for create user
        element: (
          <AuthorizedRoute allow={pk.CREATE_USER}>
            <TeamMemberNew />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/team-members/member/:id', // for update user
        element: (
          <AuthorizedRoute allow={pk.UPDATE_USER}>
            <TeamMemberNew />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/team-members/:id/files/:id',
        element: (
          <AuthorizedRoute allow={pk.DOWNLOAD_ACTIVITY_LOG_USER}>
            <TeamMemberActivityLogDownload />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/contacts',
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_CONTACT}>
                <ContactsLayout />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: '/contacts/create', // for create contact
        element: (
          <AuthorizedRoute allow={pk.CREATE_CONTACT}>
            <ContactForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/contacts/edit/:id', // for update contact
        element: (
          <AuthorizedRoute allow={pk.UPDATE_CONTACT}>
            <ContactForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/contacts/view/:id', // for view contact
        element: (
          <AuthorizedRoute allow={pk.SHOW_CONTACT}>
            <ContactView />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/branding',
        element: (
          <AuthorizedRoute allow={pk.LIST_BRANDING}>
            <Branding />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/file-options',
        element: (
          <AuthorizedRoute allow={pk.LIST_NAMING_CONVENTION}>
            <FileOption />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/invites/collation-history',
        element: (
          <AuthorizedRoute allow={pk.LIST_COLLATE_HISTORY}>
            <CollationHistory />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/switch-organisations',
        element: <SwitchOrganisations />,
      },
      {
        path: '/account',
        element: (
          <AuthorizedRoute allow={pk.SHOW_CUSTOM_DOMAIN}>
            <Account />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/follow-up/:inviteId/:followUpId',
        element: (
          <AuthorizedRoute allow={pk.UPDATE_FOLLOW_UP}>
            <FollowUp />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/follow-up/:inviteId/success',
        element: (
          <AuthorizedRoute allow={pk.UPDATE_FOLLOW_UP}>
            <FollowUpSuccess />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/contact-groups',
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_CONTACT_GROUP}>
                <ContactGroups />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: '/contact-groups/create',
        element: (
          <AuthorizedRoute allow={pk.CREATE_CONTACT_GROUP}>
            <ContactGroupForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/contact-groups/edit/:id',
        element: (
          <AuthorizedRoute allow={pk.UPDATE_CONTACT_GROUP}>
            <ContactGroupForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/contact-groups/view/:id',
        element: (
          <AuthorizedRoute allow={pk.SHOW_CONTACT_GROUP}>
            <ContactGroupView />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/teams',
        children: [
          {
            index: true,
            element: (
              <AuthorizedRoute allow={pk.LIST_TEAM}>
                <TeamIndex />
              </AuthorizedRoute>
            ),
          },
        ],
      },
      {
        path: '/teams/create',
        element: (
          <AuthorizedRoute allow={pk.CREATE_TEAM}>
            <TeamForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/teams/edit/:id',
        element: (
          <AuthorizedRoute allow={pk.UPDATE_TEAM}>
            <TeamForm />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/teams/view/:id',
        element: (
          <AuthorizedRoute allow={pk.SHOW_TEAM}>
            <TeamView />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/reports/:id/files/:id',
        element: (
          <AuthorizedRoute allow={pk.DOWNLOAD_HISTORY_REPORT_INVITE}>
            <InviteHistoryReport />
          </AuthorizedRoute>
        ),
      },
      {
        path: '/workspaces',
        element: (
          <AuthorizedRoute allow={pk.LIST_VIEW}>
            <Workspaces />
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <Signin />,
  },
  {
    path: '/auth/integration/:vendor/callback',
    element: <StorageGoogleCallback />,
  },
  {
    path: '/auth/:identityProvider/callback',
    element: <SocialLoginGenericCallback />,
  },
  {
    path: '/sign-up',
    element: <Register />,
  },
  {
    path: '/logout', // for logout programmatically
    element: <Logout />,
  },
  {
    path: '/login/sso',
    element: <SSOCallbackHandler />,
  },
  {
    path: '/2023-02/callback',
    element: <OauthCallbackHandler />,
  },
  {
    path: '/forgot-password',
    children: [
      {
        index: true,
        element: <ForgotPass />,
      },
      {
        path: 'confirmation',
        element: <ForgotPasswordConfirmation />,
      },
    ],
  },
  {
    path: '/reset-password',
    element: <ResetPass />,
  },
  {
    path: '/signup-invite/:uuid/:hash',
    element: <SetPassword />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '401',
    element: <AuthorizationRequired />,
  },
  {
    path: 'unauthorized',
    element: <Unauthorized />,
  },
];

export default routes;
