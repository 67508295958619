import * as Yup from 'yup';

export const MIN_NAME_LENGTH = 3;
export const MIN_MESSAGE_LENGTH = 2;
export const MIN_SUBJECT_LENGTH = 2;

export const MAX_NAME_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 1000;
export const MAX_SUBJECT_LENGTH = 100;

export const MAX_TEMPLATE_REQUESTS = 100;

// TODO: tell xyruz that we're not doing the real time count of the characters in message
// also, character count is not match with invite message
export const templateFormSchema = Yup.object().shape({
  name: Yup.string()
    .required('form.name.required')
    .max(MAX_NAME_LENGTH, 'form.name.max')
    .min(MIN_NAME_LENGTH, 'form.name.min'),
  subject: Yup.string()
    .max(MAX_SUBJECT_LENGTH, 'form.subject.max')
    .min(MIN_SUBJECT_LENGTH, 'form.subject.min')
    .transform((value, originalValue) => {
      return !originalValue ? undefined : value;
    })
    .optional(),
  rawMessage: Yup.string()
    .max(MAX_MESSAGE_LENGTH, 'form.message.max')
    .min(MIN_MESSAGE_LENGTH, 'form.message.min')
    .transform((value, originalValue) => {
      return !originalValue ? undefined : value;
    })
    .optional(),
});
