import { create } from 'zustand';

export type TWorkspaceStore = {
  rename: {
    isOpen: boolean;
    id: string;
    oldName: string;
  };
  view: {
    isOpen: boolean;
    id: string;
  };

  openRenameModal: (id: string, oldName: string) => void;
  closeRenameModal: () => void;

  openViewModal: (id: string) => void;
  closeViewModal: () => void;
};

export const useWorkspacesStore = create<TWorkspaceStore>()((set) => ({
  rename: {
    isOpen: false,
    id: '',
    oldName: '',
  },
  view: {
    isOpen: false,
    id: '',
  },
  openRenameModal: (id, oldName) => {
    set((state) => ({
      rename: {
        ...state.rename,
        isOpen: true,
        id,
        oldName,
      },
    }));
  },
  closeRenameModal: () => {
    set({
      rename: {
        oldName: '',
        id: '',
        isOpen: false,
      },
    });
  },
  openViewModal: (id) => {
    set((state) => ({
      view: {
        ...state.view,
        isOpen: true,
        id,
      },
    }));
  },
  closeViewModal: () => {
    set({
      view: {
        id: '',
        isOpen: false,
      },
    });
  },
}));
