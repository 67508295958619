import {
  createUser,
  deactivateUser,
  generateTeamMemberActivityLogs,
  getTeamMember,
  getTeamMemberActivityLogs,
  getTeamMemberActivityLogsDownload,
  getTeamMemberList,
  getTeamMemberPositions,
  reactivateUser,
  TActivityLogsRange,
  TeamMemberActivityLogDownloadPayload,
  updateUser,
} from '@gen2/api/team-member/api';
import { SearchCondition } from '@gen2/types/search-condition';
import { TeamMemberSearchCondition } from '@gen2/types/team-member-search-condition';
import { TActivityLog, TPosition, TUser } from '@gen2/types/user';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';

export enum Keys {
  queryTeamMemberList = 'getTeamMemberList',
  queryTeamMemberItem = 'getUserItem',
  queryTeamMember = 'getUser',
  queryTeamMemberPositions = 'getTeamMemberPositions',
  queryTeamMemberActivityLogs = 'getTeamMemberActivityLogs',
  queryTeamMemberActivityLogsDownload = 'getTeamMemberActivityLogsDownload',
}

export interface UserListResponse {
  data: { users: TUser[] };
  links: {
    first: string | null;
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    path: string;
    per_page: number;
    to: number;
  };
}

export const useTeamMemberListQuery = (search: TeamMemberSearchCondition) =>
  useQuery({
    queryKey: [Keys.queryTeamMemberList, search],
    queryFn: async () => {
      const payload = {
        ...search,
        'filter[status]': search['filter[status]']?.join(','),
        page: search.page,
        per_page: search.per_page,
      };

      const { data } = await getTeamMemberList(payload);

      if (data && has(data, 'data')) {
        return {
          data: data.data.users as TUser[],
          links: data.links,
          meta: data.meta,
        };
      }
      return null;
    },
  });

export const useUserMutation = () => useMutation({ mutationFn: createUser });

export const useUpdateUserMutation = () =>
  useMutation({ mutationFn: updateUser });

export const useDeactivateUserMutation = () =>
  useMutation({ mutationFn: deactivateUser });

export const useReactivateUserMutation = () =>
  useMutation({ mutationFn: reactivateUser });

export const useTeamMemberQuery = (userId: string) =>
  useQuery({
    queryKey: [Keys.queryTeamMember, userId],
    queryFn: async () => {
      const { data } = await getTeamMember(userId);

      if (data && has(data, 'data')) {
        return data.data.user as TUser;
      }
      return null;
    },
    enabled: !!userId,
    onError: (err) => {
      const error = err as AxiosResponse;

      if (error.status === 404) {
        window.location.href = '/404';
      }
    },
  });

export const useTeamMemberPositionsQuery = () =>
  useQuery({
    queryKey: [Keys.queryTeamMemberPositions],
    queryFn: async () => {
      const { data } = await getTeamMemberPositions();

      return data.data.positions as TPosition[];
    },
  });

export const useTeamMemberActivityLogsQuery = (
  search: SearchCondition & TActivityLogsRange,
  uuid?: string,
) =>
  useQuery({
    queryKey: [Keys.queryTeamMemberActivityLogs, { search, uuid }],
    queryFn: async () => {
      const { data } = await getTeamMemberActivityLogs(
        {
          ...search,
          page: search.page,
          per_page: search.per_page,
        },
        uuid ?? '',
      );

      if (data && has(data, 'data')) {
        return {
          data: data.data.activity_logs as TActivityLog[],
          links: data.links,
          meta: data.meta,
        };
      }

      return null;
    },
    enabled: !!uuid,
  });

export const useGenerateActivityLogsMutation = () =>
  useMutation({ mutationFn: generateTeamMemberActivityLogs });

export const useTeamMemberActivityLogDownloadQuery = (payload: TeamMemberActivityLogDownloadPayload) => useQuery({
  queryKey: [Keys.queryTeamMemberActivityLogsDownload, payload],
  queryFn: async () => {
    const { data } = await getTeamMemberActivityLogsDownload(payload);
    return data;
  },
});

