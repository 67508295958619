/* eslint-disable unused-imports/no-unused-vars */
import { StyledAvatarTooltip } from '@components/lib/avatar-tooltip/avatar-tooltip.styles';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTooltip } from '@gen2/app/invites/send-invites/invite-head';
import { stringAvatar } from '@gen2/utils/name';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  StyledChip,
  ToLine,
  ToLineLabel,
  ToLineTags,
} from './send-to-line.styles';

export interface SendToLineProps {
  label: string | React.ReactNode;
  tags: Array<{
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    portal_url: string | undefined;
  }>;
  'data-cy'?: string;
}

export interface ContactChipProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  anchorEl?: null | Element | ((element: Element) => Element);
  open: boolean;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  portalUrl: string | undefined;
}

export const ContactChip = ({
  onClick,
  onClose,
  anchorEl,
  open,
  name,
  firstName,
  lastName,
  email,
  id,
  portalUrl,
}: ContactChipProps) => {
  // identify if we're on archived and draft page
  const location = useLocation();

  const isArchived = location.pathname.includes('archived');
  const isDraft = location.pathname.includes('draft');

  return (
    <div data-cy="contact-chip">
      <StyledAvatarTooltip
        title={createTooltip(
          stringAvatar(firstName, lastName),
          name,
          email ?? '',
        )}
      >
        <div>
          <StyledChip
            onClick={onClick}
            aria-describedby={id}
            color="contact"
            label={
              <>
                {!isArchived && !isDraft && (
                  <span>
                    <a href={`${portalUrl}`} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={solid('link')} />
                    </a>
                    &nbsp;
                  </span>
                )}
                {name}
              </>
            }
            data-cy={`tag-${name}`}
            sx={{
              height: 'auto',
              '& .MuiChip-label': {
                display: 'block',
                whiteSpace: 'normal',
                textAlign: 'left',
              },
            }}
          />
        </div>
      </StyledAvatarTooltip>
    </div>
  );
};

export const SendToLine = ({
  label,
  tags,
  'data-cy': dataCy,
  ...rest
}: SendToLineProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ToLine data-cy={dataCy} {...rest}>
      <ToLineLabel>{label}:</ToLineLabel>
      <ToLineTags>
        {tags &&
          tags.map((tag) => {
            return (
              <ContactChip
                id={tag.id}
                key={tag.id}
                data-cy={`tag-${tag.name}`}
                onClick={handleClick}
                onClose={handleClose}
                open={open}
                name={tag.name}
                firstName={tag.firstName}
                lastName={tag.lastName}
                email={tag.email}
                anchorEl={anchorEl}
                portalUrl={tag.portal_url}
              />
            );
          })}
      </ToLineTags>
    </ToLine>
  );
};

export default SendToLine;
