import { useGetWorkspaceQuery } from '@gen2/api/workspaces/hooks';
import { sortOptions } from '@gen2/app/invite-listing-v2/invite-listing';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useTranslation } from 'react-i18next';
import { useWorkspacesStore } from '../../store';
import {
  ViewDetailsContent,
  ViewDetailsLabel,
  ViewDetailsText,
} from './view-details.styled';

const ViewDetailsModal = () => {
  const { t } = useTranslation('workspaces');
  const { view, closeViewModal } = useWorkspacesStore();
  const { data } = useGetWorkspaceQuery({
    id: view.id,
  });

  const onClose = () => {
    closeViewModal();
  };

  const getFiltersTextList = () => {
    if (!data || !data.filters) return '--';

    let list: Array<{
      label: string;
      values: Array<{ id: string; name: string } | string>;
    }> = [];

    // Keyword
    if (data.filters?.subjectQuery) {
      list = list.concat({
        label: 'Keyword',
        values: [data.filters.subjectQuery],
      });
    }

    // POC
    if (data.filters?.poc?.length) {
      list = list.concat({
        label: 'POC',
        values: data.filters.poc,
      });
    }

    // Assignees
    if (data.filters?.assignees?.length) {
      list = list.concat({
        label: 'Assignees',
        values: data.filters.assignees,
      });
    }

    if (data.filters?.contacts?.length) {
      list = list.concat({
        label: 'Contacts',
        values: data.filters.contacts,
      });
    }

    // Teams
    if (data.filters?.teams?.length) {
      list = list.concat({
        label: 'Teams',
        values: data.filters.teams,
      });
    }

    // Indicators
    if (data.filters?.indicators?.length) {
      list = list.concat({
        label: 'Indicators',
        values: data.filters.indicators.map((indicator) => ({
          id: '',
          name: indicator,
        })),
      });
    }

    // Sorting
    if (data.filters?.sortOrder && data.filters?.sortColumn) {
      list = list.concat({
        label: 'Sort',
        values: [
          {
            id: '',
            name:
              sortOptions.find(
                (option) =>
                  option.column === data.filters.sortColumn &&
                  option.order === data.filters.sortOrder,
              )?.label ?? '',
          },
        ],
      });
    }

    return list
      .map((item) => {
        return `${item.label}: "${item.values
          .map(
            (value) => (typeof value === 'string' ? value : value.name) ?? '--',
          )
          .join(', ')}"`;
      })
      .join(', ');
  };

  return (
    <Dialog
      open={view.isOpen}
      onClose={onClose}
      aria-labelledby="view-details-workspace-modal-title"
      aria-describedby="view-details-workspace-modal-description"
      data-cy="view-details-workspace-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogTitle data-cy="title" id="view-details-workspace-modal-title">
        {t('view.header')}
      </DialogTitle>
      <DialogContent data-cy="content" dividers>
        <ViewDetailsContent>
          <ViewDetailsLabel>View Name:</ViewDetailsLabel>
          <ViewDetailsText>{data?.name ?? '--'}</ViewDetailsText>

          <ViewDetailsLabel>Created By:</ViewDetailsLabel>
          <ViewDetailsText>
            {data?.creator?.first_name + ' ' + data?.creator?.last_name}
          </ViewDetailsText>

          <ViewDetailsLabel>Filters Applied:</ViewDetailsLabel>
          <ViewDetailsText>{getFiltersTextList()}</ViewDetailsText>
        </ViewDetailsContent>
      </DialogContent>
    </Dialog>
  );
};

export default ViewDetailsModal;
