import { colors, weights } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledViewControls = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const ViewFinder = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(12),
});

export const ViewFinderIcon = styled(FontAwesomeIcon)({
  fontSize: rem(16),
});

export const ViewFinderLabel = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(4),
  fontWeight: weights.medium,
  color: colors.grayDark2,
});

export const ViewButtonGroup = styled('div')({
  display: 'flex',
  gap: rem(8),
  alignItems: 'center',
});
