import axios from '@gen2/api/axios.request';
import { config } from '@gen2/config';
import { PermissionsKeys } from '@gen2/types/permissions';
import { TPhone } from '@gen2/types/phone';
import { SearchCondition } from '@gen2/types/search-condition';
import { AxiosPromise, AxiosResponse } from 'axios';

export interface TeamMemberListPayload {
  'filter[full_name]'?: string;
  'filter[email]'?: string;
  'filter[user]'?: string;
  'filter[search]'?: string;
  'filter[status]'?: string;
  'filter[permission]'?: PermissionsKeys[];
  'sort[column]': string;
  'sort[order]': string;
  'filter[active]'?: number;
  per_page: number;
  page: number;
}

export interface ITeamMemberForm {
  first_name: string;
  last_name: string;
  email: string;
  id?: string;
  position: string;
  position_other?: string;
  phone?: Omit<TPhone, 'id'>;
  role_id?: string;
}

export interface TeamMemberActivityLogDownloadPayload {
  signature: string;
  teamMemberId: string;
  expires?: number;
  fileId: string;
}

export const getTeamMemberList = (
  search: TeamMemberListPayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users`,
    params: {
      ...search,
      'filter[permission]': search['filter[permission]']?.join(','),
    },
    method: 'get',
  });
};

export const createUser = (
  form: ITeamMemberForm,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users`,
    data: form,
    method: 'post',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUser = (
  form: ITeamMemberForm,
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/${form.id}`,
    data: form,
    method: 'put',
  });
};

export const deactivateUser = (userId: string): AxiosPromise<AxiosResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/${userId}/deactivate`,
    method: 'put',
  });
};

export const reactivateUser = (userId: string): AxiosPromise<AxiosResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/${userId}/reactivate`,
    method: 'put',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTeamMember = (userId: string): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/users/${userId}`,
    method: 'get',
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTeamMemberPositions = (): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/positions`,
    method: 'get',
  });

export type TActivityLogsRange = {
  from?: string;
  to?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTeamMemberActivityLogs = (
  search: SearchCondition & TActivityLogsRange,
  userId: string,
): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/users/${userId}/activity-log`,
    params: {
      ...search,
    },
    method: 'get',
  });

export const generateTeamMemberActivityLogs = ({
  userId,
}: {
  userId: string;
}): AxiosPromise<unknown | any> =>
  axios.request({
    url: `${config.apiVersion.v1}/users/${userId}/activity-log-export`,
    method: 'post',
  });

export const getTeamMemberActivityLogsDownload = (payload: TeamMemberActivityLogDownloadPayload) => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/${payload.teamMemberId}/activity-log-download/${payload.fileId}`,
    params: {
      signature: payload.signature,
      expires: payload.expires,
    },
    method: 'get',
  }).then(response => response.data);
};