import { config } from '@gen2/config';
import { TActivityLog } from '@gen2/types/user';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';
import { TQrCodeResponse, TTwoFactorAuthResponse } from './hooks';

export interface IVerifyTwoFactorAuthRequest {
  code: string;
}

export interface IChangePasswordRequest {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface ILastAccountOrganisationRequest {
  account_id: string;
  organisation_id: string;
}

export interface IBackupTwoFactorAuthRequest {
  backup_code: string;
}

export type AvatarUploadRequest = {
  id: string;
  file: FormData;
};

export const getQrCode = (): AxiosPromise<TQrCodeResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa/qr-code`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const generateBackupCode = (
  data?: [],
): AxiosPromise<TTwoFactorAuthResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa/backup-codes`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const enableTwoFactorAuth = (
  data?: [],
): AxiosPromise<TTwoFactorAuthResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const verifyTwoFactorAuth = (
  data: IVerifyTwoFactorAuthRequest,
): AxiosPromise<TTwoFactorAuthResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa/confirm`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const challengeTwoFactorAuth = (
  data: IVerifyTwoFactorAuthRequest,
): AxiosPromise<TTwoFactorAuthResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa/challenge`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const backupTwoFactorAuth = (
  data: IBackupTwoFactorAuthRequest,
): AxiosPromise<TTwoFactorAuthResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa/backup`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const disableTwoFactorAuth = (
  data?: string,
): AxiosPromise<TTwoFactorAuthResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/users/mfa`,
    data,
    method: 'delete',
    headers: {
      Accept: 'application/json',
    },
  });
};

export interface IProfileSettingsForm {
  email?: string;
  first_name?: string;
  last_name?: string;
}

export const updateProfileSettings = (
  form: IProfileSettingsForm,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/profile`,
    data: form,
    method: 'put',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const uploadProfileAvatar = ({ id, file }: AvatarUploadRequest) => {
  return axios.request({
    url: `${config.apiVersion.v1}/files/avatar/${id}`,
    method: 'post',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const changePassword = (
  form: IChangePasswordRequest,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/change-password`,
    method: 'put',
    data: form,
    headers: {
      Accept: 'application/json',
    },
  });
};

export const setLastAccountOrganisation = (
  form: ILastAccountOrganisationRequest,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosPromise<unknown | any> => {
  return axios.request({
    url: `${config.apiVersion.v1}/last-account-organisation`,
    method: 'put',
    data: form,
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TActivityLogRequest = {
  page?: number;
  per_page?: number;
  userId: string;
};

export type TActivityLogResponse = {
  data: {
    activity_logs: TActivityLog[];
  };
  message: string;
};
