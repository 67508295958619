import { AnnotationTypeEnum } from './types';

const getAnnotationRenderers = ({ annotation }: any) => {
  let renderConfiguration: any = null;

  if (
    annotation.name
    && annotation.customData?.type
    && (
      annotation.customData.type === AnnotationTypeEnum.SIGNATURE
      || annotation.customData.type === AnnotationTypeEnum.INITIAL
    )
  ) {
    renderConfiguration = {
      node: createCustomSignatureNode({
        annotation,
        type: annotation.customData?.type,
      }),
      append: true,
    };
  }

  return renderConfiguration;
};

export default getAnnotationRenderers;

function createCustomSignatureNode({ annotation, type }: any) {
  const container = document.createElement('div');

  const nameDisplay = annotation.subject === 'multi'
    ? `<span class="custom-name-label">${annotation.customData.name}</span>`
    : '';

  if (type === AnnotationTypeEnum.SIGNATURE) {
    container.innerHTML =
      `<div class="custom-annotation-wrapper custom-signature-wrapper" style="height:3.75rem;>
        <div class="custom-signature">
          ${nameDisplay}
          <div class="custom-signature-label">
            + Add Signature
          </div>
        </div>
      </div>`;
  } else if (type === AnnotationTypeEnum.INITIAL) {
    container.innerHTML =
      `<div class="custom-annotation-wrapper custom-signature-wrapper" style="height:3.25rem;">
        <div class="custom-signature">
          ${nameDisplay}
          <div class="custom-signature-label">
            + Add Initial
          </div>
        </div>
      </div>`;
  } else if (type === AnnotationTypeEnum.DS) {
    container.innerHTML =
      `<div class="custom-annotation-wrapper custom-signature-wrapper" style="height: 6rem;">
        <div class="custom-signature">
          <div class="custom-signature-label">
            Digital Signature
          </div>
          <svg fill="#000000" width="1.5625rem" height="1.25rem" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>down-round</title>
            <path d="M0 16q0-3.232 1.28-6.208t3.392-5.12 5.12-3.392 6.208-1.28q3.264 0 6.24 1.28t5.088 3.392 3.392 5.12 1.28 6.208q0 3.264-1.28 6.208t-3.392 5.12-5.12 3.424-6.208 1.248-6.208-1.248-5.12-3.424-3.392-5.12-1.28-6.208zM4 16q0 3.264 1.6 6.048t4.384 4.352 6.016 1.6 6.016-1.6 4.384-4.352 1.6-6.048-1.6-6.016-4.384-4.352-6.016-1.632-6.016 1.632-4.384 4.352-1.6 6.016zM10.048 18.4q-0.128-0.576 0.096-1.152t0.736-0.896 1.12-0.352h2.016v-5.984q0-0.832 0.576-1.408t1.408-0.608 1.408 0.608 0.608 1.408v5.984h1.984q0.608 0 1.12 0.352t0.736 0.896q0.224 0.576 0.096 1.152t-0.544 1.024l-4 4q-0.576 0.576-1.408 0.576t-1.408-0.576l-4-4q-0.448-0.416-0.544-1.024z"></path>
          </svg>
        </div>
      </div>`;
  }

  return container;
}
