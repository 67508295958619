/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useInviteQuery } from '@gen2/api/invites/hooks';
import {
  useGetLiveFormQuery,
  useSaveLiveFormMutation,
  useUpdateLiveFormMutation,
} from '@gen2/api/live-form/hooks';
import {
  HeaderTitle,
  StyledHeader,
  StyledHeaderActions,
  StyledViewer,
  StyledWebViewerBox,
} from '@gen2/app/components/invite-request/variant/live-form/peview-web-viewer-modal.styled';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useAuth, useToast } from '@gen2/hooks';
import { TUser } from '@gen2/types/user';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog } from '@mui/material';
import NutrientViewer, { Instance } from '@nutrient-sdk/viewer';
import { customDecode } from '@nx-fe/components';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useUnmount } from 'usehooks-ts';
import { onDragStart } from './nutrient/sidePanel/helpers';
import SidePanel from './nutrient/sidePanel/sidePanel';
import {
  CustomInstantJSON,
  generateInstantJSON,
  handleAnnotationCreation,
  handleAnnotationDelete,
  TOOLBAR_ITEMS,
} from './nutrient/utils/common-helpers';
import getAnnotationRenderers from './nutrient/utils/customAnnotationRenderer';
import customizeUIForInitials from './nutrient/utils/customizeUIForInitials';
import handleDrop from './nutrient/utils/dropAnnotations';
import { onPressDuplicate, userChange } from './nutrient/utils/helpers';
import { TAssignee } from './nutrient/utils/types';

const defaultAssignee = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
};

export interface FormViewerModalProps {
  fileId: string;
  fileName: string;
  onClose: () => void;
  open: boolean;
  fileUrl: string;
}

let renderCount = 0;
export const NutrientFormViewerModal = ({
  fileName,
  onClose,
  open,
  fileId,
  fileUrl,
}: FormViewerModalProps) => {
  const viewer = useRef(null);

  const { inviteId } = useParams<{
    inviteId: string;
  }>();

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const toast = useToast();
  const { user } = useAuth();
  const siStore = useSendInviteStore();
  const { mutate: saveMutate, isLoading: saveLoading } = useSaveLiveFormMutation();
  const { mutate: updateMutate, isLoading: updateLoading } = useUpdateLiveFormMutation();
  const { data: invite } = useInviteQuery(inviteId || siStore?.invite?.id, {
    enabled: Boolean(inviteId || siStore?.invite?.id),
  });
  const contacts: TAssignee[] = useMemo(() => invite?.contacts || [], [invite]);

  const [nutrientInstance, setNutrientInstance] = useState<any>(null);

  // Solution：solely to work around an issue that only appears in Development mode,
  // forcing WebViewer Initialization Only Occurs Once in Development build -- the Strict Mode
  const beenInitialised = useRef<boolean>(false);

  // State to store the current signee i.e the user who is currently selected for which the field will be added
  const initialAssignee = contacts.length > 0 ? contacts[0] : defaultAssignee;
  const [currSignee, setCurrSignee] = useState(initialAssignee);
  const currSigneeRef = useRef(currSignee);
  currSigneeRef.current = currSignee;

  // State to store the current user i.e the user who is currently Loggedin
  const [currUser] = useState<TAssignee>(user as TAssignee);
  const currUserRef = useRef(currUser);
  currUserRef.current = currUser;

  // State to store the current page index
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const { data } = useGetLiveFormQuery(fileId);

  const instantJSON: CustomInstantJSON = useMemo(() => {
    const initialJsonData: CustomInstantJSON = {
      format: 'https://pspdfkit.com/instant-json/v1',
      customFromAPI: true,
    };

    if (data !== undefined) {
      const responseData = data?.liveform?.fields?.json_data;

      if (responseData) {
        return {
          ...initialJsonData,
          ...responseData,
        };
      }

      return initialJsonData;
    }

    return {
      ...initialJsonData,
      customFromAPI: false,
    };
  }, [data]);

  const liveFormFieldId = useMemo(() => {
    if (data?.liveform?.fields?.id) {
      return data?.liveform?.fields?.id;
    }

    return '';
  }, [data?.liveform?.fields?.id]);

  // For Custom signature / initial field appearance
  const mySignatureIdsRef = useRef([]);
  const [signatureAnnotationIds, setSignatureAnnotationIds] = useState<
    string[]
  >([]);

  // For Custom Add Signature / Intitial field appearance
  const [sessionSignatures, setSessionSignatures] = useState<any>([]);
  const [sessionInitials, setSessionInitials] = useState<any>([]);

  function onDragEnd(event: React.DragEvent<HTMLDivElement>) {
    (event.target as HTMLDivElement).style.opacity = '1';
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const [isTextAnnotationMovable, setIsTextAnnotationMovable] = useState(false);
  const isTextAnnotationMovableRef = useRef(isTextAnnotationMovable);
  isTextAnnotationMovableRef.current = isTextAnnotationMovable;

  // Tracking whether add Signature/Initial UI
  let isCreateInitial = false;
  const trackInst = useRef<any>(null);
  const loadPSPDFKit = useCallback(
    async (instantJSON: CustomInstantJSON, el?: HTMLElement) => {
      if (NutrientViewer && fileUrl && el && !beenInitialised.current) {
        NutrientViewer.unload(el);
        beenInitialised.current = true;
        try {
          const {
            UI: { createBlock, Recipes, Interfaces },
          } = NutrientViewer;

          await NutrientViewer.load({
            theme: NutrientViewer.Theme.LIGHT,
            container: el,
            document: fileUrl,
            instantJSON: instantJSON,
            licenseKey: customDecode(window.$xr9c42),
            baseUrl: `${window.location.protocol}//${window.location.host}/assets/lib/pspdfkit/`,
            toolbarItems: [...TOOLBAR_ITEMS].filter(
              (item) => !['export-pdf', 'print'].includes(item.type),
            ),
            disableTextSelection: false,
            autoSaveMode: NutrientViewer.AutoSaveMode.IMMEDIATE,
            initialViewState: new NutrientViewer.ViewState({
              interactionMode: NutrientViewer.InteractionMode.FORM_CREATOR,
            }),
            styleSheets: [`/assets/lib/pspdfkit/viewer.css`],
            isEditableAnnotation: (annotation) => !annotation.isSignature,
            ui: {
              [Interfaces.CreateSignature]: ({ props }: { props: any }) => ({
                content: createBlock(
                  Recipes.CreateSignature,
                  props,
                  ({ ui }: { ui: any }) => {
                    if (isCreateInitial) ui = customizeUIForInitials(ui);
                    return ui.createComponent();
                  },
                ).createComponent(),
              }),
            },
            customRenderers: {
              Annotation: ({ annotation }) => {
                return getAnnotationRenderers({ annotation });
              },
            },
          }).then((instance) => {
            setLoading(false);
            trackInst.current = instance;
            setNutrientInstance(instance);
            configureInstanceEvents(instance); // Configure instance-specific events
          });
        } catch (error) {
          console.error('Error loading PSPDFKit:', error);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setTimeout(() => loadPSPDFKit(instantJSON, viewer.current!));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const cleanupPSPDFKit = async () => {
    if (nutrientInstance && viewer.current) {
      await NutrientViewer.unload(viewer.current);
      viewer.current = null;
      setNutrientInstance(null);
    }
  };

  useEffect(() => {
    if (
      instantJSON &&
      instantJSON.customFromAPI &&
      viewer.current &&
      !isEmpty(instantJSON) &&
      renderCount === 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      loadPSPDFKit(instantJSON, viewer.current!);
      renderCount++;
    }
  }, [instantJSON, loadPSPDFKit]);

  useEffect(() => {
    if (checked) {
      userChange(currUser, NutrientViewer, nutrientInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useUnmount(async () => {
    await cleanupPSPDFKit();
    renderCount = 0;
  });

  const configureInstanceEvents = (inst: Instance) => {
    // Additional configurations and event listeners for the PSPDFKit instance

    inst.setViewState((viewState) =>
      viewState.set(
        'showSignatureValidationStatus',
        NutrientViewer.ShowSignatureValidationStatusMode.IF_SIGNED,
      ),
    );

    inst.addEventListener('viewState.currentPageIndex.change', (page) =>
      setOnPageIndex(page),
    );

    //@ts-ignore
    const cont = inst.contentDocument.host;
    cont.ondrop = async (e: any) =>
      await handleDrop(
        e,
        inst,
        NutrientViewer,
        currSigneeRef,
        currUserRef,
        onPageIndexRef,
      );

    inst.addEventListener(
      'annotations.press',
      (event: { preventDefault?: any; annotation?: any; }) => {
        if (!viewer.current) {
          return; // Prevents error when the viewer is not mounted
        }
        const { annotation } = event;
        if (annotation.customData?.isInitial) {
          setSessionInitials((prev: any) => [...prev, annotation]);
          isCreateInitial = true;
        } else {
          setSessionSignatures((prev: any) => [...prev, annotation]);
          isCreateInitial = false;
        }

        if (
          !isTextAnnotationMovableRef.current &&
          annotation instanceof NutrientViewer.Annotations.TextAnnotation
        ) {
          event.preventDefault();
        }

        //If it's a text widget show duplicate in popup
        setTimeout(() => {
          const popupFooterDiv = inst.contentDocument.querySelector(
            '.PSPDFKit-2wtzexryxvzwm2ffu1e1vh391u',
          );
          const existingDuplicateButton = inst.contentDocument.querySelector(
            `.PSPDFKit-Form-Creator-Editor-Duplicate`,
          );
          // Remove existing duplicate button if it exists
          if (existingDuplicateButton) {
            existingDuplicateButton.remove();
          }

          // Add duplicate button if it doesn't exist
          if (popupFooterDiv) {
            const duplicateButtonHTML = document.createElement('button');
            duplicateButtonHTML.innerHTML = 'Duplicate';
            duplicateButtonHTML.className =
              'PSPDFKit-239sjtxdzvwfjbe3ass6aqfp77 PSPDFKit-7kqbgjh4u33aw27zwkrtu57p2e PSPDFKit-68w1xn1tjp178f46bwdhvjg7f1 PSPDFKit-Form-Creator-Editor-Duplicate ' +
              annotation.Id;
            duplicateButtonHTML.onclick = async () => {
              await onPressDuplicate(
                annotation,
                NutrientViewer,
                trackInst.current,
              );
            };
            popupFooterDiv.appendChild(duplicateButtonHTML);
          }
        }, 1);

        if (
          !isTextAnnotationMovableRef.current &&
          event.annotation instanceof NutrientViewer.Annotations.TextAnnotation
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          event.preventDefault();
        }
      },
    );

    inst.addEventListener(
      'storedSignatures.create',
      async (annotation: any) => {
        if (isCreateInitial) {
          setSessionInitials([...sessionInitials, annotation]);
        } else {
          setSessionSignatures([...sessionSignatures, annotation]);
        }
      },
    );

    inst.addEventListener(
      'annotations.create',
      async function (createdAnnotations: any) {
        const annotation = createdAnnotations.get(0);
        await handleAnnotationCreation(
          inst,
          annotation,
          mySignatureIdsRef,
          setSignatureAnnotationIds,
          currUser.email,
        );
      },
    );

    inst.addEventListener(
      'annotations.delete',
      async (deletedAnnotations: any) => {
        const annotation = deletedAnnotations.get(0);
        await handleAnnotationDelete(inst, annotation, currUser?.email);
        const updatedAnnotationIds = mySignatureIdsRef.current.filter(
          (id) => id !== annotation.id,
        );
        setSignatureAnnotationIds(updatedAnnotationIds);
        mySignatureIdsRef.current = updatedAnnotationIds;
      },
    );

    setIsTextAnnotationMovable(true);
  };

  const handleAssigneeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAssignee = contacts.find(
      (contact) => contact.email === event.target.value,
    );
    setCurrSignee(selectedAssignee || defaultAssignee);
  };

  const onSave = async () => {
    const instantJSON = await nutrientInstance.exportInstantJSON();

    const payloadInstantJSON = await generateInstantJSON(instantJSON);


    const responseHandlers = {
      onSuccess: () => {
        toast.show({
          text: 'File data saved successfully',
          variant: 'success',
        });

        handleClose();
      },
      onError: (e: unknown) => {
        if ((e as AxiosError).status === 500) {
          toast.show({
            text: 'Internal Server Error',
            variant: 'error',
          });
        } else if ((e as AxiosError).status === 422) {
          toast.show({
            text: 'The selected type is invalid',
            variant: 'error',
          });
        }
      },
    };

    if (liveFormFieldId) {
      updateMutate(
        {
          fileId,
          liveFormFieldId,
          liveFormData: {
            xfdf_data: '',
            json_data: JSON.stringify(payloadInstantJSON),
            type: siStore.liveFormType,
          },
        },
        responseHandlers,
      );
    } else {
      saveMutate(
        {
          fileId,
          liveFormData: {
            xfdf_data: '',
            json_data: JSON.stringify(payloadInstantJSON),
            type: siStore.liveFormType,
          },
        },
        responseHandlers,
      );
    }
  };

  const applyAndSave = async () => {
    try {
      await onSave();
    } catch (e) {
      console.error(e);
      toast.show({
        text: 'Something went wrong! Reload the page and try again',
        variant: 'error',
      });
    }
  };

  const handleClose = async () => {
    await cleanupPSPDFKit();
    onClose();
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChangeReadyToSign(event.target.checked, user, NutrientViewer);
  };

  const onChangeReadyToSign = async (
    value: boolean,
    user: TUser | null,
    PSPDFKit: any,
  ) => {
    if (nutrientInstance) {
      if (value) {
        nutrientInstance.setViewState((viewState: any) =>
          viewState.set('interactionMode', PSPDFKit.InteractionMode.PAN),
        );
        nutrientInstance.setViewState((viewState: any) =>
          viewState.set('showToolbar', false),
        );
        setIsTextAnnotationMovable(false);
      } else {
        nutrientInstance.setViewState((viewState: any) =>
          viewState.set(
            'interactionMode',
            PSPDFKit.InteractionMode.FORM_CREATOR,
          ),
        );
        nutrientInstance.setViewState((viewState: any) =>
          viewState.set('showToolbar', true),
        );
        setIsTextAnnotationMovable(true);
      }
    }
  };

  return (
    <Dialog
      id="form-viewer-modal"
      fullScreen
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
    >
      <StyledHeader>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button variant="outlined" color="tertiary" onClick={handleClose}>
            Back
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={applyAndSave}
            loading={saveLoading || updateLoading}
          >
            Save & close
          </LoadingButton>
        </StyledHeaderActions>
      </StyledHeader>
      <StyledWebViewerBox>
        <SidePanel
          users={contacts}
          currSignee={currSignee}
          onDragStart={(event: React.DragEvent<HTMLDivElement>, type: string) =>
            onDragStart(
              event,
              type,
              currSignee,
              nutrientInstance,
              siStore.liveFormType,
            )
          }
          onDragEnd={onDragEnd}
          handleChange={handleAssigneeChange}
          type={siStore.liveFormType}
          checked={checked || loading}
          handleCheck={handleCheck}
        />
        <StyledViewer ref={viewer} onDragOver={handleDragOver} />
      </StyledWebViewerBox>
    </Dialog>
  );
};
