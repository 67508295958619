import { colors, weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const StyledWorkspaces = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingTop: rem(30),
  paddingBottom: rem(30),
  backgroundColor: colors.grayLight2,
  height: '100%',
});

export const StyledWorkspacesContent = styled('div')({
  maxWidth: rem(640),
  width: '100%',
});

export const StyledManageInviteViews = styled('div')({
  backgroundColor: colors.white,
  borderRadius: rem(4),
  borderTopLeftRadius: 0,
});

export const ViewFinder = styled('div')({
  padding: rem(20),
  display: 'flex',
  flexDirection: 'column',
});

export const WorkspaceTitle = styled('div')({
  fontSize: rem(18),
  fontWeight: weights.semiBold,
});

export const WorkspaceText = styled('div')({
  marginBottom: rem(20),
});

export const ViewFinderField = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: rem(20),

  '& .MuiAutocomplete-root': {
    width: '100%',
  },

  button: {
    flexShrink: 0,
  },
});

export const Views = styled('div')({
  padding: rem(20),
});
