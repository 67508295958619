import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDeleteFilesMutation } from '@gen2/api/files/hooks';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import {
  InviteRequestsKeys,
  useUpdateInviteRequestMutation,
} from '@gen2/api/invite-requests/hooks';
import {
  TemplateKeys,
  useUpdateTemplateRequestMutation,
} from '@gen2/api/templates/hooks';
import { TInviteRequestsForm } from '@gen2/app/invites/send-invites/requests/requests';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FileUploadRequest } from '../variant/file-upload/file-upload';
import { LiveFormRequest } from '../variant/live-form/live-form';

export type TRequestContainer = 'invite' | 'template' | 'follow-up';

export type TUseRequestHook = {
  id: number;
  container?: TRequestContainer;
};

export type RequestType = {
  label: string;
  value: string;
  icon: IconDefinition;
  component: React.FC<{
    id: number;
    container?: TRequestContainer;
  }>;
};

export type RequestTypes = Array<RequestType>;

export const types: RequestTypes = [
  {
    label: 'File Upload',
    value: 'file-upload',
    icon: regular('upload'),
    component: FileUploadRequest,
  },
  {
    label: 'Form',
    value: 'live-form',
    icon: regular('pencil'),
    component: LiveFormRequest,
  },
  {
    label: 'Read Only',
    value: 'read-only',
    icon: regular('glasses'),
    component: FileUploadRequest,
  },
];

export const useRequestHook = ({ container, id }: TUseRequestHook) => {
  const {
    formState,
    getValues,
    trigger,
    setValue,
  } = useFormContext<TInviteRequestsForm>();
  const req = getValues().requests[id];

  const { mutate: updateInviteRequestMutation } =
    useUpdateInviteRequestMutation();
  const { mutate: updateTemplateRequestMutation } =
    useUpdateTemplateRequestMutation();
  const { mutate: deleteFilesMutation } = useDeleteFilesMutation();
  const siStore = useSendInviteStore();
  const { inviteId, templateId } = useParams<{
    inviteId: string;
    templateId: string;
  }>();
  const { t } = useTranslation(['sendInvite', 'templates']);
  const toast = useToast();
  const defaultRequestType = useMemo(() => {
    return types.find((t) => t.value === req?.type) ?? types[0];
  }, [req]);

  const [requestType, setRequestType] =
    useState<RequestType>(defaultRequestType);

  const inviteUpdate = () => {
    const { pdf_redactions, ...payload } = req;

    updateInviteRequestMutation(
      {
        inviteId: inviteId || siStore.invite.id,
        ...payload,
      },
      {
        onSuccess: () => {
          siStore.setBannerMessage({
            severity: 'success',
            message: siStore.isEditSendInvite
              ? t('notDraftSaved')
              : t('draftSaved'),
          });
        },
      },
    );
  };

  const templateUpdate = () => {
    const { pdf_redactions: _, ...payload } = req;

    updateTemplateRequestMutation(
      {
        templateId: templateId ?? '',
        ...payload,
      },
      {
        onSuccess: () => {
          toast.show({
            variant: 'success',
            text: t('form.save.success', { ns: 'templates' }),
          });
        },
      },
    );
  };

  const onUpdate = async () => {
    // Get real time validation
    const isValid = await trigger(`requests.${id}`);

    if (!isValid) return;

    switch (container) {
      case 'template':
        return templateUpdate();
      case 'invite':
      case 'follow-up':
        return inviteUpdate();
      default:
        throw new Error('Invalid container');
    }
  };

  const templateTypeChange = async (title: string, type: string) => {
    const { pdf_redactions, ...payload } = req;

    updateTemplateRequestMutation(
      {
        templateId: templateId ?? '',
        ...payload,
        title: title,
        type: type,
      },
      {
        onSuccess: () => {
          toast.show({
            variant: 'success',
            text: t('form.save.success', { ns: 'templates' }),
          });

          setValue(`requests.${id}.pdf_redactions`, []);

          queryClient.invalidateQueries([TemplateKeys.getTemplateRequests]);
        },
      },
    );
  };

  const inviteTypeChange = async (title: string, type: string) => {
    const { pdf_redactions, ...payload } = req;

    updateInviteRequestMutation(
      {
        inviteId: inviteId || siStore.invite.id,
        ...payload,
        pdf_redactions: [],
        title: title,
        type: type,
      },
      {
        onSuccess: () => {
          siStore.setBannerMessage({
            severity: 'success',
            message: siStore.isEditSendInvite
              ? t('notDraftSaved')
              : t('draftSaved'),
          });

          setValue(`requests.${id}.pdf_redactions`, []);
          queryClient.invalidateQueries([InviteRequestsKeys.getInviteRequests]);
          queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);
          queryClient.invalidateQueries([FollowUpKeys.getFollowUps]);
        },
      },
    );
  };

  const onChangeVariant = async (type: string) => {
    const selectedType = types.find((t) => t.value === type) ?? types[0];
    if (selectedType.value === requestType.value) return;

    setRequestType(selectedType);
    setValue(`requests.${id}.type`, selectedType.value);

    // Prepare payload
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { pdf_redactions, ...payload } = req;
    const placeholderTitle = `New ${selectedType.label} Request`;
    const placeholderTitleRegex = /^New (File Upload|Form|Read Only) Request$/g;
    const updatedTitle = placeholderTitleRegex.test(req.title)
      ? placeholderTitle
      : req.title;

    switch (container) {
      case 'template':
        templateTypeChange(updatedTitle, selectedType.value);
        break;
      case 'invite':
      case 'follow-up':
        inviteTypeChange(updatedTitle, selectedType.value);
        break;
      default:
        throw new Error('Invalid container');
    }

    // Clear all files
    // if there are no files uploaded dont call this endpoint when changing request type
    // and wont call this endpoint when edit send invite, as deletion will be handled on BE
    if (req.files.length > 0 && !siStore.isEditSendInvite) {
      deleteFilesMutation({
        type: 'request',
        typeId: req.id,
        files: req.files.map((file) => ({ id: file.id })),
      });
    }
  };

  return {
    onUpdate,
    onChangeVariant,
    req,
  };
};
