import {
  useUpdateWorkspaceMutation,
  WorkspaceKeys,
} from '@gen2/api/workspaces/hooks';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useWorkspacesStore } from '../../store';
import { Content, FormGroup } from './rename.styled';
import { schema } from './schema';

type TRenameWorkspaceForm = {
  name: string;
};

export const defaultValues: TRenameWorkspaceForm = {
  name: '',
};

const RenameWorkspaceModal = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<TRenameWorkspaceForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const { rename, closeRenameModal } = useWorkspacesStore();
  const { mutate: updateWorkspaceMutation } = useUpdateWorkspaceMutation();
  const { t } = useTranslation('workspaces');
  const toast = useToast();

  const name = register('name');

  useEffect(() => {
    if (!rename.oldName) return;

    reset({ name: rename.oldName });
  }, [rename?.oldName]);

  const onSave: SubmitHandler<TRenameWorkspaceForm> = (data) => {
    updateWorkspaceMutation(
      {
        id: rename.id,
        name: data.name,
      },
      {
        onSuccess: async () => {
          toast.show({
            text: t('update.success'),
            variant: 'success',
          });

          await queryClient.invalidateQueries([WorkspaceKeys.getWorkspaces]);

          closeRenameModal();
        },
        onError: (err: any) => {
          setError('name', {
            type: 'manual',
            message: err.data.message,
          });
        },
      },
    );
  };

  const onClose = () => {
    reset(defaultValues);
    closeRenameModal();
  };

  return (
    <Dialog
      open={rename.isOpen}
      onClose={onClose}
      aria-labelledby="update-workspace-modal-title"
      aria-describedby="update-workspace-modal-description"
      data-cy="update-workspace-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogTitle data-cy="title" id="update-workspace-modal-title">
        {t('update.header')}
      </DialogTitle>
      <DialogContent data-cy="content" dividers>
        <Content>
          <FormGroup>
            <label htmlFor="name">Enter a name:</label>
            <TextField
              id="name"
              {...name}
              error={!!errors.name}
              helperText={t(errors.name?.message ?? '')}
              defaultValue={rename.oldName}
            />
          </FormGroup>
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="tertiary"
          variant="outlined"
          data-cy="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSave)}
          color="primary"
          variant="contained"
          data-cy="cancel-btn"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameWorkspaceModal;
