import { colors } from '@components/theme/gen2';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Heading,
  TabPanel as TabPanelContainer,
} from '@gen2/app/components/base-popup-with-tabs/base-popup-with-tabs.styled';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { rem } from 'polished';
import { TFilterInviteIndicator } from './filter';
import { IndicatorChipItem, IndicatorMenuItem } from './filter.styled';

type TItem = TFilterInviteIndicator;

const inviteIndicatorLabels: { [key: string]: string } = {
  sent: 'Sent',
  no_progress: 'No Progress',
  progressing: 'Progressing',
  awaiting_review: 'Awaiting Review',
  in_review: 'In Review',
  complete: 'Completed',
  due_today: 'Due Today',
  overdue: 'Overdue',
};

export type TIndicatorTabPanelProps<T> = {
  data: T[] | undefined;
  selectedItems: TFilterInviteIndicator[];
  setSelectedItems: (items: TFilterInviteIndicator[]) => void;
  title: string;
  subTitle?: string;
};

const IndicatorTabPanel = <T extends TItem>({
  data,
  selectedItems,
  setSelectedItems,
  title,
  subTitle,
}: TIndicatorTabPanelProps<T>) => {
  const handleDeleteChip = (
    event: React.MouseEvent,
    item: TFilterInviteIndicator,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedItems(selectedItems.filter((status) => status !== item));
  };

  const ITEM_HEIGHT = 60;
  const ITEM_MARGIN_TOP = rem(8);

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: ITEM_MARGIN_TOP,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_MARGIN_TOP,
        width: 250,
        boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
        border: `1px solid ${colors.blue}`,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<TFilterInviteIndicator[]>) => {
    const selectValues = event.target.value as string[];

    const filteredIndicators: T[] =
      data?.filter((status) => selectValues?.includes(status)) ?? [];

    setSelectedItems(filteredIndicators);
  };

  return (
    <TabPanelContainer>
      <Heading>
        <p data-cy="title">{title}</p>
        <span>{subTitle}</span>
      </Heading>

      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="select-multiple-chip-label">Select</InputLabel>
        <Select
          labelId="select-multiple-chip-label"
          id="select-multiple-chip"
          multiple
          MenuProps={MenuProps}
          value={selectedItems}
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Chip"
              sx={{ backgroundColor: 'red' }}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', gap: rem(6), flexWrap: 'wrap' }}>
              {selected.map((value) => (
                <IndicatorChipItem
                  // onDelete={(event) => handleDeleteChip(event, value)}
                  key={value}
                  color="contact"
                  size="small"
                  label={inviteIndicatorLabels[value]}
                  icon={
                    <IconButton
                      sx={{ padding: 0, width: rem(13), height: rem(13) }}
                      onClick={(event) => handleDeleteChip(event, value)}
                      size="small"
                    >
                      <FontAwesomeIcon
                        icon={solid('times-circle')}
                        size="xs"
                        color={colors.grayDark1}
                      />
                    </IconButton>
                  }
                  onMouseDown={(event) => event.stopPropagation()}
                />
              ))}
            </Box>
          )}
        >
          {data?.map((status) => (
            <IndicatorMenuItem key={status} value={status}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: rem(10) }}>
                <Checkbox
                  sx={{ height: rem(16), padding: 0 }}
                  color="secondary"
                  checked={selectedItems.includes(status)}
                ></Checkbox>
                <span className="status-label">
                  {inviteIndicatorLabels[status]}
                </span>
              </Box>
            </IndicatorMenuItem>
          ))}
        </Select>
      </FormControl>
    </TabPanelContainer>
  );
};

export default IndicatorTabPanel;
