import { styled } from '@mui/material';
import { rem } from 'polished';

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: rem(676),
  paddingLeft: rem(50),
  paddingRight: rem(50),
});

export const FormGroup = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(5),
  marginBottom: rem(10),
  width: rem(412),
});
