import {
  useDeleteWorkspaceMutation,
  WorkspaceKeys,
} from '@gen2/api/workspaces/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import TableActionItem from '@gen2/app/components/base-table/base-table-action-item';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { PermissionsKeys } from '@gen2/types/permissions';
import { useTranslation } from 'react-i18next';
import { useWorkspacesStore } from '../../store';

const Actions = ({ id, name }: { id: string; name: string }) => {
  const { mutate: deleteWorkspaceMutation } = useDeleteWorkspaceMutation();
  const toast = useToast();
  const { t } = useTranslation('workspaces');
  const { showModal } = useActionModalStore();
  const { openRenameModal, openViewModal } = useWorkspacesStore();

  const handleDelete = async () => {
    const res = await showModal({
      header: t('delete.header') ?? '',
      message: 'delete.description',
      translationNamespace: 'workspaces',
      messageParams: {
        name: "'All Invites'",
      },
      closeButtonLabel: t('delete.cancel') ?? '',
      submitButtonLabel: t('delete.confirm') ?? '',
    });

    if (!res.isConfirmed) return;

    deleteWorkspaceMutation(id, {
      onSuccess: async () => {
        toast.show({
          text: t('delete.success'),
          variant: 'info',
        });

        await queryClient.invalidateQueries([WorkspaceKeys.getWorkspaces]);
      },
      onError: () => {
        toast.show({
          text: t('delete.failed'),
          variant: 'error',
        });
      },
    });
  };

  const handleRename = () => {
    openRenameModal(id, name);
  };

  const handleView = () => {
    openViewModal(id);
  };

  return (
    <TableActionItem
      id="workspace-action"
      translationNamespace="workspaces"
      menuOptions={[
        {
          label: 'menu.edit',
          action: 'edit',
          visible: true,
          closeOnClick: true,
          onClick: handleRename,
          permission: PermissionsKeys.UPDATE_VIEW,
        },
        {
          label: 'menu.view',
          action: 'view',
          visible: true,
          closeOnClick: true,
          onClick: handleView,
          permission: PermissionsKeys.SHOW_VIEW,
        },
        {
          label: 'menu.delete',
          action: 'delete',
          visible: true,
          closeOnClick: true,
          onClick: handleDelete,
          permission: PermissionsKeys.DELETE_VIEW,
        },
      ]}
    />
  );
};

export default Actions;
