import SearchBar from '@components/lib/searchbar/searchbar';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageIconAvatar } from '@gen2/app/components/avatar/avatar.styled';
import {
  Heading,
  Item,
  List,
  StyledEmpty,
  TabPanel as TabPanelContainer,
} from '@gen2/app/components/base-popup-with-tabs/base-popup-with-tabs.styled';
import { Loading } from '@gen2/app/invite-listing/invite-listing-item/loading';
import { stringAvatar } from '@gen2/utils/name';
import { Checkbox } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { TFilter } from '../store';
import { ChipItem, ChipList, Info, Name, StyledAvatar } from './filter.styled';

type TItem = {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  avatar_url?: string;
  name?: string;
};

export type TabPanelProps<T> = {
  data: T[] | undefined;
  isLoading: boolean;
  selectedItems: TFilter[];
  setSelectedItems: (filter: TFilter[]) => void;
  handleSearch: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  searchQuery: string;
  handleReset: () => void;
  placeholder: string;
  emptyMessage: string;
  title: string;
  subTitle?: string;
  withAvatar?: boolean;
};

const TabPanel = <T extends TItem>({
  data,
  isLoading,
  selectedItems,
  setSelectedItems,
  handleSearch,
  searchQuery,
  handleReset,
  placeholder,
  title,
  subTitle = 'Search by name or email',
  emptyMessage,
  withAvatar = true,
}: TabPanelProps<T>) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: T,
  ) => {
    if (event.target.checked) {
      setSelectedItems([
        ...selectedItems,
        {
          id: item.id,
          name: item?.name
            ? item?.name
            : item?.first_name + ' ' + item?.last_name,
        },
      ]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
    }
  };

  const handleDeleteChip = (item: TFilter) => {
    setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
  };

  const selectedItemIds = useMemo(() => {
    return selectedItems.map((item) => item.id);
  }, [selectedItems]);

  const getName = useCallback((item: TFilter) => {
    if (item?.name) {
      return item.name;
    }

    return '';
  }, []);

  return (
    <TabPanelContainer>
      <Heading>
        <p data-cy="title">{title}</p>
        <span>{subTitle}</span>
      </Heading>
      <SearchBar
        onChange={handleSearch}
        value={searchQuery}
        onReset={handleReset}
        placeholder={placeholder}
        id="search-bar"
        autoFocus
        mode="dark"
        isVariant={false}
      />
      {isLoading ? (
        <Loading />
      ) : data?.length ? (
        <>
          <ChipList data-cy="chip-list">
            {selectedItems.map((item) => (
              <ChipItem
                key={item.id}
                color="contact"
                size="small"
                label={getName(item)}
                icon={<FontAwesomeIcon icon={solid('times-circle')} />}
                onClick={() => handleDeleteChip(item)}
              />
            ))}
          </ChipList>
          <List data-cy="search-result-list">
            {data.map((item) => (
              <Item key={item.id}>
                <Info>
                  {withAvatar &&
                    (item?.avatar_url ? (
                      <ImageIconAvatar src={item?.avatar_url} />
                    ) : (
                      <StyledAvatar>
                        {stringAvatar(item.first_name, item.last_name)}
                      </StyledAvatar>
                    ))}

                  <Name>
                    {getName({
                      id: item.id,
                      name: item?.name
                        ? item?.name
                        : item?.first_name + ' ' + item?.last_name,
                    })}
                    {item.email && <span>{item.email}</span>}
                  </Name>
                </Info>
                <Checkbox
                  color="secondary"
                  checked={selectedItemIds.includes(item.id)}
                  onChange={(event) => handleChange(event, item)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Item>
            ))}
          </List>
        </>
      ) : (
        <StyledEmpty>{emptyMessage}</StyledEmpty>
      )}
    </TabPanelContainer>
  );
};

export default TabPanel;
