import { useSafeQuery } from '@gen2/hooks';
import { useMutation } from '@tanstack/react-query';
import {
  defaultWorkspace,
  deleteWorkspace,
  getWorkspace,
  getWorkspaces,
  saveWorkspace,
  TGetWorkspaceParams,
  TGetWorkspacesParams,
  updateWorkspace,
} from './api';

export enum WorkspaceKeys {
  getWorkspaces = 'getWorkspaces',
  getWorkspace = 'getWorkspace',
  saveWorkspace = 'saveWorkspace',
  deleteWorkspace = 'deleteWorkspace',
  updateWorkspace = 'updateWorkspace',
}

export const useGetWorkspacesQuery = (params?: TGetWorkspacesParams) => {
  return useSafeQuery(
    [WorkspaceKeys.getWorkspaces, Object.values(params ?? {}).toString()],
    async () => {
      const res = await getWorkspaces(params);
      return {
        ...res?.data?.data,
        meta: res?.data?.links,
      };
    },
  );
};

export const useGetWorkspaceQuery = (params: TGetWorkspaceParams) => {
  return useSafeQuery(
    [WorkspaceKeys.getWorkspace, Object.values(params).toString()],
    async () => {
      const res = await getWorkspace(params);
      return res?.data?.data?.view;
    },
    {
      enabled: !!params?.id,
    },
  );
};

export const useSaveWorkspaceMutation = () =>
  useMutation({
    mutationFn: saveWorkspace,
  });

export const useDeleteWorkspaceMutation = () =>
  useMutation({
    mutationFn: deleteWorkspace,
  });

export const useUpdateWorkspaceMutation = () =>
  useMutation({
    mutationFn: updateWorkspace,
  });

export const useDefaultWorkspaceMutation = () =>
  useMutation({
    mutationFn: defaultWorkspace,
  });
