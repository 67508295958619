import {
  colors,
  headingSizes,
  textSizes,
  weights,
} from '@components/theme/gen2';
import { Stack, styled } from '@mui/material';
import { rem } from 'polished';

export const HeaderTitle = styled('h3')(({ theme }) => ({
  fontSize: headingSizes.h3,
  fontWeight: weights.semiBold,
  margin: 0,
  lineHeight: rem(20),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '70%',
  [theme.breakpoints.down('xs')]: { maxWidth: '40%', fontSize: textSizes.base },
}));

export const StyledHeader = styled('header')(() => ({
  width: '100%',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: rem(5),
  paddingBottom: rem(5),
  paddingLeft: rem(15),
  paddingRight: rem(15),
  height: rem(49),
  borderBottom: `${rem(1)} solid ${colors.grayLight1}`,
  '&.hidden': {
    position: 'fixed',
    top: rem(-49),
    left: 0,
  },
}));

export const StyledHeaderActions = styled(Stack)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    '& > button': {
      padding: `${rem(4)} ${rem(8)}`,
      minWidth: rem(64),
      minHeight: rem(28),
    },
  },
}));

export const StyledWebViewerBox = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const StyledAside = styled('aside')(({ theme }) => ({
  flex: `0 0  ${rem(260)}`,
  padding: rem(10),
  '> p': {
    lineHeight: rem(20),
    margin: `${rem(10)} 0`,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledViewer = styled('div')(({ theme }) => ({
  width: 'calc(100% - 260px)',
  height: '100vh',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledH3 = styled('h3')({
  color: colors.grayDark4,
  fontWeight: weights.semiBold,
});

export const StyledP = styled('p')({
  color: colors.grayDark3,
  fontWeight: weights.medium,
});
