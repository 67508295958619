import { baseType, colors, textSizes, weights } from '@components/theme/gen2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  MenuItem,
  Stack,
  styled,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
} from '@mui/material';
import { rem } from 'polished';

export const SortIcons = styled(FontAwesomeIcon)({
  fontSize: textSizes.sm1,
});

export const StyledListToolbar = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '> button': {
    marginLeft: '10px',
  },
  width: '100%',
  maxWidth: rem(395),
});

export const TableCellStyled = styled(TableCell)<{
  $isAction?: boolean;
}>(({ $isAction }) => ({
  fontFamily: baseType,
  fontWeight: weights.normal,
  fontSize: textSizes.base,
  lineHeight: '21px',
  padding: `${rem(16)} ${rem(20)}`,
  color: colors.grayDark3,
  borderBottom: `1px solid ${colors.grayLight1}`,
  ...($isAction && {
    backgroundColor: colors.grayLight3,
    width: rem(36),
  }),
}));

interface StyledTableContainerProps extends TableContainerProps {
  border?: 0 | 1;
}

export const TableContainerStyled = styled(
  TableContainer,
)<StyledTableContainerProps>(({ border }) => ({
  borderRadius: `${rem(4)} `,
  backgroundColor: colors.white,
  border: border ? `1px solid ${colors.grayLight2}` : 'none',
  borderBottom: 'none',
}));

export const TableHeadCellStyled = styled(TableCell)<{
  $darkBackground?: boolean;
}>(({ $darkBackground }) => ({
  fontFamily: baseType,
  fontWeight: weights.medium,
  fontSize: textSizes.sm,
  lineHeight: '18px',
  color: colors.grayDark2,
  padding: `${rem(10.5)} ${rem(20)}`,
  borderTop: `1px solid transparent`,
  borderBottom: `1px solid ${colors.grayLight1}`,
  ...($darkBackground && {
    backgroundColor: colors.grayLight3,
  }),
}));

export const TableHeader = styled('div')({
  display: 'flex',
  gap: rem(8),
  justifyContent: 'start',
  alignItems: 'center',
  textTransform: 'uppercase',
});

export const TableToolbar = styled('div')<{ isShow?: boolean }>(
  ({ isShow = false }) => ({
    minHeight: rem(56),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
    justifyContent: isShow ? 'space-between' : 'flex-end',
    padding: '7px 20px',
    borderBottom: `1px solid ${colors.grayLight1}`,
    borderTop: '1px solid transparent',
  }),
);

export const ListLoading = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: rem(10),
  width: '100%',
});

export const StyledMenuItem = styled(MenuItem)({
  a: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
  },
});

export const NoOptions = styled('div')({
  fontSize: textSizes.base,
  fontWeight: weights.medium,
  color: colors.grayDark3,
  textAlign: 'center',
  margin: '0 auto',
  width: rem(480),
});

interface StyledTableFooterCellProps extends TableCellProps {
  // this is weird why not just boolean?
  nofooterborder?: 0 | 1;
}

export const StyledTableFooterCell = styled(
  TableCell,
)<StyledTableFooterCellProps>(({ nofooterborder = false }) => ({
  borderBottom: nofooterborder
    ? '1px solid transparent'
    : `1px solid ${colors.grayLight1}`,
}));
