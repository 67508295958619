import { getUserToken } from '@gen2/utils/auth';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { has, isNil, omit } from 'lodash';
import * as R from 'ramda';

interface IAxiosRequestConfig extends AxiosRequestConfig {
  omit?: Array<string>;
}

class HttpRequest {
  baseUrl: string;
  queue: {
    [propName: string]: boolean;
  };

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }

  async getInsideConfig(hasCustomeAuthorization: boolean, isGlobal: boolean) {
    const accessToken = hasCustomeAuthorization ? '' : await getUserToken();
    const organisationId = sessionStorage.getItem('FI-ORGANISATION-ID');
    const accountId = sessionStorage.getItem('FI-ACCOUNT-ID');

    const baseUrl = isGlobal
      ? this.baseUrl
      : `//${localStorage.getItem('regionEndpoint')}`;

    const config: AxiosRequestConfig = {
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`, // jwt access_token
        'organisation-id': organisationId,
        'account-id': accountId,
      },
      withCredentials: true, // carry cookies except use mocking request env
    };

    return config;
  }

  destroy(url: string) {
    delete this.queue[url];
  }

  interceptors(instance: AxiosInstance, url: string) {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig): InternalAxiosRequestConfig => {
        // if (!Object.keys(this.queue).length) {
        // do something before sending requests
        // }
        this.queue[url] = true;

        return config as InternalAxiosRequestConfig;
      },
      (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
      },
    );
    instance.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => {
        this.destroy(url);
        // do something after getting responses

        return response;
      },
      (error: AxiosError): Promise<AxiosError> => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            localStorage.getItem('accessToken') &&
            localStorage.getItem('refreshToken')
          ) {
            // Redirect to the logout page
            window.location.href = '/logout';
          }
        }

        this.destroy(url);

        return Promise.reject(error.response);
      },
    );
  }

  async request(options: IAxiosRequestConfig) {
    const instance = axios.create();
    let pureOptions = R.mergeDeepRight(
      await this.getInsideConfig(
        has(options, 'headers.Authorization'),
        has(options, 'headers.global'),
      ),
      options,
    ) as AxiosRequestConfig;
    if (!isNil(options.omit)) {
      pureOptions = omit(pureOptions, options.omit);
      delete options.omit;
    }
    this.interceptors(instance, pureOptions.url || '__None__');
    return instance(pureOptions);
  }
}
export default HttpRequest;
