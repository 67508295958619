import {
  regular,
  solid,
  thin,
} from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GetIntSettingsResponse,
  IntFolder,
  TIntegrationType,
} from '@gen2/api/integrations/api';
import {
  IntegrationKeys,
  useStorageFolderMutation,
  useStorageSettings,
} from '@gen2/api/integrations/hooks';
import { ActionModal } from '@gen2/app/components/action-modal/action-modal';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { queryClient } from '@gen2/config';
import { PermissionsKeys } from '@gen2/types/permissions';
import { usePermissions } from '@gen2/utils/permissions/permissions';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ConnectConfirm from '../connect-confirm/connect-confirm';
import { CreateFolderModal } from '../create-folder/create-folder';
import { IntFinder } from '../finder/finder';
import { CustomTooltip } from '../integrations.styled';
import { useIntegrationsStore } from '../store';
import { useIntegrationContext } from '../useIntegrationContext';
import SharepointSitesModal from '../vendors/sharepoint-sites-modal/sharepoint-sites-modal';
import {
  ConnectButtonWrapper,
  FilePath,
  StyledAlertContainer,
  StyledContainer,
  StyledIntDetails,
  StyledIntDetailsBody,
  StyledIntDetailsBodyGroup,
  StyledIntDetailsBodyGroupTitle,
  StyledIntDetailsBodySection,
  StyledIntDetailsConnectIcon,
  StyledIntDetailsFolder,
  StyledIntDetailsFolderButton,
  StyledIntDetailsFooter,
  StyledIntDetailsHeader,
  StyledIntDetailsHeaderLeft,
  StyledIntDetailsHeaderLeftImg,
  StyledIntDetailsHeaderRight,
  StyledIntDetailsHeaderRightH3,
  StyledIntDetailsHeaderRightLink,
  StyledIntDetailsHeaderRightP,
  StyledSuccessAlertContainer,
} from './storage.styled';

const CloudStorage = () => {
  const {
    isConnected: getIsConnected,
    isLoading,
    connect,
    disabled,
    disconnectAsync,
  } = useIntegrationContext();
  const { mutate: saveMutation, isLoading: isSavingFolder } =
    useStorageFolderMutation();
  const {
    alert,
    setAlert,
    selectedFolder,
    setSelectedFolder,
    setCurrentFolder,
    isFinderOpen,
    isCreateFolderOpen,
    setFinderOpen,
    setCreateFolderOpen,
    setSitePickerOpen,
    site,
    changeSite,
  } = useIntegrationsStore();
  const navigate = useNavigate();
  const { t } = useTranslation('integrations');
  const { vendor } = useParams<{ vendor: TIntegrationType }>();
  const isConnected = getIsConnected(vendor || 'google');

  const { showModal } = useActionModalStore();

  const { data: settings } = useStorageSettings(vendor || 'google', {
    onSuccess: async (res) => {
      const data = res.data;

      if (!data?.folder) return;

      const { folder } = data;

      setSelectedFolder({
        id: folder.id,
        name: folder.name,
        path: folder.path,
      });
    },
    onError: async (err: any) => {
      const data = err.data as GetIntSettingsResponse;

      setAlert({
        message: data.message,
        severity: 'error',
        open: true,
      });

      setSelectedFolder({
        id: data.data.folder.id,
        name: data.data.folder.name,
        path: data.data.folder.path,
      });

      changeSite({
        id: data?.data?.site_id || '',
        name: data?.data?.site_name || '',
        path: '',
        isOpen: false,
      });

      return data;
    },
    enabled: isConnected,
  });

  useEffect(() => {
    if (!(settings && settings.data?.site_id)) return;

    changeSite({
      id: settings?.data?.site_id || '',
      name: settings?.data?.site_name || '',
      path: '',
      isOpen: false,
    });
  }, [settings, changeSite]);

  useEffect(() => {
    // reset on unmount
    return () => {
      setAlert({
        open: false,
        severity: 'success',
        message: '',
      });
    };
  }, [setAlert]);

  const handleCloseFinder = () => {
    setFinderOpen(false);
  };

  const handleSelectFolder = (folder: IntFolder) => {
    setSelectedFolder(folder);
    setFinderOpen(false);
  };

  const handleChangeFolder = async () => {
    if (vendor === 'sharepoint' && !site.id) {
      const site = await setSitePickerOpen();
      if (!site?.id) return;
    }

    if (selectedFolder) {
      setCurrentFolder(selectedFolder);
    }

    setFinderOpen(true);
  };

  const handleDisconnect = async () => {
    const integrationName = t(`${vendor}.name`);

    const { isConfirmed } = await showModal({
      header: t('disconnect.modal.header', { provider: integrationName }),
      message: 'disconnect.modal.message',
      messageParams: { provider: integrationName },
      translationNamespace: 'integrations',
      closeButtonLabel: t('disconnect.modal.cancel_btn') ?? '',
      submitButtonLabel: t('disconnect.modal.confirm_btn') ?? '',
    });

    if (!isConfirmed) {
      return;
    }

    try {
      await disconnectAsync();

      setAlert({
        message: `${integrationName} was successfully disconnected!`,
        severity: 'warning',
        open: true,
      });
    } catch (err) {
      setAlert({
        message: `Failed to disconnect ${integrationName}. Please try again.`,
        severity: 'error',
        open: true,
      });
    }
  };

  const onSave = () => {
    if (alert.open) {
      setAlert({ ...alert, open: false });
    }

    saveMutation(
      {
        vendor: vendor || 'google',
        directory_id: selectedFolder?.id ?? '',
        site_id: site.id,
      },
      {
        onSuccess: async () => {
          setAlert({
            open: true,
            severity: 'success',
            message: t(`${vendor}.success`),
          });

          await queryClient.invalidateQueries([
            IntegrationKeys.storageSettings,
          ]);
        },
        onError: (err: unknown) => {
          const error = err as AxiosError;

          const message =
            error.status !== 422
              ? t(`${vendor}.error`)
              : t(`${vendor}.folder_not_found`);

          setAlert({
            open: true,
            severity: 'error',
            message,
          });
        },
      },
    );
  };

  const onCloseCreateFolder = () => {
    setCreateFolderOpen(false);
    setFinderOpen(true);
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };

  const handleNavigate = () => {
    setAlert({ ...alert, open: false });
    setTimeout(() => {
      navigate('/integrations');
    }, 100);
  };

  return (
    <>
      <StyledContainer>
        {alert.open && (
          <StyledSuccessAlertContainer>
            <Alert
              data-cy="alert"
              severity={alert.severity}
              onClose={handleAlertClose}
            >
              <p>{alert.message}</p>
            </Alert>
          </StyledSuccessAlertContainer>
        )}
        <StyledIntDetails>
          <ModalCloseButton
            data-cy={`integration-${vendor}-close`}
            aria-label="close"
            onClick={handleNavigate}
          >
            <FontAwesomeIcon icon={regular('close')} />
          </ModalCloseButton>
          <StyledIntDetailsHeader>
            <StyledIntDetailsHeaderLeft>
              <StyledIntDetailsHeaderLeftImg
                src={`/assets/int-${vendor}.png`}
                alt={t(`${vendor}.thumbnail.title`) || ''}
              />
            </StyledIntDetailsHeaderLeft>
            <StyledIntDetailsHeaderRight>
              <StyledIntDetailsHeaderRightH3>
                {t(`${vendor}.thumbnail.title`)}
              </StyledIntDetailsHeaderRightH3>
              <StyledIntDetailsHeaderRightP>
                {t(`${vendor}.thumbnail.desc`)}
              </StyledIntDetailsHeaderRightP>
              <StyledIntDetailsHeaderRightLink
                href={t(`${vendor}.url`) || '#'}
                target="_blank"
              >
                {t(`${vendor}.link`)}
              </StyledIntDetailsHeaderRightLink>
            </StyledIntDetailsHeaderRight>
          </StyledIntDetailsHeader>
          <StyledIntDetailsBody>
            {isConnected ? (
              <>
                <StyledIntDetailsBodySection>
                  <StyledIntDetailsBodyGroup>
                    <LoadingButton
                      variant="outlined"
                      color="tertiary"
                      startIcon={
                        <StyledIntDetailsConnectIcon icon={solid('unlink')} />
                      }
                      onClick={handleDisconnect}
                      loading={isLoading}
                      data-cy={`disconnect-${vendor}`}
                    >
                      Disconnect
                    </LoadingButton>
                  </StyledIntDetailsBodyGroup>
                </StyledIntDetailsBodySection>
                <StyledIntDetailsBodySection>
                  {alert.open && alert.severity === 'error' && (
                    <StyledAlertContainer>
                      <Alert
                        data-cy="alert"
                        severity={alert.severity}
                        onClose={() => setAlert({ ...alert, open: false })}
                      >
                        <p>{alert.message}</p>
                      </Alert>
                    </StyledAlertContainer>
                  )}
                  <div>
                    <StyledIntDetailsBodyGroupTitle>
                      {t(`${vendor}.title`)}
                    </StyledIntDetailsBodyGroupTitle>
                    <p>{t(`${vendor}.desc`)}</p>
                  </div>
                  <StyledIntDetailsFolder>
                    <>
                      {(selectedFolder?.id || site?.name) && (
                        <FilePath title={selectedFolder?.path}>
                          <FontAwesomeIcon icon={thin('folder')} />
                          <span>{site.name + selectedFolder?.path}</span>
                        </FilePath>
                      )}
                      <StyledIntDetailsFolderButton
                        variant="contained"
                        color="secondary"
                        data-cy={`change-${vendor}-folder`}
                        onClick={handleChangeFolder}
                      >
                        {t(`${vendor}.choose_btn`)}
                      </StyledIntDetailsFolderButton>
                    </>
                  </StyledIntDetailsFolder>
                </StyledIntDetailsBodySection>
                <StyledIntDetailsFooter>
                  <Button
                    data-cy="back-to-integrations"
                    variant="outlined"
                    color="tertiary"
                    onClick={handleNavigate}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    data-cy={`save-${vendor}-folder`}
                    onClick={onSave}
                    variant="contained"
                    color="primary"
                    disabled={!selectedFolder?.id && !site.id}
                    loading={isSavingFolder}
                  >
                    Save Changes
                  </LoadingButton>
                </StyledIntDetailsFooter>
              </>
            ) : (
              <CustomTooltip
                title={
                  disabled[vendor || 'google']
                    ? t(`tooltip.disabled.${vendor}`)
                    : t(
                        `tooltip.level.${
                          vendor === 'sharepoint' ? 'account' : 'user'
                        }`,
                      )
                }
                placement="top"
              >
                <ConnectButtonWrapper>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    startIcon={
                      <StyledIntDetailsConnectIcon icon={solid('link')} />
                    }
                    onClick={() => connect(vendor || 'google')}
                    loading={isLoading}
                    data-cy={`connect-${vendor}`}
                    disabled={disabled[vendor || 'google']}
                  >
                    Connect
                  </LoadingButton>
                </ConnectButtonWrapper>
              </CustomTooltip>
            )}
          </StyledIntDetailsBody>
        </StyledIntDetails>
      </StyledContainer>
      <IntFinder
        open={isFinderOpen}
        onClose={handleCloseFinder}
        onSelect={handleSelectFolder}
      />
      <CreateFolderModal
        open={isCreateFolderOpen}
        onClose={onCloseCreateFolder}
      />
      <ConnectConfirm />
      <SharepointSitesModal />
      <ActionModal />
    </>
  );
};

export const CloudStorageWrapper = () => {
  const { withPermissions } = usePermissions();
  const { vendor } = useParams<{ vendor: TIntegrationType }>();

  if (
    vendor === 'sharepoint' &&
    !withPermissions(PermissionsKeys.SHOW_INTEGRATION)
  ) {
    return <Navigate to="/integrations" />;
  }

  return <CloudStorage />;
};

export default CloudStorage;
