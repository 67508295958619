import { config } from '@gen2/config';
import { TContact } from '@gen2/types/contact';
import { TPagination } from '@gen2/types/pagination';
import { MimeType } from '@nx-fe/components';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';
import { TPdfRedactionOption } from '../redactions/api';

export type TInviteRequestFileCreator = {
  id: string;
  first_name: string;
  last_name: string;
  type: string;
  email: string;
};

export type TInviteRequestFile = {
  id: string;
  mime_type: MimeType;
  original_name: string;
  size: number;
  created_at: string;
  creator: TInviteRequestFileCreator;
  type: string;
};

export type TInviteRequest = {
  id: string;
  title: string;
  description: string;
  descriptionText: string;
  type: string;
  meta: string;
  status: string;
  order: number;
  files: Array<TInviteRequestFile>;
  pdf_redactions: TPdfRedactionOption[];
  created_at: string;
  indicator: string;
  contacts?: TContact[]
  is_follow_up_request?: boolean;
};

export type TInviteRequestPayload = {
  inviteId: string;
  title: string;
  description?: string;
  meta?: string;
  type: string;
  follow_up_id?: string;
  pdf_redactions?: Array<TPdfRedactionOption['id']>;
};

export type TInviteRequestResponse = {
  data: {
    request: TInviteRequest;
  };
  message: string;
};

export const createInviteRequest = ({
  inviteId,
  ...data
}: TInviteRequestPayload): AxiosPromise<TInviteRequestResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests`,
    data,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TInviteRequests = {
  data: {
    requests: TInviteRequest[];
  };
};

export type TInviteRequestQueryParams = {
  inviteId: string;
} & TPagination;

export const getInviteRequests = ({
  inviteId,
  ...params
}: TInviteRequestQueryParams): AxiosPromise<TInviteRequests> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests`,
    params: {
      ...params,
      'filter[exclude_follow_up_requests]': 1,
    },
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TDeleteInviteRequests = {
  inviteId: string;
  requestIds: Array<TInviteRequest['id']>;
};

export const deleteInviteRequests = ({
  inviteId,
  requestIds,
}: TDeleteInviteRequests) => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests`,
    method: 'delete',
    data: {
      requests: requestIds.map((id) => ({ id })),
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TUpdateInviteRequestPayload = {
  id: string;
} & TInviteRequestPayload;

export const updateInviteRequest = ({
  inviteId,
  id,
  ...data
}: TUpdateInviteRequestPayload): AxiosPromise<TInviteRequestResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests/${id}`,
    data,
    method: 'put',
    headers: {
      Accept: 'application/json',
    },
  });
};

export type TAssignContactInRequestPayload = {
  inviteId: string;
  requestId: string;
  contactId: string;
};

export const addContactInRequest = ({
  inviteId,
  requestId,
  contactId
}: TAssignContactInRequestPayload): AxiosPromise<TInviteRequestResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests/${requestId}/contacts/${contactId}`,
    method: 'post',
    headers: {
      Accept: 'application/json',
    },
  });
};

export const deleteContactInRequest = ({
  inviteId,
  requestId,
  contactId
}: TAssignContactInRequestPayload): AxiosPromise<TInviteRequestResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/requests/${requestId}/contacts/${contactId}`,
    method: 'delete',
    headers: {
      Accept: 'application/json',
    },
  });
};
