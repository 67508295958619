import { weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const ViewDetailsContent = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: rem(8),
  rowGap: rem(24),
  width: '100%',
  maxWidth: rem(420),
  marginLeft: 'auto',
  marginright: 'auto',
});

export const ViewDetailsLabel = styled('span')({
  fontWeight: weights.semiBold,
  justifySelf: 'end',
});

export const ViewDetailsText = styled('span')({});
