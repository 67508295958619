import axios from '@gen2/api/axios.request';
import { TGetFileUrlQueryParams } from "@gen2/api/files/api";
import { LiveFormType } from '@gen2/app/invites/send-invites/store';
import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';

export interface ILiveFormRequest {
    liveFormData: {
        xfdf_data: string;
        json_data: string;
        type: LiveFormType;
    }
    fileId: string;
    liveFormFieldId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLiveForm = (fileId: string): AxiosPromise<unknown | any> => {
    return axios.request({
        url: `${config.apiVersion.v1}/files/${fileId}/liveform-fields`,
        method: 'get',
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveLiveForm = ({ fileId, liveFormData }: ILiveFormRequest): AxiosPromise<unknown | any> => {
    return axios.request({
        url: `${config.apiVersion.v1}/files/${fileId}/liveform-fields`,
        method: 'post',
        data: liveFormData
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateLiveForm = ({ fileId, liveFormFieldId, liveFormData }: ILiveFormRequest): AxiosPromise<unknown | any> => {
  return axios.request({
      url: `${config.apiVersion.v1}/files/${fileId}/liveform-fields/${liveFormFieldId}`,
      method: 'put',
      data: liveFormData
  });
}

export type TDetectFormFieldsResponse = {
  data: {
    xfdf: '';
  }
};

export const detectFormFields = ({ fileId }: TGetFileUrlQueryParams): AxiosPromise<TDetectFormFieldsResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/files/${fileId}/detect-form-fields`,
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });
}

