import { FileKeys, useGetFileUrlQuery } from '@gen2/api/files/hooks';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useToast } from '@gen2/hooks';
import { Box, Button, CircularProgress, Dialog, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { NutrientFormViewerModal } from './nutrient-form-viewer-modal';
import {
  StyledAside,
  StyledHeader,
  StyledOverlap,
  StyledWebViewerBox,
} from './nutrient-loading-modal.styled';
import { DraggableAnnotation } from './nutrient/sidePanel/draggableAnnotations/draggableAnnotations';
import { StyledFormTitle } from './nutrient/sidePanel/sidePanel.styled';
import { AnnotationTypeEnum } from './nutrient/utils/types';
import { PreviewNutrientViewerModal } from './preview-nutrient-viewer-modal';
import { TemplateNutrientWebViewerModal } from './template-nutrient-web-viewer-modal';
export interface NutrientLoadingModal {
  fileId: string;
  onClose?: () => void;
  open: boolean;
  fileName: string;
  isInTemplates?: boolean;
}

export const StaticSidePanel = () => {
  return (
    <Box paddingX={2} paddingTop={3}>
      <Stack spacing={5}>
        <Box>
          <StyledFormTitle
            style={{ paddingBottom: '20px', paddingTop: '10px' }}
          >
            Add Fields<span> | Drag & drop</span>
          </StyledFormTitle>
          <DraggableAnnotation
            type={AnnotationTypeEnum.TextField}
            label="Text Field"
          />
          <DraggableAnnotation
            type={AnnotationTypeEnum.CheckBox}
            label="Check Box"
          />
          <DraggableAnnotation
            type={AnnotationTypeEnum.RadioButton}
            label="Radio Button"
          />
        </Box>
        <Box>
          <StyledFormTitle
            style={{ paddingBottom: '20px', paddingTop: '10px' }}
          >
            Signatures<span> | Drag & drop</span>
          </StyledFormTitle>
          <DraggableAnnotation
            type={AnnotationTypeEnum.SIGNATURE}
            label="Signature"
          />
          <DraggableAnnotation
            type={AnnotationTypeEnum.INITIAL}
            label="Initial"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export const NutrientLoadingModal = ({
  onClose,
  open,
  fileId,
  fileName,
}: NutrientLoadingModal) => {
  const [isLoading, setIsLoading] = useState(true);
  const prevFileDataRef = useRef<{ fileId: string; signed_url: string | null }>(
    {
      fileId,
      signed_url: null,
    },
  );
  const { data } = useGetFileUrlQuery(
    {
      fileId,
    },
    FileKeys.GET_FILE_URL_NUTRIENT_MODAL,
  );

  const toast = useToast();

  const store = useSendInviteStore();
  useEffect(() => {
    if (!data) return;
    if (!data.signed_url) {
      toast.show({
        text: 'Your file is being processed. Please wait a moment and check back later. Alternatively, you can contact the support team for assistance.',
        variant: 'error',
      });
    }

    if (
      prevFileDataRef.current.fileId === fileId &&
      prevFileDataRef.current.signed_url === data.signed_url
    ) {
      return;
    }

    prevFileDataRef.current = { fileId, signed_url: data.signed_url };
    setIsLoading(!data.signed_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fileId]);

  const handleCloseAll = () => {
    onClose && onClose();
    store.setSource('');
  };

  return (
    <>
      <Dialog
        id="nutrient-form-viewer-modal"
        fullScreen
        open={open}
        onClose={onClose}
        disableEscapeKeyDown
      >
        <StyledHeader>
          <Button variant="outlined" color="tertiary" onClick={onClose}>
            Back
          </Button>
          <Button variant="contained" color="primary" disabled>
            Save & close
          </Button>
        </StyledHeader>

        <StyledWebViewerBox>
          <StyledAside>
            <StaticSidePanel />
          </StyledAside>
          <StyledOverlap>
            <CircularProgress size="3rem" color="secondary" disableShrink />
          </StyledOverlap>
        </StyledWebViewerBox>
      </Dialog>

      {store.source === 'fillable' && !isLoading && data?.signed_url && (
        <NutrientFormViewerModal
          fileId={fileId}
          fileUrl={data.signed_url}
          fileName={fileName}
          open={store.source === 'fillable'}
          onClose={handleCloseAll}
        />
      )}
      {store.source === 'preview' && !isLoading && data?.signed_url && (
        <PreviewNutrientViewerModal
          isOpen={store.source === 'preview'}
          fileId={fileId}
          fileName={fileName}
          fileUrl={data.signed_url}
          onClose={handleCloseAll}
        />
      )}

      {store.source === 'template' && !isLoading && data?.signed_url && (
        <TemplateNutrientWebViewerModal
          fileId={fileId}
          fileUrl={data.signed_url}
          fileName={fileName}
          type={store.liveFormType}
          open={store.source === 'template'}
          onClose={handleCloseAll}
        />
      )}
    </>
  );
};
