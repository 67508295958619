import { ListLoading } from '@gen2/app/components/base-table/base-table.styled';
import { Player } from '@lottiefiles/react-lottie-player';
import { FC } from 'react';

const LoadingComponent: FC = () => {
  return (
    <ListLoading>
      <Player
        autoplay
        loop
        src="/assets/initializing.json"
        style={{ height: '34px', width: '38px' }}
      ></Player>
      <div>Loading records...</div>
    </ListLoading>
  );
};

export default LoadingComponent;
