import {
  useSaveWorkspaceMutation,
  WorkspaceKeys,
} from '@gen2/api/workspaces/hooks';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useInviteListingStore } from '../../store';
import { Content, FormGroup, Subtitle } from './save-workspace.styled';
import { schema } from './schema';

type TSaveWorkspaceForm = {
  name: string;
  defaultView: boolean;
};

export const defaultValues: TSaveWorkspaceForm = {
  name: '',
  defaultView: false,
};

export type TSaveWorkspaceModalProps = {
  filters: {
    contacts: Array<{ id: string; name: string }>;
    users: Array<{ id: string; name: string }>;
    assignees: Array<{ id: string; name: string }>;
    teams: Array<{ id: string; name: string }>;
    indicators: Array<{ id: string; name: string }>;
    total: number;
  };
};

const SaveWorkspaceModal = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<TSaveWorkspaceForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const { isViewModalOpen, setViewModalOpen, filter, selectedFilters } =
    useInviteListingStore();
  const { mutate: saveWorkspaceMutation } = useSaveWorkspaceMutation();
  const { t } = useTranslation('workspaces');
  const toast = useToast();

  const name = register('name');
  const defaultView = register('defaultView');

  const onSave: SubmitHandler<TSaveWorkspaceForm> = (data) => {
    saveWorkspaceMutation(
      {
        name: data.name,
        is_default: data.defaultView,
        filters: {
          poc: filter.poc.map((user) => ({
            id: user.id,
            name: user.name,
          })),
          pocQuery: filter.pocQuery,
          contacts: filter.contacts.map((contact) => ({
            id: contact.id,
            name: contact.name,
          })),
          contactsQuery: filter.contactsQuery,
          assignees: filter.assignees.map((assignee) => ({
            id: assignee.id,
            name: assignee.name,
          })),
          assigneesQuery: filter.assigneesQuery,
          teams: filter.teams.map((team) => ({
            id: team.id,
            name: team.name,
          })),
          teamsQuery: filter.teamsQuery,
          indicators: filter.indicators.map((indicator) => indicator),
          sortColumn: selectedFilters.sortColumn,
          sortOrder: selectedFilters.sortOrder,
          subjectQuery: selectedFilters.subjectQuery,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([WorkspaceKeys.getWorkspaces]);

          toast.show({
            text: t('saveForm.success'),
            variant: 'success',
          });
          onClose();
        },
        onError: (err: any) => {
          setError('name', {
            type: 'manual',
            message: err.data.message,
          });
        },
      },
    );
  };

  const onClose = () => {
    reset(defaultValues);
    setViewModalOpen(false);
  };

  return (
    <Dialog
      open={isViewModalOpen}
      onClose={onClose}
      aria-labelledby="save-workspace-modal-title"
      aria-describedby="save-workspace-modal-description"
      data-cy="save-workspace-modal"
    >
      <ModalCloseButton
        data-cy="close-btn"
        aria-label="close"
        onClick={onClose}
      />
      <DialogTitle data-cy="title" id="save-workspace-modal-title">
        Save a New View
        <Subtitle>
          Turn frequently used filters, sort and keywords into a view for easy
          access.
        </Subtitle>
      </DialogTitle>
      <DialogContent data-cy="content" dividers>
        <Content>
          <FormGroup>
            <label htmlFor="name">Enter a name:</label>
            <TextField
              id="name"
              {...name}
              error={!!errors.name}
              helperText={t(errors.name?.message ?? '')}
            />
          </FormGroup>
          <FormControlLabel
            control={<Checkbox color="secondary" {...defaultView} />}
            label="Set this as my default view for the Manage Invites page"
          />
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="tertiary"
          variant="outlined"
          data-cy="cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSave)}
          color="primary"
          variant="contained"
          data-cy="cancel-btn"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveWorkspaceModal;
