/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable array-callback-return */
import { ToolbarItem } from '@nutrient-sdk/viewer';
import { cloneDeep, isUndefined } from 'lodash';
import getAnnotationRenderers from './customAnnotationRenderer';

export type CustomInstantJSON = {
  format: 'https://pspdfkit.com/instant-json/v1';
  customFromAPI: boolean;
};

export const TOOLBAR_ITEMS: Array<ToolbarItem> = [
  { type: 'sidebar-thumbnails', mediaQueries: ['(min-width: 577px)'] },
  { type: 'pager' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'search' },
  { type: 'spacer' },
  { type: 'print', mediaQueries: ['(min-width: 577px)'] },
];

export const handleAnnotationCreation = async (
  instance: any,
  annotation: any,
  mySignatureIdsRef: any,
  setSignatureAnnotationIds: any,
  myEmail: string,
) => {
  if (annotation.isSignature) {
    for (let i = 0; i < instance.totalPageCount; i++) {
      const annotations = await instance.getAnnotations(i);
      for await (const maybeCorrectAnnotation of annotations) {
        if (
          annotation.boundingBox.isRectOverlapping(
            maybeCorrectAnnotation.boundingBox,
          )
        ) {
          const newAnnotation = getAnnotationRenderers({
            annotation: maybeCorrectAnnotation,
          });
          if (newAnnotation?.node) {
            newAnnotation.node.className = 'signed';
          }
        }
      }
    }

    const signatures = [...mySignatureIdsRef.current, annotation.id];

    setSignatureAnnotationIds(signatures);
    mySignatureIdsRef.current = signatures;
  }
};

export const handleAnnotationDelete = async (
  instance: any,
  annotation: any,
  myEmail: string,
) => {
  if (annotation.isSignature) {
    for (let i = 0; i < instance.totalPageCount; i++) {
      const annotations = await instance.getAnnotations(i);
      for await (const maybeCorrectAnnotation of annotations) {
        if (
          annotation.boundingBox.isRectOverlapping(
            maybeCorrectAnnotation.boundingBox,
          )
        ) {
          const newAnnotation = getAnnotationRenderers({
            annotation: maybeCorrectAnnotation,
          });
          if (newAnnotation?.node) {
            newAnnotation.node.className = '';
          }
        }
      }
    }
  }
};

export const updateAnnotationIdsBasedOnPdfObjectId = (
  originalInstantJSON: any,
) => {
  const instantJSON = cloneDeep(originalInstantJSON);
  const mappedAnnotation = instantJSON.annotations.map((annotation: any) => {
    // Check if `pdfObjectId` exists
    if (
      (!isUndefined(annotation.pdfObjectId) &&
        typeof annotation.pdfObjectId === 'string') ||
      typeof annotation.pdfObjectId === 'number'
    ) {
      // Update the annotation ID with pdfObjectId
      annotation.id = annotation.pdfObjectId.toString();
    }

    return annotation;
  });

  instantJSON.annotations = mappedAnnotation;

  return instantJSON;
};

export const generateInstantJSON = async (instantJSON: any) => {
  const currentTimestamp = new Date().toISOString();

  const formFieldValues = instantJSON.formFieldValues.map(
    (formField: any) => {
      formField.updatedAt = currentTimestamp;
      return formField;
    }
  );

  instantJSON.formFieldValues = formFieldValues;

  return instantJSON;
};
