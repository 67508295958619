/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useGetLiveFormQuery } from '@gen2/api/live-form/hooks';
import { Button, Dialog } from '@mui/material';
import NutrientViewer, { Instance } from '@nutrient-sdk/viewer';
import { customDecode } from '@nx-fe/components';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUnmount } from 'usehooks-ts';
import {
  CustomInstantJSON,
  TOOLBAR_ITEMS,
} from './nutrient/utils/common-helpers';
import getAnnotationRenderers from './nutrient/utils/customAnnotationRenderer';
import customizeUIForInitials from './nutrient/utils/customizeUIForInitials';
import {
  HeaderTitle,
  StyledHeader,
  StyledHeaderActions,
} from './peview-web-viewer-modal.styled';

export type TWebViewerModalProps = {
  isOpen: boolean;
  fileId: string;
  fileName: string;
  fileUrl: string;
  onClose: () => void;
};

let renderCount = 0;
export const PreviewNutrientViewerModal = ({
  isOpen,
  fileId,
  fileName,
  fileUrl,
  onClose,
}: TWebViewerModalProps) => {
  const viewer2 = useRef(null);
  const [nutrientInstance, setNutrientInstance] = useState<any>(null);
  const beenInitialised = useRef<boolean>(false);
  // State to store the current page index
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;

  const [isTextAnnotationMovable, setIsTextAnnotationMovable] = useState(false);
  const isTextAnnotationMovableRef = useRef(isTextAnnotationMovable);
  isTextAnnotationMovableRef.current = isTextAnnotationMovable;

  const { data } = useGetLiveFormQuery(fileId);

  const instantJSON: CustomInstantJSON = useMemo(() => {
    const initialJsonData: CustomInstantJSON = {
      format: 'https://pspdfkit.com/instant-json/v1',
      customFromAPI: true,
    };

    if (data !== undefined) {
      const responseData = data?.liveform?.fields?.json_data;

      if (responseData) {
        return {
          ...initialJsonData,
          ...responseData,
        };
      }

      return initialJsonData;
    }

    return {
      ...initialJsonData,
      customFromAPI: false,
    };
  }, [data]);

  const isCreateInitial = false;
  const trackInst = useRef<any>(null);
  const loadPSPDFKit = useCallback(
    async (instantJSON: CustomInstantJSON, el?: HTMLElement) => {
      if (NutrientViewer && fileUrl && el && !beenInitialised.current) {
        NutrientViewer.unload(el);
        beenInitialised.current = true;
        try {
          const {
            UI: { createBlock, Recipes, Interfaces },
          } = NutrientViewer;
          await NutrientViewer.load({
            theme: NutrientViewer.Theme.LIGHT,
            container: el,
            document: fileUrl,
            instantJSON: instantJSON,
            licenseKey: customDecode(window.$xr9c42),
            autoSaveMode: NutrientViewer.AutoSaveMode.IMMEDIATE,
            baseUrl: `${window.location.protocol}//${window.location.host}/assets/lib/pspdfkit/`,
            toolbarItems: [...TOOLBAR_ITEMS].filter(
              (item) => !['export-pdf', 'print'].includes(item.type),
            ),
            disableTextSelection: false,
            styleSheets: [`/assets/lib/pspdfkit/viewer.css`],
            ui: {
              [Interfaces.CreateSignature]: ({ props }: { props: any }) => ({
                content: createBlock(
                  Recipes.CreateSignature,
                  props,
                  ({ ui }: { ui: any }) => {
                    if (isCreateInitial) ui = customizeUIForInitials(ui);
                    return ui.createComponent();
                  },
                ).createComponent(),
              }),
            },
            customRenderers: {
              Annotation: ({ annotation }) => {
                return getAnnotationRenderers({ annotation });
              },
            },
          }).then((instance) => {
            trackInst.current = instance;
            setNutrientInstance(instance);
            configureInstanceEvents(instance); // Configure instance-specific events
          });
        } catch (error) {
          console.error('Error loading PSPDFKit:', error);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setTimeout(() => loadPSPDFKit(viewer2.current!));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (
      instantJSON &&
      instantJSON.customFromAPI &&
      viewer2.current &&
      !isEmpty(instantJSON) &&
      renderCount === 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      loadPSPDFKit(instantJSON, viewer2.current!);
      renderCount++;
    }
  }, [instantJSON, loadPSPDFKit]);

  const configureInstanceEvents = (inst: Instance) => {
    // Additional configurations and event listeners for the PSPDFKit instance

    inst.setViewState((viewState) =>
      viewState.set(
        'showSignatureValidationStatus',
        NutrientViewer.ShowSignatureValidationStatusMode.IF_SIGNED,
      ),
    );

    inst.addEventListener('viewState.currentPageIndex.change', (page) =>
      setOnPageIndex(page),
    );

    setIsTextAnnotationMovable(false);
  };

  const handleClose = async () => {
    await cleanupPSPDFKit();
    onClose();
  };

  const cleanupPSPDFKit = async () => {
    if (nutrientInstance && viewer2.current) {
      await NutrientViewer.unload(viewer2.current);
      viewer2.current = null;
      setNutrientInstance(null);
    }
  };

  useUnmount(async () => {
    await cleanupPSPDFKit();
    renderCount = 0;
  });

  return (
    <Dialog
      id="preview-modal"
      fullScreen
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown
    >
      <StyledHeader>
        <HeaderTitle>{fileName}</HeaderTitle>
        <StyledHeaderActions spacing={1} direction="row">
          <Button variant="outlined" color="tertiary" onClick={handleClose}>
            Back
          </Button>
        </StyledHeaderActions>
      </StyledHeader>
      <div ref={viewer2} style={{ height: '100vh' }} />
    </Dialog>
  );
};
