import { TInviteIndicator } from '@gen2/api/invites/api';
import { create } from 'zustand';

export type TFilter = { id: string; name: string };

export type TInviteListingStoreFilter = {
  anchorEl: HTMLElement | null;
  contacts: TFilter[] | [];
  contactsQuery?: string;
  poc: TFilter[] | [];
  pocQuery?: string;
  assignees: TFilter[] | [];
  assigneesQuery?: string;
  teams: TFilter[] | [];
  teamsQuery?: string;
  sortColumn?: 'created_at' | 'progression_percentage';
  sortOrder?: 'asc' | 'desc';
  subjectQuery?: string;
  indicators: TInviteIndicator[] | [];
  clearFlag: boolean;
  applyFilter: ({
    poc,
    pocQuery,
    contacts,
    contactsQuery,
    assignees,
    assigneesQuery,
    teams,
    teamsQuery,
    indicators,
    sortColumn,
    sortOrder,
    subjectQuery,
  }: {
    poc: TFilter[];
    pocQuery?: string;
    contacts: TFilter[];
    contactsQuery?: string;
    assignees: TFilter[];
    assigneesQuery?: string;
    teams: TFilter[];
    teamsQuery?: string;
    indicators: TInviteIndicator[];
    sortColumn?: 'created_at' | 'progression_percentage';
    sortOrder?: 'asc' | 'desc';
    subjectQuery?: string;
  }) => void;
  clearFilter: () => void;

  openFilter: (event: React.MouseEvent<HTMLElement>) => void;
  closeFilter: () => void;
  resetClearFlag: () => void;
};

export type TInviteListingStore = {
  archive: {
    isOpen: boolean;
    subject: string;
    id: string;
  };
  filter: TInviteListingStoreFilter;
  selectedFilters: {
    poc: TFilter[];
    pocQuery?: string;
    contacts: TFilter[];
    contactsQuery?: string;
    assignees: TFilter[];
    assigneesQuery?: string;
    teams: TFilter[];
    teamsQuery?: string;
    indicators: TInviteIndicator[];
    sortColumn?: 'created_at' | 'progression_percentage';
    sortOrder?: 'asc' | 'desc';
    subjectQuery?: string;
  };
  isViewModalOpen: boolean;

  setArchive: ({
    isOpen,
    subject,
  }: {
    isOpen: boolean;
    subject: string;
    id: string;
  }) => void;

  setViewModalOpen: (isOpen: boolean) => void;

  setSelectedFilters: ({
    poc,
    pocQuery,
    contacts,
    contactsQuery,
    assignees,
    assigneesQuery,
    teams,
    teamsQuery,
    indicators,
    sortColumn,
    sortOrder,
    subjectQuery,
  }: {
    poc?: TFilter[];
    pocQuery?: string;
    contacts?: TFilter[];
    contactsQuery?: string;
    assignees?: TFilter[];
    assigneesQuery?: string;
    teams?: TFilter[];
    teamsQuery?: string;
    indicators?: TInviteIndicator[];
    sortColumn?: 'created_at' | 'progression_percentage';
    sortOrder?: 'asc' | 'desc';
    subjectQuery?: string;
  }) => void;

  setSelectedPoc: (poc: TFilter[]) => void;
  setSelectedPocQuery: (pocQuery: string) => void;
  setSelectedContacts: (contacts: TFilter[]) => void;
  setSelectedContactsQuery: (contactsQuery: string) => void;
  setSelectedAssignees: (assignees: TFilter[]) => void;
  setSelectedAssigneesQuery: (assigneesQuery: string) => void;
  setSelectedTeams: (teams: TFilter[]) => void;
  setSelectedTeamsQuery: (teamsQuery: string) => void;
  setSelectedIndicators: (indicators: TInviteIndicator[]) => void;
  setSelectedSortColumn: (sortColumn: 'created_at' | 'progression_percentage') => void;
  setSelectedSortOrder: (sortOrder: 'asc' | 'desc') => void;
  setSelectedSubjectQuery: (subjectQuery: string) => void;
};

export const useInviteListingStore = create<TInviteListingStore>()((set) => ({
  archive: {
    isOpen: false,
    subject: '',
    id: '',
  },
  isViewModalOpen: false,
  filter: {
    anchorEl: null,
    contacts: [],
    poc: [],
    assignees: [],
    teams: [],
    indicators: [],
    sortColumn: 'created_at',
    sortOrder: 'desc',
    subjectQuery: '',
    clearFlag: false,
    applyFilter: ({
      poc: selectedUsers,
      pocQuery: selectedUsersQuery,
      contacts: selectedContacts,
      contactsQuery: selectedContactsQuery,
      assignees: selectedAssignees,
      assigneesQuery: selectedAssigneesQuery,
      teams: selectedAssignedTeams,
      teamsQuery: selectedAssignedTeamsQuery,
      indicators: selectedInviteIndicators,
      sortColumn: selectedSortColumn,
      sortOrder: selectedSortOrder,
      subjectQuery: selectedSubjectQuery,
    }) => {
      set((state) => ({
        filter: {
          ...state.filter,
          poc: selectedUsers,
          pocQuery: selectedUsersQuery,
          contacts: selectedContacts,
          contactsQuery: selectedContactsQuery,
          assignees: selectedAssignees,
          assigneesQuery: selectedAssigneesQuery,
          teams: selectedAssignedTeams,
          teamsQuery: selectedAssignedTeamsQuery,
          indicators: selectedInviteIndicators,
          sortColumn: selectedSortColumn,
          sortOrder: selectedSortOrder,
          subjectQuery: selectedSubjectQuery,
        },
      }));
    },
    clearFilter: () => {
      set((state) => ({
        filter: {
          ...state.filter,
          clearFlag: true,
          poc: [],
          pocQuery: '',
          contacts: [],
          contactsQuery: '',
          assignees: [],
          assigneesQuery: '',
          teams: [],
          teamsQuery: '',
          indicators: [],
          sortColumn: 'created_at',
          sortOrder: 'desc',
          subjectQuery: '',
        },
      }));
    },
    openFilter: (event) => {
      set((state) => ({
        filter: {
          ...state.filter,
          anchorEl: event.currentTarget,
        },
      }));
    },
    closeFilter: () => {
      set((state) => ({
        filter: {
          ...state.filter,
          anchorEl: null,
        },
      }));
    },
    resetClearFlag: () => {
      set((state) => ({
        filter: {
          ...state.filter,
          clearFlag: false,
        },
      }));
    },
  },

  selectedFilters: {
    poc: [],
    pocQuery: '',
    contacts: [],
    contactsQuery: '',
    assignees: [],
    assigneesQuery: '',
    teams: [],
    teamsQuery: '',
    indicators: [],
    sortColumn: 'created_at',
    sortOrder: 'desc',
    subjectQuery: '',
  },

  // actions
  setArchive: ({ isOpen, subject, id }) => {
    set({
      archive: {
        isOpen,
        subject,
        id,
      },
    });
  },

  setViewModalOpen: (isViewModalOpen) => {
    set({
      isViewModalOpen,
    });
  },

  setSelectedFilters: ({
    poc = [],
    pocQuery = '',
    contacts = [],
    contactsQuery = '',
    assignees = [],
    assigneesQuery = '',
    teams = [],
    teamsQuery = '',
    indicators = [],
    sortColumn = 'created_at',
    sortOrder = 'desc',
    subjectQuery = '',
  }) => {
    set({
      selectedFilters: {
        poc,
        pocQuery,
        contacts,
        contactsQuery,
        assignees,
        assigneesQuery,
        teams,
        teamsQuery,
        indicators,
        sortColumn,
        sortOrder,
        subjectQuery,
      },
    });
  },

  setSelectedPoc: (poc) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        poc,
      },
    }));
  },

  setSelectedPocQuery: (pocQuery) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        pocQuery,
      },
    }));
  },

  setSelectedContacts: (contacts) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        contacts,
      },
    }));
  },

  setSelectedContactsQuery: (contactsQuery) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        contactsQuery,
      },
    }));
  },

  setSelectedAssignees: (assignees) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        assignees,
      },
    }));
  },

  setSelectedAssigneesQuery: (assigneesQuery) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        assigneesQuery,
      },
    }));
  },

  setSelectedTeams: (teams) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        teams,
      },
    }));
  },

  setSelectedTeamsQuery: (teamsQuery) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        teamsQuery,
      },
    }));
  },

  setSelectedIndicators: (indicators) => {
    set((state) => ({
      selectedFilters: {
        ...state.selectedFilters,
        indicators,
      },
    }));
  },

  setSelectedSortColumn: (sortColumn) => {
    set((state) => ({
      selectedFilters: { ...state.selectedFilters, sortColumn },
    }));
  },

  setSelectedSortOrder: (sortOrder) => {
    set((state) => ({
      selectedFilters: { ...state.selectedFilters, sortOrder },
    }));
  },

  setSelectedSubjectQuery: (subjectQuery) => {
    set((state) => ({
      selectedFilters: { ...state.selectedFilters, subjectQuery },
    }));
  },
}));
