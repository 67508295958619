import { weights } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem } from 'polished';

export const Container = styled('div')({});

export const Subtitle = styled('p')({
  fontWeight: weights.normal,
  fontSize: rem(14),
});

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: rem(100),
  paddingRight: rem(100),
});

export const FormGroup = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(5),
  marginBottom: rem(10),
});
