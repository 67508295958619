import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledMenuItem } from '@gen2/app/components/base-table/base-table.styled';
import { StyledIconButton } from '@gen2/app/team-member/layout/team-member-listing.styled';
import { PermissionsKeys } from '@gen2/types/permissions';
import { Permissions } from '@gen2/utils/permissions/permissions';
import { Menu } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface MenuItemOption {
  label: string;
  action: 'view' | 'edit' | 'delete';
  path?: string;
  onClick?: () => void | Promise<void>;
  closeOnClick?: boolean;
  visible?: boolean;
  permission?: PermissionsKeys;
}

export interface TableActionItemProps<T> {
  id: string;
  item?: T;
  menuOptions?: MenuItemOption[];
  translationNamespace?: string;
}

const TableActionItem = <T,>({
  id,
  menuOptions,
  translationNamespace,
}: TableActionItemProps<T>) => {
  const { t } = useTranslation(translationNamespace);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuItems = useMemo(() => {
    return (menuOptions ?? [])
      .filter((option) => option.visible !== false) // Filter out invisible options
      .map((option, index) => {
        const content = (
          <StyledMenuItem
            key={`${option.action}-${index}`}
            onClick={() => {
              // Handle closing the menu based on option type
              if (option.closeOnClick) {
                handleOnClose();
              }
              if (option.onClick) {
                option.onClick(); // Handle custom actions
              }
            }}
            data-cy={`${option.action}-${id}`}
          >
            {option.path ? (
              <Link to={`${option.path}/${id}`}>{t(option.label)}</Link>
            ) : (
              <span>{t(option.label)}</span>
            )}
          </StyledMenuItem>
        );

        if (option.permission) {
          return (
            <Permissions key={index} allow={option.permission}>
              {content}
            </Permissions>
          );
        }

        return content;
      });
  }, [menuOptions, id, t, handleOnClose]);

  return (
    <div>
      <StyledIconButton onClick={handleClick} data-cy="ellipsis-button">
        <FontAwesomeIcon icon={regular('ellipsis-vertical')} />
      </StyledIconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default TableActionItem;
