import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FollowUpKeys,
  useFollowUpMergeTemplatesMutation,
  useGetFollowUpQuery,
} from '@gen2/api/follow-up/hooks';
import {
  InviteRequestsKeys,
  useInviteRequestsQuery,
} from '@gen2/api/invite-requests/hooks';
import { TMergeTemplates } from '@gen2/api/invites/api';
import {
  InvitesKeys,
  useMergeTemplatesMutation,
} from '@gen2/api/invites/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useRouter, useToast } from '@gen2/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { ModalCloseButton } from '@nx-fe/components';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MAX_INVITE_REQUESTS } from '../../requests/schema';
import {
  HeaderActions,
  HeaderTitle,
  MergeTemplateModalContainer,
  ModalContent,
  ModalHeader,
  TemplateCardContainer,
} from './merge-templates-modal.styled';
import { OtherSettings } from './other-settings/other-settings';
import { useMergeTemplateModalStore } from './store';
import { TemplateCard } from './template-card/template-card';

export const MergeTemplatesModal = () => {
  const { t } = useTranslation('templates');
  const toast = useToast();
  const router = useRouter();
  const { inviteId, followUpId } = useParams<{
    inviteId: string;
    followUpId: string;
  }>();
  const isFollowUp = router.pathname.includes('follow-up');

  const [currentStep, setCurrentStep] = useState<{
    step: number;
    stepString: string;
  }>({ step: 1, stepString: 'Requests' });

  const { invite, setContextInviteIdForNewInvite } = useSendInviteStore();
  const { showModal: showActionModal } = useActionModalStore();
  const {
    templates,
    selectedRequests,
    perTemplateSelectedCount,
    isSubmitting,
    isSubmitted,
    requests,
    subject,
    message,
    reminderSettings,
    submitHandler,
    reset,
    setIsSubmitting,
    setIsSubmitted,
    submitModal,
    isModalOpen,
    closeModal,
    setSubmitHandler,
  } = useMergeTemplateModalStore();

  const { data: followUpRequests } = useGetFollowUpQuery({
    inviteId: inviteId ?? invite.id ?? '',
    followUpId: followUpId ?? '',
    options: {
      enabled: isFollowUp && !!inviteId && !!followUpId,
    },
  });

  const { data: inviteRequests } = useInviteRequestsQuery(
    {
      inviteId: inviteId ?? invite.id ?? '',
      'sort[order]': 'asc',
      'sort[column]': 'order',
      per_page: 100,
    },
    {
      enabled: !!inviteId,
    },
  );

  const { mutateAsync, isLoading: inviteIsLoading } =
    useMergeTemplatesMutation();
  const { mutateAsync: followUpMutate, isLoading: followUpIsLoading } =
    useFollowUpMergeTemplatesMutation();

  const resetState = useCallback(() => {
    reset();
    setCurrentStep({ step: 1, stepString: 'Requests' });
  }, [reset]);

  const handleClose = useCallback(() => {
    resetState();
    closeModal(false);
  }, [closeModal, resetState]);

  useEffect(() => {
    if (isSubmitted) {
      resetState();
      setIsSubmitted(false);
      submitModal();
    }
  }, [isSubmitted, resetState, setIsSubmitted, submitModal]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const sortSelectedRequests = async (requestToTemplateMap: {
    [key: string]: string;
  }) => {
    if (!selectedRequests) {
      return [];
    }

    return selectedRequests.slice().sort((a, b) => {
      const templateIdA = requestToTemplateMap[a];
      const templateIdB = requestToTemplateMap[b];

      const indexA = templates.findIndex(
        (template) => template.id === templateIdA,
      );
      const indexB = templates.findIndex(
        (template) => template.id === templateIdB,
      );

      return indexA - indexB;
    });
  };

  const sortTemplateRequests = async () => {
    const requestToTemplateMap: { [key: string]: string } = {};

    for (const templateId in requests) {
      requests[templateId].forEach((requestId: string) => {
        requestToTemplateMap[requestId] = templateId;
      });
    }

    return sortSelectedRequests(requestToTemplateMap);
  };

  const createPayload = (
    payloadSelectedRequests: string[],
    getInviteId: () => string,
    templates: { id: string }[],
    getTemplateId: (template?: string) => string,
    subject?: string,
    message?: string,
    reminderSettings?: string,
  ): TMergeTemplates => {
    return {
      inviteId: getInviteId(),
      templates: templates.map((template) => template.id),
      requests: payloadSelectedRequests || [],
      subject_template_id: getTemplateId(subject),
      message_template_id: getTemplateId(message),
      reminder_settings_template_id: getTemplateId(reminderSettings),
    };
  };

  useEffect(() => {
    setSubmitHandler(async (overrideIsSubmitting = false) => {
      const handleErrorResponse = async (err: unknown) => {
        const error = err as AxiosResponse<{
          errors: { [index: string]: string[] };
          message: string;
        }>;

        if (error.status === 422) {
          const responseErrors = error.data;

          toast.show({
            text: responseErrors?.message,
            variant: 'error',
          });
        } else {
          toast.show({
            text: t('failedToChange'),
            variant: 'error',
          });
        }

        setIsSubmitting(false);
      };

      const getTemplateId = (template?: string) => {
        return template && template !== 'no-template' ? template : '';
      };

      const getInviteId = () => {
        if (!isFollowUp) {
          return invite?.id ? invite.id : inviteId ?? '';
        }

        return inviteId ?? '';
      };

      const validateForm = async () => {
        if ((isSubmitting || overrideIsSubmitting) && requests) {
          const payloadSelectedRequests = await getPayloadSelectedRequests();
          const inviteReqs = getInviteRequestsCount();
          const needsConfirmation = checkNeedsConfirmation(
            inviteReqs,
            payloadSelectedRequests,
          );

          const payload: TMergeTemplates = createPayload(
            payloadSelectedRequests,
            getInviteId,
            templates,
            getTemplateId,
            subject,
            message,
            reminderSettings,
          );

          if (needsConfirmation) {
            const isConfirmed = await showConfirmationModal();

            if (isConfirmed) {
              await sendPayload(payload);
              return true;
            } else {
              setIsSubmitting(false);
              return false;
            }
          } else {
            await sendPayload(payload);
          }
        }

        setIsSubmitting(false);
        return false;
      };

      const getPayloadSelectedRequests = async () => {
        if (!selectedRequests) {
          return [];
        }

        if (
          requests &&
          selectedRequests?.length === Object.values(requests).flat().length
        ) {
          return await sortTemplateRequests();
        }

        return selectedRequests;
      };

      const getInviteRequestsCount = () => {
        return !isFollowUp
          ? inviteRequests?.length ?? 0
          : followUpRequests?.requests.length ?? 0;
      };

      const checkNeedsConfirmation = (
        inviteReqs: number,
        payloadSelectedRequests: string[],
      ) => {
        return (
          inviteReqs &&
          payloadSelectedRequests &&
          inviteReqs + payloadSelectedRequests.length > MAX_INVITE_REQUESTS
        );
      };

      const showConfirmationModal = async () => {
        const { isConfirmed } = await showActionModal({
          header: t('confirm.title'),
          message: 'confirm.desc',
          translationNamespace: 'templates',
          closeButtonLabel: t('cancel', { ns: 'common' }) ?? '',
          submitButtonLabel: t('confirm.buttons.submit') ?? '',
          submitButtonColor: 'primary',
        });

        return isConfirmed;
      };

      const sendPayload = async (payload: TMergeTemplates) => {
        try {
          if (!isFollowUp) {
            await mutateAsync(payload);
          } else {
            await followUpMutate({
              ...payload,
              followUpId: followUpId ?? '',
            });
          }

          toast.show({
            text: t('afterChange'),
            variant: 'success',
          });

          setIsSubmitted(true);
        } catch (err: unknown) {
          await handleErrorResponse(err);
        } finally {
          if (invite?.id) {
            setContextInviteIdForNewInvite(invite.id);
          }

          if (!isFollowUp) {
            await queryClient.invalidateQueries([
              InviteRequestsKeys.getInviteRequests,
            ]);
            await queryClient.invalidateQueries([InvitesKeys.getInvite]);
          } else {
            await queryClient.invalidateQueries([FollowUpKeys.getFollowUp]);
          }

          setIsSubmitting(false);
        }
      };

      return validateForm();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invite,
    inviteId,
    inviteRequests,
    message,
    isSubmitting,
    mutateAsync,
    reminderSettings,
    requests,
    selectedRequests,
    setContextInviteIdForNewInvite,
    setIsSubmitted,
    setIsSubmitting,
    setSubmitHandler,
    showActionModal,
    subject,
    templates,
  ]);

  const validateSelectedRequests = useCallback(() => {
    let result = true;

    if (perTemplateSelectedCount) {
      const zeroValueEntries = Object.entries(perTemplateSelectedCount).filter(
        ([key, value]) => value === 0,
      );

      if (zeroValueEntries.length > 0) {
        const zeroTemplates = zeroValueEntries.map(([zeroTemplate, value]) => {
          const templateName = templates
            .filter((template) => template.id === zeroTemplate)
            .pop()?.name;
          return `"${templateName}"`;
        });

        const templateString =
          zeroTemplates.length < 2
            ? zeroTemplates.join(' & ')
            : zeroTemplates.slice(0, -1).join(', ') +
              ' & ' +
              zeroTemplates.slice(-1);

        const message =
          zeroTemplates.length > 1
            ? `templates ${templateString}`
            : `template ${templateString}`;

        toast.show({
          text: t('mergeModal.requests.noRequestSelected', {
            templateString: message,
          }),
          variant: 'error',
        });

        result = false;
      }
    }

    if (selectedRequests) {
      if (selectedRequests.length > MAX_INVITE_REQUESTS) {
        toast.show({
          text: t('mergeModal.requests.maxRequests', {
            max: MAX_INVITE_REQUESTS,
          }),
          variant: 'error',
        });

        result = false;
      }
    }

    return result;
  }, [perTemplateSelectedCount, selectedRequests, t, templates, toast]);

  const handleChangeStep = useCallback(
    (step = 1, stepString = 'Requests') => {
      if (step === 2 && !validateSelectedRequests()) {
        return;
      }

      setCurrentStep({ step, stepString });
    },
    [validateSelectedRequests],
  );

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
  }, [setIsSubmitting]);

  useEffect(() => {
    if (isSubmitting && isFollowUp) {
      if (validateSelectedRequests()) {
        submitHandler(true);
      } else {
        setIsSubmitting(false);
      }
    }
  }, [isFollowUp, isSubmitting, setIsSubmitting]);

  const handleBackButton = useCallback(() => {
    if (currentStep.step === 2) {
      handleChangeStep(1, 'Requests');

      return;
    }

    handleClose();
  }, [currentStep.step, handleChangeStep, handleClose]);

  return (
    <MergeTemplateModalContainer
      data-cy="delete-dialog"
      open={isModalOpen}
      onClose={() =>
        inviteIsLoading || followUpIsLoading ? handleClose() : ''
      }
      aria-labelledby="delete"
      aria-describedby="delete"
      disablePortal={false}
    >
      <ModalCloseButton
        data-cy="delete-dialog-close"
        aria-label="close"
        disabled={inviteIsLoading || followUpIsLoading}
        onClick={() => (!isSubmitting ? handleClose() : '')}
      >
        <FontAwesomeIcon icon={regular('close')} />
      </ModalCloseButton>
      <ModalHeader>
        <HeaderTitle>
          <span>{t('mergeModal.title', { ...currentStep })}</span>
        </HeaderTitle>
        <HeaderActions>
          <LoadingButton
            onClick={handleBackButton}
            color="primary"
            variant="contained"
            data-cy="load-selected-template-btn"
            disabled={inviteIsLoading || followUpIsLoading}
            loading={inviteIsLoading || followUpIsLoading}
          >
            {t('mergeModal.buttons.back')}
          </LoadingButton>
          {currentStep.step === 2 || isFollowUp ? (
            <LoadingButton
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              data-cy="load-selected-templates-btn"
              disabled={inviteIsLoading || followUpIsLoading}
              loading={inviteIsLoading || followUpIsLoading}
            >
              {t('mergeModal.buttons.load')}
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={() => handleChangeStep(2, 'Other Saved Settings')}
              color="secondary"
              variant="contained"
              data-cy="next-btn"
            >
              {t('mergeModal.buttons.next')}
            </LoadingButton>
          )}
        </HeaderActions>
      </ModalHeader>
      <ModalContent dividers step={currentStep.step}>
        {currentStep.step === 1 ? (
          <TemplateCardContainer>
            {templates.length > 0 &&
              templates.map((template) => (
                <TemplateCard
                  key={template.id}
                  template={template}
                  data-cy={`template-${template.id}`}
                />
              ))}
          </TemplateCardContainer>
        ) : (
          <OtherSettings />
        )}
      </ModalContent>
    </MergeTemplateModalContainer>
  );
};
