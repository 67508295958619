import SearchBar from '@components/lib/searchbar/searchbar';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TWorkspace } from '@gen2/api/workspaces/api';
import {
  useDefaultWorkspaceMutation,
  useGetWorkspacesQuery,
  WorkspaceKeys,
} from '@gen2/api/workspaces/hooks';
import { queryClient } from '@gen2/config';
import { useToast } from '@gen2/hooks';
import { PermissionsKeys } from '@gen2/types/permissions';
import { usePermissions } from '@gen2/utils/permissions/permissions';
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { noop } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { ActionModal } from '../components/action-modal/action-modal';
import {
  NoOptions,
  SortIcons,
  StyledListToolbar,
  TableCellStyled,
  TableContainerStyled,
  TableHeadCellStyled,
  TableHeader,
  TableToolbar,
} from '../components/base-table/base-table.styled';
import LoadingComponent from '../components/base-table/table-loading';
import { defaultWorkspace } from '../invite-listing-v2/view-controls/view-controls';
import { ViewFinderIcon } from '../invite-listing-v2/view-controls/view-controls.styled';
import {
  StyledPaginationButton,
  StyledPaginationContainer,
  StyledPaginationCount,
  StylePaginationPageSizeContainer,
} from '../team-member/layout/list-username.styled';
import RenameWorkspaceModal from './modals/rename/rename';
import ViewDetailsModal from './modals/view-details/view-details';
import Actions from './table-cells/actions/actions';
import {
  StyledManageInviteViews,
  StyledWorkspaces,
  StyledWorkspacesContent,
  ViewFinder,
  ViewFinderField,
  Views,
  WorkspaceText,
  WorkspaceTitle,
} from './workspaces.styled';

const columnHelper = createColumnHelper<TWorkspace>();

export const ManageInviteViews = () => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounceValue(query, 500);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [workspace, setWorkspace] = useState<TWorkspace | null>(null);
  const [optionQuery, setOptionQuery] = useState('');
  const [debouncedOptionQuery] = useDebounceValue(optionQuery, 500);
  const { data, isLoading } = useGetWorkspacesQuery({
    'filter[search]': debouncedQuery,
    page,
    per_page: limit,
  });
  const { data: dropdownWorkspaces, isLoading: isOptionsLoading } =
    useGetWorkspacesQuery({
      'filter[search]': debouncedOptionQuery,
      'sort[column]': 'name',
      'sort[order]': 'asc',
    });
  const { data: currentWorkspace } = useGetWorkspacesQuery({
    'filter[is_default]': 1,
  });
  const { withPermissions } = usePermissions();
  const { mutate: defaultWorkspaceMutation } = useDefaultWorkspaceMutation();
  const { t } = useTranslation('workspaces');
  const toast = useToast();

  const options = useMemo<TWorkspace[]>(() => {
    return [defaultWorkspace, ...(dropdownWorkspaces?.views ?? [])];
  }, [dropdownWorkspaces?.views]);

  const handlePageSize = (e: SelectChangeEvent<number>) => {
    setLimit(e.target.value as number);
  };

  const previousPage = () => {
    setPage((prev) => prev - 1);
  };

  const nextPage = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (!currentWorkspace?.views[0]?.id) {
      setWorkspace(defaultWorkspace);
      return;
    }

    setWorkspace(currentWorkspace.views[0]);
  }, [currentWorkspace?.views[0]?.id]);

  const columns = useMemo(() => {
    const c = [
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('creator', {
        header: () => 'Created By',
        cell: (info) => (
          <Link to={`/team-members/${info.getValue().id}`} target="_blank">
            {info.getValue().first_name + ' ' + info.getValue().last_name}
          </Link>
        ),
      }),
      columnHelper.display({
        id: 'action',
        header: '',
        cell: (info) => (
          <Actions name={info.row.original.name} id={info.row.original.id} />
        ),
      }),
    ];

    return c;
  }, []);

  const table = useReactTable({
    data: data?.views || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setPage(1);
  };

  const onResetSearch = () => {
    setPage(1);
    setQuery('');
  };

  const handleWorkspaceChange = (
    event: SyntheticEvent<Element, Event>,
    newWorkspace: TWorkspace | null,
    reason: string,
  ) => {
    if (!newWorkspace && reason !== 'selectOption') return;
    setWorkspace(newWorkspace);

    if (newWorkspace?.name === 'All Invites') {
      // reset to all invites
      return;
    }
  };

  const handleOptionSearchChange = (
    event: React.ChangeEvent<object>,
    newInputValue: string,
    reason: string,
  ) => {
    if (reason === 'input') {
      setOptionQuery(newInputValue);
    } else if (reason === 'clear') {
      setOptionQuery('');
    }
  };

  const handleChangeDefault = () => {
    if (!workspace) return;

    defaultWorkspaceMutation(
      {
        id: workspace.id,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([WorkspaceKeys.getWorkspaces]);

          toast.show({
            text: t('default.success', { name: workspace?.name }) ?? '',
            variant: 'success',
          });
        },
        onError: () => {
          toast.show({
            text: t('default.error', { name: workspace?.name }) ?? '',
            variant: 'error',
          });
        },
      },
    );
  };

  return (
    <StyledManageInviteViews>
      <ViewFinder>
        <WorkspaceTitle>Set your default view</WorkspaceTitle>
        <WorkspaceText>
          Set the default view for when you visit the Manage Invites page:
        </WorkspaceText>
        <ViewFinderField>
          <Autocomplete
            disablePortal
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="View" />}
            popupIcon={<ViewFinderIcon icon={solid('search')} />}
            onChange={handleWorkspaceChange}
            onInputChange={handleOptionSearchChange}
            noOptionsText={
              isOptionsLoading ? t('field.searching') : t('field.noResults')
            }
            value={workspace}
            disabled={!withPermissions(PermissionsKeys.SET_DEFAULT_VIEW)}
          />
          <Button
            onClick={handleChangeDefault}
            variant="contained"
            color="secondary"
            size="small"
            disabled={!withPermissions(PermissionsKeys.SET_DEFAULT_VIEW)}
          >
            Save Changes
          </Button>
        </ViewFinderField>
      </ViewFinder>
      <Views>
        <WorkspaceTitle>Views</WorkspaceTitle>
        <WorkspaceText>
          Check filter details, rename or delete existing views.
        </WorkspaceText>
        <TableContainerStyled border={1}>
          <TableToolbar>
            <StyledListToolbar data-cy="view-search">
              <SearchBar
                onChange={handleSearch}
                id="views-search"
                value={query}
                onReset={onResetSearch}
                placeholder="Search by name or email"
                data-cy="view-search-input"
                autoFocus
              />
            </StyledListToolbar>
          </TableToolbar>
          <Table data-cy="views-table">
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHeadCellStyled
                        key={header.id}
                        colSpan={header.colSpan}
                        data-cy={`view-table-header-${header.id}`}
                        $darkBackground={header.id === 'action'}
                        onClick={
                          header.id === 'created_by'
                            ? header.column.getToggleSortingHandler()
                            : undefined
                        }
                      >
                        {header.isPlaceholder ? null : (
                          <TableHeader>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: <SortIcons icon={duotone('sort')} />,
                              desc: (
                                <SortIcons
                                  icon={duotone('sort')}
                                  rotation={180}
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </TableHeader>
                        )}
                      </TableHeadCellStyled>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <LoadingComponent />
                  </TableCell>
                </TableRow>
              ) : !data?.views?.length ? (
                <TableRow>
                  <TableCellStyled colSpan={3} align="center">
                    <NoOptions>{t('noResults')}</NoOptions>
                  </TableCellStyled>
                </TableRow>
              ) : (
                table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} data-cy={`view-table-row-${row.id}`}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCellStyled
                            key={cell.id}
                            data-cy={`view-table-cell-${cell.column.id}`}
                            $isAction={cell.column.id === 'action'}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCellStyled>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>

            <TableFooter data-cy="view-table-footer">
              <TableRow data-cy="view-table-footer-row">
                <TableCell
                  colSpan={columns.length}
                  data-cy="view-table-footer-cell"
                  sx={{ py: 1 }}
                >
                  <StyledPaginationContainer data-cy="view-table-footer-pagination">
                    <StylePaginationPageSizeContainer data-cy="view-table-footer-pagination-page">
                      Show
                      <Select
                        labelId="page-size-label"
                        id="page-size-select"
                        value={limit}
                        onChange={handlePageSize}
                        autoWidth
                        color="info"
                        data-cy="view-table-footer-pagination-page-size"
                      >
                        {[10, 25, 50, 100].map((pageSize) => (
                          <MenuItem key={pageSize} value={pageSize}>
                            {pageSize}
                          </MenuItem>
                        ))}
                      </Select>
                      rows
                    </StylePaginationPageSizeContainer>
                    <StyledPaginationCount data-cy="view-table-footer-pagination-nav">
                      <Button
                        variant="outlined"
                        color="ghost"
                        size="small"
                        data-cy="first"
                        onClick={() => setPage(1)}
                      >
                        First
                      </Button>
                      <StyledPaginationButton
                        onClick={previousPage}
                        disabled={!data?.meta.prev}
                        data-cy="view-table-footer-pagination-nav-prev"
                        size="small"
                      >
                        <FontAwesomeIcon icon={solid('arrow-left')} />
                      </StyledPaginationButton>
                      <StyledPaginationButton
                        onClick={nextPage}
                        disabled={!data?.meta.next}
                        data-cy="view-table-footer-pagination-nav-next"
                        size="small"
                      >
                        <FontAwesomeIcon icon={solid('arrow-right')} />
                      </StyledPaginationButton>
                    </StyledPaginationCount>
                  </StyledPaginationContainer>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainerStyled>
      </Views>
    </StyledManageInviteViews>
  );
};

const Workspaces = () => {
  return (
    <>
      <StyledWorkspaces>
        <StyledWorkspacesContent>
          <Tabs value={1} onChange={noop} aria-label="basic tabs example">
            <Tab label="Manage Invite Views" value={1} />
          </Tabs>
          <ManageInviteViews />
        </StyledWorkspacesContent>
      </StyledWorkspaces>

      <ActionModal />
      <RenameWorkspaceModal />
      <ViewDetailsModal />
    </>
  );
};

export default Workspaces;
