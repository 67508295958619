import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TWorkspace } from '@gen2/api/workspaces/api';
import {
  useGetWorkspaceQuery,
  useGetWorkspacesQuery,
} from '@gen2/api/workspaces/hooks';
import { PermissionsKeys } from '@gen2/types/permissions';
import { TUser } from '@gen2/types/user';
import { usePermissions } from '@gen2/utils/permissions/permissions';
import { Autocomplete, Button, TextField, Tooltip } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useInviteListingFilters } from '../hooks';
import { useInviteListingStore } from '../store';
import {
  StyledViewControls,
  ViewButtonGroup,
  ViewFinder,
  ViewFinderIcon,
  ViewFinderLabel,
} from './view-controls.styled';

export const defaultWorkspace = {
  id: '',
  name: 'All Invites',
  creator: {
    first_name: '',
    last_name: '',
  } as TUser,
};

const ViewControls = () => {
  const {
    setViewModalOpen,
    setSelectedSortColumn,
    setSelectedSortOrder,
    selectedFilters,
  } = useInviteListingStore();
  const [workspace, setWorkspace] = useState<TWorkspace>(defaultWorkspace);
  const [search, setSearch] = useState('');
  const [debouncedValue] = useDebounceValue(search, 500);
  const { data, isLoading } = useGetWorkspacesQuery({
    'filter[search]': debouncedValue,
    'sort[column]': 'name',
    'sort[order]': 'asc',
  });
  const { data: currentWorkspace } = useGetWorkspacesQuery({
    'filter[is_default]': 1,
  });
  const { data: selectedWorkspace } = useGetWorkspaceQuery({
    id: workspace?.id ?? '',
  });
  const { applyWorkspaceFilters, resetFilters } = useInviteListingFilters();
  const { t } = useTranslation('workspaces');
  const navigate = useNavigate();
  const { withPermissions } = usePermissions();

  const workspaces = useMemo<TWorkspace[]>(() => {
    return [defaultWorkspace, ...(data?.views ?? [])];
  }, [data]);

  useEffect(() => {
    if (!currentWorkspace?.views[0]?.id) {
      setWorkspace(defaultWorkspace);
      return;
    }

    setWorkspace(currentWorkspace.views[0]);
  }, [currentWorkspace?.views[0]?.id]);

  useEffect(() => {
    if (!selectedWorkspace || !selectedWorkspace?.filters) return;

    applyWorkspaceFilters(selectedWorkspace.filters);
  }, [workspace?.id, selectedWorkspace?.id]);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newWorkspace: TWorkspace | null,
    reason: string,
  ) => {
    if (!newWorkspace && reason !== 'selectOption') return;
    setWorkspace(newWorkspace ?? defaultWorkspace);

    if (newWorkspace?.name === 'All Invites') {
      resetFilters();
      return;
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<object>,
    newInputValue: string,
    reason: string,
  ) => {
    if (reason === 'input') {
      setSearch(newInputValue);
    } else if (reason === 'clear') {
      setSearch('');
    }
  };

  const handleReset = () => {
    setWorkspace(defaultWorkspace);
    setSelectedSortColumn('created_at');
    setSelectedSortOrder('desc');
    resetFilters();
  };

  return (
    <StyledViewControls>
      <ViewFinder>
        <Tooltip
          title="Save regularly used filters, sorting and search queries as Views that you can make your default in Settings."
          placement="right"
        >
          <ViewFinderLabel>
            Views
            <FontAwesomeIcon icon={solid('info-circle')} />
          </ViewFinderLabel>
        </Tooltip>
        <Autocomplete
          disablePortal
          options={workspaces}
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="View" />}
          popupIcon={<ViewFinderIcon icon={solid('search')} />}
          onChange={handleChange}
          onInputChange={handleSearchInputChange}
          noOptionsText={
            isLoading ? t('field.searching') : t('field.noResults')
          }
          value={workspace}
        />
        <Button
          variant="outlined"
          color="tertiary"
          size="small"
          onClick={handleReset}
          disabled={
            workspace.id === '' &&
            selectedFilters.subjectQuery === '' &&
            selectedFilters.sortColumn === 'created_at' &&
            selectedFilters.sortOrder === 'desc'
          }
        >
          Reset
        </Button>
      </ViewFinder>
      <ViewButtonGroup>
        <Button
          onClick={() => setViewModalOpen(true)}
          variant="outlined"
          color="tertiary"
          size="small"
          disabled={!withPermissions(PermissionsKeys.CREATE_VIEW)}
        >
          Save View
        </Button>
        <Button
          onClick={() => navigate('/workspaces')}
          variant="outlined"
          color="ghost"
          size="small"
        >
          Settings
        </Button>
      </ViewButtonGroup>
    </StyledViewControls>
  );
};

export default ViewControls;
