import { colors, headingType, textSizes, weights } from '@components/theme/gen2';
import TabPanel, { TabPanelProps } from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  styled,
} from '@mui/material';
import { rem } from 'polished';

export const StyledPageContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },

  padding: rem(40),
  backgroundColor: colors.grayLight2,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: rem(16),
}));

export const StyledTabRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledAddButton = styled(Button)({
  marginLeft: 'auto',
});

export const StyledChip = styled(Chip)({
  padding: `${rem(3)} ${rem(12)}`,
  fontSize: textSizes.md,
});

export const StyledIconButton = styled(IconButton)({
  color: colors.grayDark3,
  fontSize: textSizes.xl,
});

interface StyledTabPanelProps extends TabPanelProps {
  withpadding?: 0 | 1;
}

export const StyledTabPanel = styled(TabPanel)<StyledTabPanelProps>(
  ({ withpadding = 0 }) => ({
    padding: withpadding ? rem(32) : 0,
    backgroundColor: withpadding ? colors.white : 'unset',
    borderTopRightRadius: withpadding ? rem(4) : 'unset',
  }),
);

export const ActionHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: colors.grayLight2,
});

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  width: '100%',
  gap: rem(10),
  '> h3': {
    fontSize: textSizes.sm,
    color: colors.grayDark2,
    fontWeight: weights.medium,
    fontFamily: headingType,
    margin: 0,
  },
  [theme.breakpoints.down('sm')]: {
    gap: rem(5),
  },
}));
