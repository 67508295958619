import { Avatar } from '@mui/material';
import {
  AvatarContainer,
  Content,
  Main,
  MessageContent,
  MessageDateTime,
  MessageDetails,
  MessageFooter,
  MessageLineContainer,
  MessageName,
  StyledAvatar,
} from './message-line.styles';

export type TMessageLineProps = {
  firstName: string;
  lastName: string;
  message: string;
  isSender: boolean;
  senderName?: string;
  sentAt: string;
  avatar?: string;
};

const MessageLine = ({
  isSender,
  firstName,
  lastName,
  message,
  sentAt,
  senderName,
  avatar,
}: TMessageLineProps) => {
  return (
    <MessageLineContainer>
      <Main>
        {isSender ? (
          <AvatarContainer>
            {avatar ? (
              <Avatar alt="avatar" src={avatar} />
            ) : (
              <StyledAvatar data-cy="avatar" fromMessage={true}>
                {firstName[0]}
                {lastName[0]}
              </StyledAvatar>
            )}
          </AvatarContainer>
        ) : null}

        <Content sender={isSender}>
          <MessageDetails>
            <MessageName>
              {firstName} {lastName}
            </MessageName>
            <MessageDateTime>{sentAt}</MessageDateTime>
          </MessageDetails>

          <MessageContent>{message}</MessageContent>
          {senderName ? (
            <MessageFooter>Sent by {senderName}</MessageFooter>
          ) : null}
        </Content>

        {!isSender ? (
          <AvatarContainer>
            {avatar ? (
              <Avatar alt="avatar" src={avatar} />
            ) : (
              <StyledAvatar data-cy="avatar" fromMessage={true}>
                {firstName[0]}
                {lastName[0]}
              </StyledAvatar>
            )}
          </AvatarContainer>
        ) : null}
      </Main>
    </MessageLineContainer>
  );
};

export default MessageLine;
