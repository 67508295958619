import { colors } from '@components/theme/gen2';
import { styled } from '@mui/material';
import { rem, rgba } from 'polished';

export const StyledWebViewerBox = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100vh',
  backgroundColor: colors.white,
});

export const StyledHeader = styled('header')(() => ({
  width: '100%',
  backgroundColor: colors.white,
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
  justifyContent: 'end',
  paddingTop: rem(5),
  paddingBottom: rem(5),
  paddingLeft: rem(15),
  paddingRight: rem(15),
  height: rem(49),
  borderBottom: `${rem(1)} solid ${colors.grayLight1}`,
  '&.hidden': {
    position: 'fixed',
    top: rem(-49),
    left: 0,
  },
}));

export const StyledAside = styled('aside')(({ theme }) => ({
  flex: `0 0  ${rem(314)}`,
  padding: rem(10),
  backgroundColor: colors.white,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledViewer = styled('div')(({ theme }) => ({
  width: 'calc(100% - 260px)',
  height: 'calc(100vh - 49px)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledOverlap = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: rgba(0, 0, 0, 0.4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
