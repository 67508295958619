import { config } from '@gen2/config';
import { AxiosPromise } from 'axios';
import axios from '../axios.request';
import { TInviteRequest } from '../invite-requests/api';

export type TSaveTemplateRequest = {
  inviteId: string;
  name: string;
};

export type TSaveTemplateResponse = {
  message: string;
};

export const saveTemplate = ({
  inviteId: id,
  name,
}: TSaveTemplateRequest): AxiosPromise<TSaveTemplateResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    data: {
      invite_id: id,
      name,
    },
    method: 'post',
  });
};

export type TTemplate = {
  id: string;
  name: string;
  apryse?: number;
  status: string;
  created_at: string;
  subject?: string;
  almost_due_reminder?: number;
  overdue_reminder?: number;
  message?: string;
  meta?: string;
};

export type TGetTemplatesResponse = {
  data: {
    templates: TTemplate[];
  };
  meta: {
    to: number;
    per_page: number;
    current_page: number;
    from: number;
    path: string;
  };
  message: string;
};

export type TGetTemplatesRequest = {
  'filter[name]'?: string;
  page?: number;
  per_page?: number;
};

export const getTemplates = (
  params?: TGetTemplatesRequest,
): AxiosPromise<TGetTemplatesResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    params: {
      'sort[column]': 'created_at',
      'sort[order]': 'desc',
      page: 1,
      per_page: 25, // NOTE: update this when pagination is implemented
      ...params,
    },
    method: 'get',
  });
};

export type TSelectTemplate = {
  inviteId: string;
  templateId: string;
};

export const selectTemplate = ({
  inviteId,
  templateId,
}: TSelectTemplate): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/invites/${inviteId}/use-template`,
    data: {
      template_id: templateId,
    },
    method: 'put',
  });
};

export type TRenameTemplate = {
  templateId: string;
  name: string;
};

export const renameTemplate = ({
  templateId,
  name,
}: TRenameTemplate): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/rename`,
    data: {
      name,
    },
    method: 'put',
  });
};

export type TDeleteTemplates = {
  templateIds: string[];
};

export const deleteTemplates = ({
  templateIds,
}: TDeleteTemplates): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    data: {
      template_ids: templateIds,
    },
    method: 'delete',
  });
};

export type TDuplicateTemplate = {
  templateId: string;
};

export const duplicateTemplate = ({
  templateId,
}: TDuplicateTemplate): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/duplicate`,
    method: 'post',
  });
};

export type TCreateTemplate = {
  name?: string;
};

export type TCreateTemplateResponse = {
  data: {
    template: TTemplate;
  };
  message: string;
};

export const createTemplate = (
  payload?: TCreateTemplate,
): AxiosPromise<TCreateTemplateResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates`,
    data: payload,
    method: 'post',
  });
};

export type TUpdateTemplate = {
  templateId: string;

  name: string;
  subject?: string;
  almost_due_reminder?: number;
  overdue_reminder?: number;
  message?: string;
  meta?: string;
  status: string;
};

export const updateTemplate = ({
  templateId,
  ...data
}: TUpdateTemplate): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}`,
    data,
    method: 'put',
  });
};

export type TGetTemplate = {
  id: string;
  name: string;
  subject: string;
  almost_due_reminder: string;
  overdue_reminder: string;
  message: string;
  meta: string;
  status: string;
  created_at: string;
};

export type TGetTemplateResponse = {
  data: {
    template: TGetTemplate;
  };
  message: string;
};

export const getTemplate = (id: string): AxiosPromise<TGetTemplateResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${id}`,
    method: 'get',
  });
};

// ************************************************************
// TEMPLATE REQUESTS
// ************************************************************

export type TGetTemplateRequestsParams = {
  templateId: string;
};

export type TGetTemplateRequestsResponse = {
  data: {
    requests: TInviteRequest[];
  };
};

export const getTemplateRequests = ({
  templateId,
}: TGetTemplateRequestsParams): AxiosPromise<TGetTemplateRequestsResponse> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/requests`,
    params: {
      'sort[column]': 'created_at',
      'sort[order]': 'asc',
      per_page: 100,
    },
    method: 'get',
  });
};

export type TCreateTemplateRequestPayload = {
  templateId: string;
  title: string;
  type: string;
};

export const createTemplateRequest = ({
  templateId,
  ...data
}: TCreateTemplateRequestPayload): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/requests`,
    data,
    method: 'post',
  });
};

export type TUpdateTemplateRequestPayload = {
  templateId: string;
  id: string;
  title: string;
  description?: string;
  meta?: string;
  type: string;
};

export const updateTemplateRequest = ({
  templateId,
  id,
  ...data
}: TUpdateTemplateRequestPayload): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/requests/${id}`,
    data,
    method: 'put',
  });
};

export type TDeleteTemplateRequestPayload = {
  id: string;
  templateId: string;
};

export const deleteTemplateRequest = ({
  id,
  templateId,
}: TDeleteTemplateRequestPayload): AxiosPromise<unknown> => {
  return axios.request({
    url: `${config.apiVersion.v1}/templates/${templateId}/requests/${id}`,
    method: 'delete',
  });
};
