import { TWorkspaceFilters } from '@gen2/api/workspaces/api';
import { useMemo } from 'react';
import { useInviteListingStore } from './store';

export const useInviteListingFilters = () => {
  const { filter, setSelectedFilters } = useInviteListingStore();

  const filters = useMemo(() => {
    const contactsIds = filter.contacts.map((contact) => contact.id);
    const usersIds = filter.poc.map((user) => user.id);
    const assigneeUserIds = filter.assignees.map((user) => user.id);
    const assignedTeamIds = filter.teams.map((team) => team.id);
    const inviteIndicators = filter.indicators;

    return {
      contacts: contactsIds,
      users: usersIds,
      assignees: assigneeUserIds,
      teams: assignedTeamIds,
      indicators: inviteIndicators,
      total:
        contactsIds.length +
        usersIds.length +
        assigneeUserIds.length +
        assignedTeamIds.length +
        inviteIndicators.length,
    };
  }, [
    filter.contacts,
    filter.poc,
    filter.assignees,
    filter.teams,
    filter.indicators,
  ]);

  const applyWorkspaceFilters = (newFilters: TWorkspaceFilters) => {
    filter.applyFilter({
      poc: newFilters.poc ?? [],
      pocQuery: newFilters.pocQuery ?? '',
      contacts: newFilters.contacts ?? [],
      contactsQuery: newFilters.contactsQuery ?? '',
      assignees: newFilters.assignees ?? [],
      assigneesQuery: newFilters.assigneesQuery ?? '',
      teams: newFilters.teams ?? [],
      teamsQuery: newFilters.teamsQuery ?? '',
      indicators: newFilters.indicators ?? [],
      sortColumn: newFilters.sortColumn ?? 'created_at',
      sortOrder: newFilters.sortOrder ?? 'desc',
      subjectQuery: newFilters.subjectQuery ?? '',
    });

    setSelectedFilters({
      poc: newFilters.poc ?? [],
      pocQuery: newFilters.pocQuery ?? '',
      contacts: newFilters.contacts ?? [],
      contactsQuery: newFilters.contactsQuery ?? '',
      assignees: newFilters.assignees ?? [],
      assigneesQuery: newFilters.assigneesQuery ?? '',
      teams: newFilters.teams ?? [],
      teamsQuery: newFilters.teamsQuery ?? '',
      indicators: newFilters.indicators ?? [],
      sortColumn: newFilters.sortColumn ?? 'created_at',
      sortOrder: newFilters.sortOrder ?? 'desc',
      subjectQuery: newFilters.subjectQuery ?? '',
    });
  };

  const resetFilters = () => {
    filter.clearFilter();
    setSelectedFilters({
      poc: [],
      pocQuery: '',
      contacts: [],
      contactsQuery: '',
      assignees: [],
      assigneesQuery: '',
      teams: [],
      teamsQuery: '',
      indicators: [],
      sortColumn: 'created_at',
      sortOrder: 'desc',
      subjectQuery: '',
    });
  };

  return {
    filters,
    applyWorkspaceFilters,
    resetFilters,
  };
};
